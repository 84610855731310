import { useState, useEffect } from 'react'
import { saldoContas, faturamentoMes, despesasMes } from '../../services/dashboardFinanceiro'
import Perfil from '../../middleware/Perfil'
import saldoContasImg from '../../assets/img/saldoContas.png'
import faturamentoMesImg from '../../assets/img/faturamentoMes.png'
import despesasMesImg from '../../assets/img/despesasMes.png'
import lucroMesImg from '../../assets/img/lucroMes.png'
import GraficoFaturamentoDespesa from '../GraficoFaturamentoDespesa'
import GraficoLucroMes from '../GraficoLucroMes'
import GraficoTicketMedio from '../GraficoTicketMedio'
import DreMes from '../DreMes'
import DreAno from '../DreAno'
import LoadingCirculo from '../LoadingCirculo'
import './dashboardfinanceiro.css'


function DashboardFinanceiro () {

    const [carregando, setCarregando] = useState(false)

    const [saldoContasCarregando, setSaldoContasCarregando] = useState(false)
    const [saldoContasValor, setsaldoContasValor] = useState(0)

    const [faturamentoMesCarregando, setFaturamentoMesCarregando] = useState(false)
    const [faturamentoValor, setFaturamentoValor] = useState(0)

    const [despesaMesCarregando, setDespesaMesCarregando] = useState(false)
    const [despesaMesValor, setDespesaMesValor] = useState(0)

    const [lucroMesValor, setLucroMesValor] = useState(0)


    useEffect (() => {

        const buscarSaldoContas = async () => {

            
            setCarregando (true)
            try {
                const resposta = await saldoContas ()
                setsaldoContasValor (resposta.data[0].saldoTotal)
                setCarregando (false)
                setSaldoContasCarregando (true)
        
            } catch (error: any) {
    
            }
        }

        buscarSaldoContas ()
        
    }, [setsaldoContasValor])

    useEffect (() => {

        const faturamento = async () => {
            
            setCarregando (true)
            try {
                const resposta = await faturamentoMes ()
                setFaturamentoValor (resposta.data[0].faturamentoMes)
                setFaturamentoMesCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
                
            }
        }

        faturamento ()
        
    }, [setFaturamentoMesCarregando])

    useEffect (() => {

        const despesas = async () => {

            setCarregando (true)
            try {
                const resposta = await despesasMes ()
                setDespesaMesValor (resposta.data[0].despesasMes)
                setDespesaMesCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
    
            }
        }

        despesas ()
        
    }, [setDespesaMesCarregando])

    useEffect (() => {

        const lucroMes = faturamentoValor - despesaMesValor

        return setLucroMesValor(lucroMes)
        
    }, [faturamentoValor, despesaMesValor])

    return (
        <Perfil perfil= {['master']}>
            <div id = 'dashboardFinanceiroContainer'>
                <div id = 'dadosDashboardFinanceiro'>
                    <div id = 'valoresFinanceiroContent'>
                        {carregando && <LoadingCirculo />}
                        {saldoContasCarregando && <h1>{Number(saldoContasValor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>}
                        <p>Saldo das Contas</p>
                    </div>
                    <div>
                        <img src= {saldoContasImg} alt= ''></img>
                    </div>
                </div>

                <div id = 'dadosDashboardFinanceiro'>
                    <div id = 'valoresFinanceiroContent'>
                        {carregando && <LoadingCirculo />}
                        {faturamentoMesCarregando && <h1>{Number(faturamentoValor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>}
                        <p>Faturamento do Mês</p>
                    </div>
                    <div>
                        <img src= {faturamentoMesImg} alt=""></img>
                    </div>
                </div>  

                <div id = 'dadosDashboardFinanceiro'>
                    <div id = 'valoresFinanceiroContent'>
                        {carregando && <LoadingCirculo />}
                        {despesaMesCarregando && <h1>{Number(despesaMesValor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>}
                        <p>Despesas do Mês</p>
                    </div>
                    <div>
                        <img src= {despesasMesImg} alt=""></img>
                    </div>
                </div> 

                <div id = 'dadosDashboardFinanceiro'>
                    <div id = 'valoresFinanceiroContent'>
                        <h1>{Number(lucroMesValor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>
                        <p>Lucro do Mês</p>
                    </div>
                    <div>
                        <img src= {lucroMesImg} alt=''></img>
                    </div>
                </div>
                <div id = 'graficosDashboardFinanceiro'>
                    <GraficoFaturamentoDespesa/>
                    <GraficoLucroMes/>
                    <GraficoTicketMedio/>
                    <DreMes/>
                    <DreAno/>
                </div>
            </div>
        </Perfil>
    )
}

export default DashboardFinanceiro