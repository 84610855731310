import { Button, Loader } from "../Styles";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { atualizarContato, buscarTags, criarTag, removerTagContato } from "../../services/Atendimento";
import { criptografarDado, telefoneMascara } from "../../helpers";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { listaContato, tags } from "../../interfaces/atendimento";
import "./index.css"
import ModalAvisoCancelar from "../ModalAvisoCancelar";
import { FaTrash } from "react-icons/fa";

function ModalEditarContato({ onClose, contatoSelecionado }: any) {

  const { atualizarDados } = useContext(ModalEstadoContext)

  const [processando, setProcessando] = useState(false)
  const [nome, setNome] = useState(contatoSelecionado.nome || "")
  const [telefone, setTelefone] = useState(contatoSelecionado.telefone || "")
  const [email, setEmail] = useState(contatoSelecionado.email || "")
  const [tipo, setTipo] = useState(contatoSelecionado.tipo || "")

  const [nomeTag, setNomeTag] = useState("")
  const [cor, setCor] = useState("#ff6b6c")
  const [sugestoesTag, setSugestoesTag] = useState<tags[]>([])

  const [modalConfirmacaoRemoverTag, setModalConfirmacaoRemoverTag] = useState(false)
  const [tagSelecionada, setTagSelecionada] = useState<string>("")

  const handleUpdateContato = async () => {
    setProcessando(true)
    try {
      const payload: listaContato = {
        nome,
        telefone,
        email,
        tipo
      }
      const idContato = criptografarDado(contatoSelecionado.id.toString())

      const resposta = await atualizarContato(idContato, payload)

      if (resposta.status === 200) {
        toast.success("Contato Atualizado com Sucesso.")
        atualizarDados()
        onClose()
      }
      setProcessando(false)
    } catch (error) {
      setProcessando(false)
      toast.error("Erro ao Atualizar Dados do Contato")
    }
  }

  const handleCreateTag = async (tagSugerida?: tags) => {
    try {
      const payload = {
        idContato: criptografarDado(contatoSelecionado.id.toString()),
        nome: tagSugerida ? tagSugerida.nome : nomeTag,
        cor: tagSugerida ? tagSugerida.cor : cor
      }
      const resposta = await criarTag(payload)

      if (resposta.status === 201) {
        atualizarDados()
        setProcessando(false)
        onClose()
      }
    } catch (error) {
      toast.error("Erro ao Adicionar Tag ao Contato")
    }
  }

  const handleRemoveTag = async () => {
    setProcessando(true)
    try {
      if (!tagSelecionada) return toast.info("Não foi possivel encontrar a tag")
      const tagIdCript = criptografarDado(tagSelecionada)
      const resposta = await removerTagContato(tagIdCript)

      if (resposta.status === 200) {
        atualizarDados()
        setProcessando(false)
        onClose()
        toast.success("Tag do Contato Removido com Sucesso.")
        setTagSelecionada("")
      }
    } catch (error) {
      setProcessando(false)
      setModalConfirmacaoRemoverTag(false)
      toast.error("Erro ao Remover Tag Contato.")
    }
  }

  useEffect(() => {
    const handleListarTags = async () => {
      try {
        const resposta = await buscarTags()

        const tagsJaSelecionados = contatoSelecionado.tags.map((tag: tags) => tag.nome)

        const tagsNaoAdicionadas = resposta.data.filter((tag: tags) => !tagsJaSelecionados.includes(tag.nome))

        const tagsNaoRepetidas = tagsNaoAdicionadas.filter((tag: tags, index: number, self: any) => index === self.findIndex((t: tags) => t.nome === tag.nome))

        if (resposta.status === 200) {
          setSugestoesTag(tagsNaoRepetidas)
        }
      } catch (error) {
        toast.error("Erro ao criar sugestões de Tags")
      }
    }
    handleListarTags()
  }, [atualizarDados])

  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        <span className='novoContainerTitulo'>
          <h1>
            Editar Contato
          </h1>
        </span>
        <section className="container-form-inputs">
          <input type="text" placeholder="Nome Completo" defaultValue={contatoSelecionado.nome} onChange={(e) => setNome(e.target.value)} maxLength={100} />
          <input type="email" placeholder="Email" defaultValue={contatoSelecionado.email} onChange={(e) => setEmail(e.target.value)} maxLength={100} />
          <input type="text" placeholder="Telefone" value={telefone} onChange={(e) => setTelefone(telefoneMascara(e.target.value))} maxLength={18} />
          <select value={tipo} id="" onChange={(e) => setTipo(e.target.value)} required>
            <option value="" disabled>Selecione o Tipo</option>
            <option value="Sócio(a)">Sócio(a)</option>
            <option value="Empresário(a)">Empresário(a)</option>
            <option value="Colaborador">Colaborador</option>
            <option value="Terceirizado">Terceirizado</option>
            <option value="Outro">Outro</option>
          </select>
          <section>
            <details className="details-tags">
              <summary>
                Tags
              </summary>
              <section>
                <span className="container-input-tag">
                  <input type="text" placeholder="Nova Tag" onChange={(e: any) => setNomeTag(e.target.value)} />
                  <input type="color" onChange={(e: any) => setCor(e.target.value)} defaultValue={"#ff6b6c"} className="input-color" maxLength={6} />
                  <span>
                    <IoMdAddCircle fontSize={"2rem"} className="btn-add-tag" onClick={() => handleCreateTag()} />
                  </span>
                </span>
                <span className="modalEditarTagContato">
                  {contatoSelecionado.tags && contatoSelecionado.tags.map((tag: tags) => (
                    <p className="nome-tag-contato" key={tag.id} style={{ backgroundColor: tag.cor }} onClick={() => { setModalConfirmacaoRemoverTag(true); setTagSelecionada(tag.id.toString()) }}>
                      <FaTrash className="iconeTagTrash" title="Clique para Remover a Tag" />
                      {tag.nome}
                    </p>
                  ))}
                </span>

                {sugestoesTag.length !== 0 && <span>
                  <p className="titulo-tag">Sugestões de Tags</p>
                  <span className="adicionarTagSugestao">
                    {sugestoesTag.map((tagSugerida: tags) => (
                      <p title="Clique para Vincular a Tag" key={tagSugerida.id} onClick={() => handleCreateTag(tagSugerida)} className="tagContatoModal nome-tag-contato">{tagSugerida.nome}</p>
                    )
                    )}
                  </span>
                </span>}

              </section>
            </details>
          </section>
        </section>

        <span className="botoesContainer">
          <Button type='button' cor='cancelar' isLoading={processando} onClick={() => onClose(false)}>Fechar</Button>
          <Button type='submit' cor='salvar' isLoading={processando} onClick={handleUpdateContato}>
            {!processando && 'Salvar'}
            {processando && <Loader isLoading={processando} />}
          </Button>
        </span>
      </section>
      {modalConfirmacaoRemoverTag && <ModalAvisoCancelar titulo={"Certeza que deseja remover a tag do contato?"} processando={processando} funcaoSubmit={handleRemoveTag} onClose={setModalConfirmacaoRemoverTag} />}
    </div>
  );
}

export default ModalEditarContato;