import { useState, useEffect, FormEvent, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarComentariosSuporte, novoSuporte, atualizarSuporte } from '../../services/suporte'
import { comentarSuporte } from '../../services/suporte'
import { ModalSuporteDados } from '../../interfaces/suporte'
import { Button, Loader } from '../Styles'
import { comentariosListaResposta } from '../../interfaces/suporte'
import { toast } from 'react-toastify'
import suporteImg from '../../assets/img/suporte.png'
import './suporte.css'
import '../../styles/modal.css'


function ModalSuporte ({ suporte }: ModalSuporteDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { pathname, search } = location
    
    const params = new URLSearchParams(search)
    const tipoSuporte = params.get('tipo')

    const [titulo, setTitulo] = useState(suporte?.titulo)
    const [descricao, setDescricao] = useState(suporte?.descricao)
    const [status, setStatus] = useState<string | null>(null)
    const [comentario, setComentario] = useState<string | null>(null)
    const [comentarios, setComentarios] = useState<comentariosListaResposta []>([])
    const [tipos, setTipos] = useState<{nome: string, valor: string}[]>([])
    const [processandoComentario, setProcessandoComentario] = useState(false)
    const [processando, setProcessando] = useState (false)

    function ocultarModal () {

        navigate(`${pathname}?tipo=${tipoSuporte}`)

        return exibirModal ()
    }

    const tiposSuporte = () => {

        if(tipoSuporte === undefined || tipoSuporte === null) {
            return
        }

        const tipos: {[key: string]: string} = {
            'bug': 'Bug',
            'sugestão': 'Sugestão',
            'suporte': 'Suporte'
        }

        return tipos[tipoSuporte]
    }

    const cadastrarSuporte = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(tipoSuporte === undefined || tipoSuporte === null) {
            return
        }

        try {

            const payload = {
                tipo: tipoSuporte,
                titulo,
                descricao
            }

            const resposta = await novoSuporte(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                exibirModal()

                return toast.success('Dados Salvo com Sucesso')
            }
            
        } catch (error) {
            
            setProcessando(false)

            return toast.error('Falha ao Processar a sua Solicitação')
        }

    }

    const enviarComentario = async (e: FormEvent) => {

        e.preventDefault()
        setProcessandoComentario(true)

        const idSuporte = params.get('idSuporte') || params.get('idBug') || params.get('idSugestão')

        if(idSuporte === null) {
            setProcessandoComentario(false)
            return
        }

        if(comentario === null) {
            setProcessandoComentario(false)
            return alert('Preencha o Comentário')
        }

        try {

            const payload = {
                comentario
            }

            const resposta = await comentarSuporte(idSuporte, payload)

            if(resposta.status === 201) {
                setProcessandoComentario(false)
                atualizarDados()
                setComentario('')

                return toast.success('Comentário Salvo com Sucesso')
            }
            
        } catch (error) {
            
            setProcessandoComentario(false)
            return toast.error('Falha ao Salvar o seu Comentário')
        }

    }


    const atualizarStatusSuporte = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idSuporte = params.get('idSuporte') || params.get('idBug') || params.get('idSugestão')

        if(idSuporte === null || status === null) {
            setProcessando(false)
            return
        }

        try {

            const payload = {
                status
            }

            const resposta = await atualizarSuporte(idSuporte, payload)

            if(resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                exibirModal ()

                return toast.success('Dados Atualizados com Sucesso')
            }
            
        } catch (error) {

            setProcessando(false)

            return toast.error('Falha ao Processar a Sua Solicitação')
        }

    }

    useEffect(() => {

        const buscarComentarios = async () => {

            const params = new URLSearchParams(search)

            const idSuporte = params.get('idSuporte') || params.get('idBug') || params.get('idSugestão')

            if(idSuporte === null) {
                return
            }

            try {

                const resposta = await listarComentariosSuporte(idSuporte)

                if(resposta.status === 200) {
                    setComentarios(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarComentarios ()

    }, [atualizarInformacoes, search])

    useEffect(() => {

        const atualizarTipos = () => {

            if(!tipoSuporte) {
                return {nome: '', valor: ''}
            }
    
            const tipos: {[key: string]: {nome: string, valor: string}[]} = {
                suporte: [
                    { nome: 'Sem Análise', valor: 'sem análise' },
                    { nome: 'Em Análise', valor: 'em análise' },
                    { nome: 'Aguardando Retorno do Cliente', valor: 'aguardando retorno do cliente' },
                    { nome: 'Aguardando Retorno do Suporte', valor: 'aguardando retorno do suporte' },
                    { nome: 'Finalizado', valor: 'finalizado'}
                ],
                bug: [
                    { nome: 'Sem Análise', valor: 'sem análise'},
                    { nome: 'Em Análise', valor: 'em análise' },
                    { nome: 'Conclúido', valor: 'concluído' },
                ],
                sugestão: [
                    { nome: 'Sem Análise', valor: 'sem análise'},
                    { nome: 'Aceita', valor: 'aceita' },
                    { nome: 'Em Desenvolvimento', valor: 'em desenvolvimento' },
                    { nome: 'Recusada', valor: 'recusada' },
                    { nome: `${tipoSuporte === 'sugestão' ? 'Finalizada' : 'Finalizado'}`, valor: 'finalizado' },
                ]
            }
    
            return setTipos(tipos[tipoSuporte])
        }

        atualizarTipos()

    }, [tipoSuporte])


    return (
        <div id = 'fundoModal'>
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={suporteImg} alt='' />
                    <p>{!suporte ? `Nov${tipoSuporte === 'sugestão' ? 'a': 'o'} ${tiposSuporte()}`: `Vizualizar ${tiposSuporte()}`}</p>
                </span>
                <form id='formModalNovo' onSubmit={suporte ? atualizarStatusSuporte : cadastrarSuporte}>
                    {suporte && pathname === '/gesttor/ajuda' && <section>
                        <span>
                            <label htmlFor="situacaoSuporte">Situação</label>
                            <select value={status ? status: suporte.status} onChange={(e) => setStatus(e.target.value)}>
                                {tipos.map((tipo) => (
                                    <option key={tipo.valor} value={tipo.valor}>{tipo.nome}</option>
                                    ))}
                            </select>
                        </span>
                    </section>}

                    {suporte && pathname !== '/gesttor/ajuda' && <section>
                        <span>
                            <label htmlFor="situacaoSuporte">Situação</label>
                            <input 
                                type="text" 
                                value={`${suporte.status.charAt(0).toUpperCase()}${suporte.status.substring(1)}`}
                                disabled/>
                        </span>
                    </section>}

                    <section>
                        {suporte && <span>
                            <label htmlFor="usuarioSuporte">Reportado por</label>
                            <input 
                                type="text"
                                value={suporte?.solicitadoPor.nome} 
                                disabled/>
                        </span>}
                        {!suporte &&<span>
                            <label htmlFor="tituloSuporte">Título</label>
                            <input
                                type='text'
                                id='tituloSuporte'
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                                required/>
                        </span>}
                        {suporte && <span>
                            <label htmlFor="tituloSuporte">Título</label>
                            <input
                                type='text'
                                id='tituloSuporte'
                                value={titulo}
                                disabled/>
                        </span>}
                    </section>


                    {!suporte && <section>
                        <span>
                            <label htmlFor="descricaoSuporte">Descrição</label>
                            <textarea 
                                id="descricaoSuporte"
                                value={descricao}
                                placeholder='Descreva como podemos lhe ajudar'
                                onChange={(e) => setDescricao(e.target.value)} 
                                rows={5}
                                required/>
                        </span>
                    </section>}

                    {suporte && <section>
                        <span>
                            <label htmlFor="descricaoSuporte">Descrição</label>
                            <textarea 
                                id="descricaoSuporte"
                                value={descricao}
                                disabled
                                rows={5}/>
                        </span>
                    </section>}

                    {suporte && <section>
                        <span>
                            <div className='suporteComentarioContainer'>
                                <input 
                                type="text"
                                placeholder='Digite seu comentário'
                                onChange={(e) => setComentario(e.target.value)}
                                required 
                                />
                                <Button type='button' cor='salvar' isLoading={processandoComentario} onClick={(e) => enviarComentario(e)}>
                                    {!processandoComentario && 'Postar'}
                                    {processandoComentario && <Loader isLoading={processandoComentario}/>}
                                </Button>
                            </div>
                            <br/>
                            {comentarios.length > 0 && <p className='tarefasAnexos'>Comentários</p>}
                            <div>
                                {comentarios.map((comentario) => (
                                    <div key={Math.random()} className='comentarioTarefaContent'>
                                        <img src={comentario.usuario.avatar} alt="" />
                                        <p>{comentario.comentario}</p>
                                    </div>
                                ))}
                            </div>
                        </span>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        {!suporte && <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>}
                        {suporte && pathname === '/gesttor/ajuda' && <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Atualizar Suporte'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>}
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalSuporte
