import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { ModalModeloProcessoDados } from '../../interfaces/modelosProcessos'
import { atualizarModeloProcesso, novaModeloProcesso } from '../../services/modelosProcessos'
import { cadastrarTarefaModeloLocal, listaTarefasModelosPayload } from '../../interfaces/modelosTarefas'
import { listarTarefasModelos, deletarTarefaModelo } from '../../services/modelosTarefas'
import { Button, Loader } from '../Styles'
import avatarDefault from '../../assets/img/avatarDefault.png'
import ModalAlerta from '../ModalAlerta'
import ModalTarefaModelo from '../ModalTarefaModelo'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import processoImg from '../../assets/img/processos.png'
import apagarImg from '../../assets/img/excluir.png'
import '../../styles/modal.css'
import './modelosprocessos.css'


function ModalModeloProcesso ({ modeloProcesso }: ModalModeloProcessoDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModalProcesso, ocultarModalProcesso, atualizarDadosProcesso, modalTarefasProcesso, exibirModalTarefasProcesso, atualizarInformacoesProcesso, tarefaModeloLocal } = useContext(ModelosProcessosContext)

    const { atualizarDados, modalExibir } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [tarefasModelos, setTarefasModelos] = useState<listaTarefasModelosPayload[]>([])
    const [tarefasModeloLocal, setTarefaModeloLocal] = useState<cadastrarTarefaModeloLocal[]>([])
    const [situacao, setSituacao] = useState(modeloProcesso?.situacao)
    const [titulo, setTitulo] = useState(modeloProcesso?.titulo)
    const [modalTarefaModelo, setModalTarefaModelo] = useState(false)
    const [tarefaModeloSelecionada, setTarefaModeloSelecionada] = useState<listaTarefasModelosPayload>()
    const [semTarefas, setSemTarefas] = useState(false)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState(false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {

        navigate(`${pathname}`)
        modalExibir('')

        return ocultarModalProcesso ()
    }

    const exibirModalTarefaModeloDados = () => exibirModalTarefasProcesso()

    const exibirModalDadosTarefaModelo = (tarefaModelo: listaTarefasModelosPayload, idTarefaModelo: string) => {

        exibirModalTarefasProcesso()
        navigate(`${pathname}${search}#${idTarefaModelo}`)

        return setTarefaModeloSelecionada(tarefaModelo)
    }

    function ocultarModalAlerta () {
        
        setConfirmacaoExclusao(false)
        setConfirmacao(false)
        return setErro(false)
    }

    function ocultarModalAlertaSalvarModeloProcesso () {
        
        setConfirmacao(false)
        return exibirModalProcesso()
    }

    function removerTarefaModeloLocal (posicao: number) {

        setTarefaModeloLocal(itens => [...itens])

        tarefasModeloLocal.splice(posicao, 1)
        return tarefasModeloLocal.splice (posicao, 1)
    }

    const deletarModeloTarefa = async (idTarefaModelo: string) => {

        setProcessando(true)

        try {

            const resposta = await deletarTarefaModelo(idTarefaModelo)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacaoExclusao(true)
                atualizarDadosProcesso()
                atualizarDados()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
            
        }
    }

    const cadastrarModeloProcesso = async (e:FormEvent) => {

        e.preventDefault()

        if(tarefasModeloLocal.length === 0) {
            setSemTarefas(true)

            return setTimeout(() => {
                setSemTarefas(false)
            }, 2500)
        }

        if(titulo === undefined) {
            return
        }
        
        setProcessando(true)

        try {

            const payload = {
                tituloProcesso: titulo,
                tarefasProcesso: tarefasModeloLocal
            }
            
            const resposta = await  novaModeloProcesso (payload)

            if (resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDadosProcesso()
                return
            }

        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
            
        }
    }

    const updateModeloProcesso = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idModelo = paramsUrl.get('idModelo')

        if (idModelo === null || titulo === undefined || situacao === undefined) {
            return setProcessando(false)
        }

        const payload = {
            titulo,
            situacao
        }

        try {

            const resposta = await atualizarModeloProcesso(idModelo, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDadosProcesso()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }

    useEffect(() => {

        setModalTarefaModelo(modalTarefasProcesso)
        if(!modalTarefasProcesso) setTarefaModeloSelecionada(undefined)

    }, [modalTarefasProcesso])

    useEffect(() => {

        const tarefaLocal = tarefaModeloLocal

        if(tarefaLocal === undefined) {
            return
        }
        
        const vincularTarefaModeloLocal = (tarefaModelo: cadastrarTarefaModeloLocal) => {

            setTarefaModeloLocal (itens => [...itens, tarefaModelo])

        }

        vincularTarefaModeloLocal(tarefaLocal)

    },[tarefaModeloLocal])

    useEffect(() => {

        const buscarTarefas = async () => {

            const params = new URLSearchParams(search)
            const idModelo = params.get('idModelo')

            if(!idModelo) {
                return
            }

            try {

                const resposta = await listarTarefasModelos(idModelo)

                if (resposta.status === 200) {
                    setTarefasModelos(resposta.data)
                    setProcessando(false)
                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return
            }
        }

        buscarTarefas ()

    }, [atualizarInformacoesProcesso, search])


    return (

        <div id = 'fundoModal'>
            {modalTarefaModelo && <ModalTarefaModelo tarefaModelo={tarefaModeloSelecionada} />}
            {semTarefas && <Notificacoes icone={erroImg} texto='Adicione Pelo Menos uma Tarefa ao Modelo' cor='erro'/>}
            {confirmacao && <ModalAlerta texto= {modeloProcesso ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={modeloProcesso ? ocultarModalAlerta : ocultarModalAlertaSalvarModeloProcesso} />}
            {confirmacaoExclusao && <ModalAlerta texto='Dados Atualizados com Sucesso' btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModalAlerta} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>{!modeloProcesso ? 'Novo Modelo de Processo': 'Vizualizar Modelo de Processo'}</p>
                </span>
                <form id='formModalNovo' onSubmit={modeloProcesso ? updateModeloProcesso : cadastrarModeloProcesso}>
                    <section>
                        <span>
                            <label htmlFor="tituloModeloProcesso">Título do Modelo</label>
                            <input
                                id='tituloModeloProcesso'
                                value={titulo}
                                onChange={((e) => setTitulo(e.target.value))} 
                                placeholder='Digite o título do processo'
                                required
                                type="text" />
                        </span>
                        {modeloProcesso && <span>
                            <label htmlFor="situacaoModeloProcesso">Situação</label>
                            <select id="situacaoModeloProcesso" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>

                    <div id='tabelaModelosProcessosTarefas'>
                        <span>
                            <Button type='button' cor='salvar' isLoading={processando} onClick={exibirModalTarefaModeloDados}>Adicionar Tarefa ao Modelo</Button>
                        </span>
                        <p>Tarefas do Modelo</p>
                        <div>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th id='ladoEsquerdoContent' align='left'>Título</th>
                                        <th id='tipoTarefaModeloProcesso' align='left'>Tipo</th>
                                        {modeloProcesso && <th align='left'>Responsável</th>}
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                {modeloProcesso && <tbody>
                                    {tarefasModelos?.map((tarefa) => (
                                        <tr key={tarefa.id}>
                                            <td id='primeiraColuna' onClick={() => exibirModalDadosTarefaModelo(tarefa, tarefa.id)}>{tarefa.titulo}</td>
                                            <td id='tipoTarefaModeloProcesso' align='left' onClick={() => exibirModalDadosTarefaModelo(tarefa, tarefa.id)}>{tarefa.tipo.charAt(0).toUpperCase()}{tarefa.tipo.substring(1)}</td>
                                            <td id='usuarioResponsavelModeloTarefaContent' align='center' onClick={() => exibirModalDadosTarefaModelo(tarefa, tarefa.id)}>
                                                {tarefa.responsavel ? <img id='usuarioResponsavelModeloTarefa' src={tarefa.responsavel.avatar ? tarefa.responsavel.avatar : avatarDefault} alt='' /> : null}
                                                {tarefa.responsavel ? tarefa.responsavel.nome : 'Responsável pelo Processo'}
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={() => deletarModeloTarefa(tarefa.id)}>
                                                <img id='apagarIconeTarefa' title='Excluir Tarefa do Modelo' src={apagarImg} alt=''/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>}
                                {!modeloProcesso && <tbody>
                                    {tarefasModeloLocal.map((tarefa, index) => (
                                        <tr key={index}>
                                            <td id='primeiraColuna'>{tarefa.titulo}</td>
                                            <td id='tipoTarefaModeloProcesso' align='left'>{tarefa.tipo.charAt(0).toUpperCase()}{tarefa.tipo.substring(1)}</td>
                                            <td id='ladoDireitoContent' align='center' onClick={() => removerTarefaModeloLocal(index)}>
                                                <img id='apagarIconeTarefa' title='Excluir Tarefa do Modelo' src={apagarImg} alt=''/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>}
                            </table>
                        </div>
                    </div>
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalModeloProcesso
