import { FormEvent, useContext, useEffect, useState } from "react";
import { buscarComunicados, buscarTags, filtrarComunicado } from "../../services/Atendimento";
import { toast } from "react-toastify";
import { Button, Loader } from "../Styles";
import { comunicadoEditar, modeloComunicadosListar, modeloTag, Usuario } from "../../interfaces/atendimento";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import ModulosContratados from '../../middleware/SistemasContratados'
import Perfil from "../../middleware/Perfil";
import LoadingBarra from "../LoadingBarra";
import FiltrosDefault from "../FiltrosDefault";
import NenhumResultadoEncontrado from "../NenhumResultadoEncontrato";
import DivSituacao from "../DivSituacao";
import maisFiltrosImg from "../../assets/img/maisFiltros.png";
import avatarDefault from "../../assets/img/avatarDefault.png";
import ModalComunicado from "../ModalComunicados";
import Organizacao from "../../middleware/Organizacao";

function Comunicados() {
  const { atualizarInformacoes, modal, modalExibir } = useContext(ModalEstadoContext);

  const [processando, setProcessando] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)
  const [termo, setTermo] = useState<string>("")
  const [maisFiltros, setMaisFiltros] = useState<boolean>(false)
  const [comunicados, setComunicados] = useState<modeloComunicadosListar[]>([])
  const [criadoresComunicado, setCriadoresComunicado] = useState<any>([])
  const [gruposOuTags, setGruposOuTags] = useState<any>([])

  const [usuario, setUsuario] = useState<string>("")
  const [grupo, setGrupo] = useState<string>("")
  const [situaçãoComunicado, setSituaçãoComunicado] = useState<string>("")
  const [dataInicial, setDataInicial] = useState<string>("")
  const [dataFinal, setDataFinal] = useState<string>("")

  const [comunicadoSelecionado, setComunicadoSelecionado] = useState<comunicadoEditar>()

  const filtrarComunicados = async (e: FormEvent) => {
    e.preventDefault()
    setNenhumResultado(false)
    setProcessando(true)
    try {

      const resposta = await filtrarComunicado({
        titulo: termo,
        usuario,
        grupo,
        situacao: situaçãoComunicado,
        dataInicial,
        dataFinal
      })
      setComunicados(resposta.data)
      setProcessando(false)
      setMaisFiltros(false)
      if (resposta.data.length === 0) return setNenhumResultado(true)
    } catch (error) {
      setProcessando(false)
      toast.error("Ocorreu um erro ao tentar filtrar os Comunicados;")
    }
  }

  useEffect(() => {
    const buscarListaDeComunicados = async () => {
      setProcessando(true)
      try {
        const resposta = await buscarComunicados();
        const comunicados = resposta.data
        const criadores = comunicados.map((comunicado: modeloComunicadosListar) => ({
          id: comunicado.usuario.id,
          nome: comunicado.usuario.nome
        }));

        const nomesUnicosDosCriadores = Array.from(
          new Map(criadores.map((criador: Usuario) => [criador.id, criador])).values()
        );

        setCriadoresComunicado(nomesUnicosDosCriadores)

        if (resposta.data.length === 0) setNenhumResultado(true)
        if (resposta.status === 200) {
          setProcessando(false)
          setComunicados(resposta.data)
        }
      } catch (error) {
        setProcessando(false)
        setNenhumResultado(true)
        toast.error("Não foi possivel listar os Comunicados")
      }
    }

    buscarListaDeComunicados()
  }, [atualizarInformacoes])

  useEffect(() => {
    const buscarTodasTags = async () => {
      setProcessando(true)
      try {
        const resposta = await buscarTags()
        const tags = resposta.data

        const gruposTag = tags.map((tag: modeloTag) => tag.nome)
        const gruposTagUnicos = Array.from(new Set(gruposTag));
        setGruposOuTags(gruposTagUnicos)

        setProcessando(false)
      } catch (error) {
        setProcessando(false)
      }
    }
    buscarTodasTags()
  }, [])

  return (
    <ModulosContratados modulo={["Atendimento"]}>
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
        <div id='paginaContainer'>
            {processando && <LoadingBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {modal.nome === "ModalComunicado" && <ModalComunicado comunicado={comunicadoSelecionado} />}
            <FiltrosDefault termo={termo} situacao={situaçãoComunicado} placeholder='Pesquise pelo título da mensagem' tipo={"Comunicado"} onChangeTermo={(e) => { setTermo(e.target.value) }} onChangeSituacao={(e) => { setSituaçãoComunicado(e.target.value) }} onSubmit={(e) => { filtrarComunicados(e) }} acaoBtn={() => { setComunicadoSelecionado(undefined); modalExibir("ModalComunicado") }} listaSituacao={[{ nome: 'Todas', valor: '' }, { nome: 'Agendado', valor: 'Agendado' }, { nome: 'Enviado', valor: 'Enviado' }, { nome: 'Cancelado', valor: 'Cancelado' }]} genero='masculino' isLoading={processando} maisFiltros={true} acaoMaisFiltro={() => { setMaisFiltros(true) }} />
            {maisFiltros && <section className='maisOpcoesFiltrosComponente'>
            <form className='maisOpcoesFiltros' onSubmit={filtrarComunicados}>
                <span className='filtrosContainerTitulo'>
                <img className='filtrosAvancados' src={maisFiltrosImg} alt='' />
                <p>Filtros Avançados</p>
                </span>
                <section className='opcoesFiltros'>
                <section>
                  <span>
                    <label htmlFor="">Usuário</label>
                    <select name="" id="" onChange={(e) => setUsuario(e.target.value)} defaultValue={usuario}>
                      <option value="">Todos</option>
                      {criadoresComunicado.map((criadores: any) => (
                        <option value={criadores.id} key={criadores.id}>{criadores.nome}</option>
                      ))}
                    </select>
                  </span>
                </section>
                <section>
                    <span>
                    <label htmlFor="">Grupo/Tag</label>
                    <select name="" id="" onChange={(e) => setGrupo(e.target.value)} defaultValue={grupo}>
                        <option value="">Todos</option>
                        <option value="cliente">Clientes</option>
                        <option value="responsável">Responsáveis</option>
                        <option value="colaborador">Colaboradores</option>
                        <option value="fornecedor">Fornecedores</option>
                        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                        <>
                            <option value="candidatos">Banco de Talentos</option>
                        </>
                        </Organizacao>
                        {gruposOuTags.map((grupoTag: any, index: number) => (
                        <option value={grupoTag} key={index}>{grupoTag.charAt(0).toUpperCase() + grupoTag.substring(1)}</option>
                        ))}
                    </select>
                    </span>
                </section>
                <section>
                    <span>
                    <label htmlFor="">Situação</label>
                    <select name="" id="" defaultValue={situaçãoComunicado} onChange={(e) => setSituaçãoComunicado(e.target.value)}>
                        <option value="">Todos</option>
                        <option value="Agendado">Agendado</option>
                        <option value="Enviado">Enviado</option>
                        <option value="Cancelado">Cancelado</option>
                    </select>
                    </span>
                </section>
                <section>
                    <span>
                    <label htmlFor="">Data Inicial</label>
                    <input type="datetime-local" onChange={(e) => setDataInicial(e.target.value)} />
                    </span>
                    <span>
                    <label htmlFor="">Data Final</label>
                    <input type="datetime-local" onChange={(e) => setDataFinal(e.target.value)} min={dataInicial} />
                    </span>
                </section>
                </section>
                <span id="botoesContainerFiltrosTarefas">
                <Button type='reset' cor='cancelar' isLoading={processando} onClick={() => setMaisFiltros(false)}>Cancelar</Button>
                <Button type='submit' cor='salvar' isLoading={processando}>
                    {!processando && 'Buscar Dados'}
                    {processando && <Loader isLoading={processando} />}
                </Button>
                </span>
            </form>
            </section>}
            <section id='fundoContainerPagina'>
            <table id='tabelaListaContainer'>
                <thead>
                <tr>
                    <th align='left'>Título</th>
                    <th align='center' className='hide-responsive'>Grupo / Tag</th>
                    <th align='center' className='hide-responsive'>Data de Envio</th>
                    <th align='center' className='hide-responsive'>Usuário</th>
                    <th align='center'>Situação</th>
                </tr>
                </thead>
                <tbody>
                {comunicados.map((comunicado: modeloComunicadosListar) => (
                    <tr key={comunicado.id} onClick={() => {
                    setComunicadoSelecionado(comunicado)
                    modalExibir("ModalComunicado")
                    }}>
                    <td className='primeiraColuna'>{comunicado.titulo}</td>
                    <td align='center' className='hide-responsive'>{`${comunicado.grupo.charAt(0).toUpperCase()}${comunicado.grupo.substring(1)}`}</td>
                    <td align='center' className='hide-responsive'>{comunicado?.dataEnvio && new Date(comunicado?.dataEnvio).toLocaleString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    }).replace(',', ' às ')}</td>
                    <td align='center' className='hide-responsive'>
                        <span className="listaUsuarioAvatar">
                        {comunicado.usuario && <img src={comunicado.usuario?.avatar || avatarDefault} className='avatar' alt="" />}
                        {comunicado.usuario?.nome}
                        </span>
                    </td>
                    <td align='center' className='ladoDireitoContent'>
                        <DivSituacao texto={comunicado.situacao} cor={comunicado.situacao.toLowerCase()} />
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </section>
        </div>
        </Perfil >
    </ModulosContratados>
  );
}

export default Comunicados;