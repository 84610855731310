import { useEffect } from "react";
import Nps from '../../components/Nps'

function NpsAtendimento() {

    useEffect(() => {
        document.title = "Avaliação do Atendimento | Gesttor"
      }, [])

  return (
    <Nps/>
  )
}

export default NpsAtendimento
