import { useState, useEffect, useContext } from 'react'
import { listarModelosProcessosTamplet } from '../../services/modelosProcessosTamplets'
import { modelosProcessosTampletsResposta } from '../../interfaces/modelosProcessosTamplets'
import { importarModeloTemplat } from '../../services/modelosProcessosTamplets'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { toast } from 'react-toastify'
import { Button } from '../Styles'
import processoImg from '../../assets/img/processos.png'
import '../../styles/paginaListaDados.css'
import './modelosprocessostamplet.css'

function ModalModelosTemplets () {

    const { modalExibir } = useContext(ModalEstadoContext)
    const { atualizarDadosProcesso } = useContext(ModelosProcessosContext)

    const [templats, setTemplats] = useState<modelosProcessosTampletsResposta[]>([])
    const [processando, setProcessando] = useState(true)



    const importarModelo = async (idTemplat: string) => {

        setProcessando(true)

        try {

            const resposta = await importarModeloTemplat(idTemplat)

            if(resposta.status === 201) {
                toast.success('Modelo Importado com Sucesso')
                atualizarDadosProcesso()
                return modalExibir('')
            }
            
        } catch (error) {

            setProcessando(false)
            return toast.error('Falta ao Importar o Modelo')
        }

    }

    useEffect(() => {
        
        const buscarModelos = async () => {

            setProcessando(true)

            const params = {
                termo: '',
                situacao: 'Ativo'
            }

            try {

                const resposta = await listarModelosProcessosTamplet(params)

                if (resposta.status === 200) {

                    setTemplats(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                setProcessando(false)
                
                return toast.error('Falha ao Listar os Tamplets')
            }
        }

        buscarModelos ()

    }, [])


    return (
        <div className="fundoModal">
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>Templats Disponíceis</p>
                </span>
                <form id='formModalNovo'>
                    <section>
                        <div className='tamplatsProcessosDisponiveisContainer'>
                            {templats.map((templat) => (
                                <div className='tamplatsProcessosDisponiveisContent' key={templat.id}>
                                    <div>
                                        <img src={templat.icone} alt=''/>
                                    </div>
                                    <div>
                                        <h1>{templat.titulo}</h1>
                                        <p>{templat.descricao}</p>
                                    </div>
                                    <p className='importarTemplats' onClick={(e) => importarModelo(templat.id)}>Importar Modelo</p>
                                </div>
                            ))}
                        </div>
                    </section>
                </form>
                <span id="botoesContainer">
                    <Button type='button' cor='cancelar' isLoading={processando} onClick={() => modalExibir('')}>Cancelar</Button>
                </span>
            </section>
        </div>
    )
}

export default ModalModelosTemplets