import { useState } from "react";
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import Perfil from "../../middleware/Perfil";
import SubMenuOpcoes from "../SubMenuOpcoes";
import { MdLightbulbOutline } from "react-icons/md";

function MenuOpcoesProjetos(props: menuOpcoes) {

  const [subMenu, setSubMenu] = useState(false)

  function exibirSubMenu() {
    setSubMenu(subMenu => !subMenu)
  }
  return (
    <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor', 'desenvolvedor']}>
        <div>
          <div id="menuOpcoes" onClick={exibirSubMenu}>
            <MdLightbulbOutline id="iconeMenu" color="#ffffff" />
            <p id='tituloMenu'>{props.titulo}</p>
            <h3>Novo</h3>
          </div>
          <div id="submenuOpcoes">
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
              <>
                {subMenu && <SubMenuOpcoes redirect={'/projetos'} texto='Todos os Projetos' />}
              </>
            </Perfil>
            <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor', 'desenvolvedor']}>
              <>
                {subMenu && <SubMenuOpcoes redirect={'/projetos?meus-projetos=true'} texto='Meus Projetos' />}
              </>
            </Perfil>
            <Perfil perfil={['master']}>
              <>
                {subMenu && <SubMenuOpcoes redirect={'/banco-indicadores'} texto='Banco de Indicadores' />}
              </>
            </Perfil>
          </div>
        </div>
    </Perfil>
)}

export default MenuOpcoesProjetos;
