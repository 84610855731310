import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dre } from '../../interfaces/graficos'
import { dreAno } from '../../services/dashboardFinanceiro'
import Perfil from '../../middleware/Perfil'
import LoadingCirculo from '../LoadingCirculo'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import './dreano.css'

function DreAno () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState<dre>()
    const [lucroAnoValor, setLucroAnoValor] = useState(0)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const ticket = async () => {

            try {
                const resposta = await dreAno ()
                setCarregando (false)
                setDados (resposta.data[0])

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        ticket ()

    }, [navigate])

    useEffect (() => {

        const lucroAno = dados?.faturamento === undefined ? 0: dados.faturamento - dados?.despesas


        return setLucroAnoValor(lucroAno)
        
    }, [dados])


    return (

        <Perfil perfil={['master']}>
            <div id = 'dreAno'>
                <p id='dreAnoP'>DRE DO ANO</p>
                {carregando && <LoadingCirculo/>}
                {grafico && <section>
                    <span>
                        <p>Faturamento</p>
                        <h1>{dados?.faturamento === undefined ? 'R$ 0,00' : Number(dados.faturamento).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>
                    </span>
                    <span>
                        <p>Depesas</p>
                        <h1>{dados?.despesas === undefined ? 'R$ 0,00' : Number(dados.despesas).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>
                    </span>
                    <span>
                        <p>Lucro</p>
                        <h1>{lucroAnoValor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</h1>
                    </span>
                </section>}
                {semDados && <NenhumDadoGrafico texto='Sem Dados para' subtexto='exibir o gráfico'/>}
            </div>
        </Perfil>
        
    )
}

export default DreAno