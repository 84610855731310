import BASEURLPROJETOS from '../api/projetos'
import { ProjetoPayload, AtualizarProjetoPayload, ListarProjetosQuery, MembroProjetoPayload } from "../../interfaces/projetos"

export function listarProjetos(queryParams: ListarProjetosQuery) {
  return BASEURLPROJETOS.get(`/projetos`, { params: queryParams });
}

export function cadastrarProjeto(payload: ProjetoPayload) {
  return BASEURLPROJETOS.post(`/projetos`, payload);
}

export function atualizarProjeto(idProjeto: string, payload: AtualizarProjetoPayload) {
  return BASEURLPROJETOS.patch(`/projetos/${idProjeto}`, payload);
}

export function cadastrarMembroProjeto(payload: MembroProjetoPayload) {
  return BASEURLPROJETOS.post(`/projetos/membros`, payload);
}

export function excluirMembroProjeto(idMembro: string) {
  return BASEURLPROJETOS.patch(`/projetos/membros/${idMembro}`);
}