import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { propsFiltrosDefault } from '../../interfaces/propsFiltrosDefault'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { AuthContext } from '../../contexts/Auth'
import { Button, Loader } from '../Styles'
import ModalMeuPerfil from '../ModalMeuPerfil'
import ModalAssinatura from '../ModalAssinatura'
import avatarDefault from '../../assets/img/avatarDefault.png'
import avatarOrganizacaoDefault from '../../assets/img/organizacao.png'
import maisFiltrosImg from '../../assets/img/maisFiltros.png'
import './filtrosDefault.css'

function FiltrosDefault (props: propsFiltrosDefault) {

    const navigate = useNavigate()

    const { exibirModalSecundario, modalEstadoSecundario, modalEstadoTernario } = useContext(ModalEstadoContext)
    const { isAuth } = useContext(AuthContext)

    const [avatar, setAvatar] = useState ('')
    const [avatarOrganizacao, setAvatarOrganizacao] = useState('')
    const [dadosUsuario, setDadosUsuario] = useState(false)
    const [modalPerfil, setModalPerfil] = useState(false)
    const [modalAssinatura, setModalAssinatura] = useState(false)

    const perfilUsuario = localStorage.getItem('perfilGesttor')

    const situacoesPermitidasPorPerfil: {[key: string]: string[]} = {
        comercial: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Concluído',
            'Oculto',
            'Nps',
            'Visivel'
        ],
        desenvolvedor: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Oculto',
            'Visivel',
            'Aguardando Aprovação',
            'Aguardando Inscritos',
            'Recrutando',
            'Atrasado',
            'Repescagem',
            'Nps',
            'Finalizado',
            'Enviado',
            'Agendado',
            'Concluído',
            'Ativo'
        ],
        suporte: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Oculto',
            'Nps',
            'Visivel'
        ],
        consultor: [
            'Atrasadas', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Todos', 
            'Ativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Oculto',
            'Visivel',
            'Aguardando Aprovação',
            'Aguardando Inscritos',
            'Recrutando',
            'Atrasado',
            'Repescagem',
            'Nps',
            'Finalizado',
            'Concluído',
            'Ativo'
        ],
        atendimento: [
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Pendentes', 
            'Concluídas', 
            'Concluídos', 
            'Cancelados', 
            'Sem Contato', 
            'Em Negociação',
            'Enviado', 
            'Conquistado', 
            'Perdido',
            'Todos',
            'Ativos', 
            'Inativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados',
            'Oculto',
            'Visivel',
            'Aguardando Aprovação',
            'Aguardando Inscritos',
            'Recrutando',
            'Atrasado',
            'Repescagem',
            'Nps',
            'Finalizado',
            'Enviado',
            'Agendado',
            'Concluído',
            'Ativo'
        ],
        administrativo: [
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Recebidas', 
            'Todas', 
            'Pendentes', 
            'Concluídas', 
            'Ativas', 
            'Inativas', 
            'Concluídos', 
            'Cancelados', 
            'Pagas', 
            'Pagamento Parcial', 
            'Aguardando Pagamento', 
            'Sem Contato', 
            'Em Negociação',
            'Enviado', 
            'Conquistado', 
            'Perdido', 
            'Todos',
            'Ativos', 
            'Inativos', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados',
            'Oculto',
            'Visivel',
            'Aguardando Aprovação',
            'Aguardando Inscritos',
            'Recrutando',
            'Atrasado',
            'Repescagem',
            'Nps',
            'Finalizado',
            'Enviado',
            'Agendado',
            'Concluído',
            'Ativo'
        ],
        master: [
            'Agendados',
            'Aguardando Recebimento', 
            'Atrasadas', 
            'Recebimento Parcial', 
            'Recebidas', 
            'Todas', 
            'Pendentes', 
            'Concluídas', 
            'Todos', 
            'Ativos', 
            'Inativos', 
            'Ativas', 
            'Inativas', 
            'Concluídos', 
            'Cancelados', 
            'Pagas', 
            'Pagamento Parcial', 
            'Aguardando Pagamento', 
            'Sem Contato', 
            'Em Negociação', 
            'Enviados',
            'Conquistado', 
            'Perdido', 
            'Sem Análise', 
            'Em Análise', 
            'Aguardando Retorno do Cliente', 
            'Aguardando Retorno do Suporte',
            'Finalizado',
            'Conclúido',
            'Aceita',
            'Em Desenvolvimento',
            'Recusada',
            'Finalizada',
            'Aguardando Assinatura',
            'Assinado',
            'Cancelado',
            'Completo',
            'Incompleto',
            'Finalizados',
            'Oculto',
            'Visivel',
            'Aguardando Aprovação',
            'Aguardando Inscritos',
            'Recrutando',
            'Atrasado',
            'Repescagem',
            'Nps',
            'Finalizado',
            'Enviado',
            'Agendado',
            'Concluído',
            'Ativo'
        ]
    }

    const situacoesPermitidas = perfilUsuario ? situacoesPermitidasPorPerfil[perfilUsuario] || [] : []

    const exibirDadosUsuario = () => setDadosUsuario(!dadosUsuario)
    const exibirModalPerfil = () => exibirModalSecundario()

    const sair = () => {

        localStorage.removeItem('usuarioGesttorAvatar')
        localStorage.removeItem('perfilGesttor')
        localStorage.removeItem('usuarioGesttor')
        localStorage.removeItem('organizacaoGesttor')
        localStorage.removeItem('authGesttor')
        localStorage.removeItem('organizacaoGesttorAvatar')
        localStorage.removeItem('modulosOrganizacoes')
        document.cookie = 'authGesttor= ; expires=true, 01 Jan 2023 00:00:01 GMT'
        document.cookie = 'modulosGesttor= ; expires=true, 01 Jan 2023 00:00:01 GMT'

        isAuth()

        return navigate('/')
    }
    
    useEffect(() => {
        setModalPerfil(modalEstadoSecundario)
    }, [modalEstadoSecundario])

    useEffect(() => {
        setModalAssinatura(modalEstadoTernario)
    }, [modalEstadoTernario])

    useEffect(() => {

        const avatar = () => {

            const avatarImg = localStorage.getItem('usuarioGesttorAvatar')

            if (avatarImg === 'null' || avatarImg === null) {

                return setAvatar(avatarDefault)
            }

            return setAvatar(avatarImg)
        }

        avatar()
        
    }, [])

    useEffect(() => {

        const avatarOrganizacao = () => {

            const avatarOrganizacaoImg = localStorage.getItem('organizacaoGesttorAvatar')

            if (avatarOrganizacaoImg === 'null' || avatarOrganizacaoImg === null) {

                return setAvatarOrganizacao(avatarOrganizacaoDefault)
            }

            return setAvatarOrganizacao(avatarOrganizacaoImg)
        }

        avatarOrganizacao()

    }, [])

    return (

        <section id="fundoContainerFiltrosDefault">
            {modalPerfil && <ModalMeuPerfil/>}
            {modalAssinatura && <ModalAssinatura />}
            <form id='containerEsqueroPesquisa' onSubmit={props.onSubmit}>
                <input
                    id='inputTermoPesquisa'
                    value={props.termo}
                    type="text"
                    placeholder={props.placeholder}
                    onChange={props.onChangeTermo} />

                <select id='selectOpcoesPesquisa' value={props.situacao} onChange={props.onChangeSituacao}>
                    {props.listaSituacao
                        .filter(situacao => situacoesPermitidas.includes(situacao.nome))
                        .map((situacao) => (
                            <option key={situacao.valor} value={situacao.valor}>
                            {situacao.nome}
                            </option>
                        ))}
                </select>

                {props.maisFiltros && <img id='maisFiltrosPesquisa' title='Mais Opções de Filtro' src={maisFiltrosImg} alt='' onClick={props.acaoMaisFiltro}/>}

                <Button type='submit' isLoading ={props.isLoading} cor='filtros'>
                    {!props.isLoading && <>Pesquisar</>}
                    {props.isLoading && <Loader isLoading={props.isLoading}/>}
                </Button>
                {props.tipo && <Button type='button' isLoading = {props.isLoading} cor='filtros' onClick={props.acaoBtn}>
                    {`Nov${props.genero === 'masculino' ? 'o' : 'a'} ${props.tipo?.charAt(0).toUpperCase()}${props.tipo?.substring(1)}`}
                </Button>}
            </form>
            <span id='containerDireitoPesquisa'>
                <img id='avatarUsuarioGesttor' src={avatar} alt='' onClick={exibirDadosUsuario}/>
                <img className='avatarOrganizacaoGesttor' src={avatarOrganizacao} alt='' title='Empresa que você está trabalhando atualmente'/>
                {dadosUsuario && <span id='dadosUsarioDefaul'>
                    <p onClick={() => exibirModalPerfil()}>Meu Perfil</p>
                    <p onClick={sair}>Sair</p>
                </span>}
            </span>
        </section>
    )
}

export default FiltrosDefault
