import { FormEvent, useContext, useEffect, useState } from "react";
import { Button, Loader } from "../Styles";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { toast } from "react-toastify";
import { IoMdAddCircle } from "react-icons/io";
import { listarCadastros } from "../../services/cadastros";
import { listarUsuariosOrganizacao } from "../../services/usuarios";
import { membroRespostaPayload, ProjetoListarParams } from "../../interfaces/projetos";
import { cadastrosRespostaPayload } from "../../interfaces/cadastros"
import { atualizarProjeto, cadastrarMembroProjeto, cadastrarProjeto, excluirMembroProjeto } from "../../services/projetos";
import { criptografarDado } from "../../helpers";
import projetoImg from '../../assets/img/projeto.png'
import "./index.css"

function ModalProjeto({ projeto }: ProjetoListarParams) {
  const { modalExibir, atualizarDados } = useContext(ModalEstadoContext)

  const [processando, setProcessando] = useState(false);
  const [clientes, setClientes] = useState<cadastrosRespostaPayload[]>([]);
  const [cliente, setCliente] = useState("");

  const [grupo, setGrupo] = useState(projeto?.grupo || "");
  const [usuarios, setUsuarios] = useState<membroRespostaPayload[]>([]);

  const [membro, setMembro] = useState("");
  const [membrosAdicionados, setMembrosAdicionados] = useState<membroRespostaPayload[]>([]);

  const [titulo, setTitulo] = useState<string>(projeto?.titulo || "");
  const [descricao, setDescricao] = useState<string>(projeto?.descricao || "");

  const [situacao, setSituacao] = useState(projeto?.situacao);

  const handleSubmitProjeto = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      if (membrosAdicionados.length === 0) return toast.info("É necessário adicionar pelo menos 1 Membro no Projeto")

      const membrosFormatados = membrosAdicionados.map((membro) => ({
        idUsuario: membro.id,
      }));

      const payload = {
        idCadastro: cliente,
        titulo,
        descricao,
        grupo,
        membros: membrosFormatados,
      }

      const resposta = await cadastrarProjeto(payload)
      atualizarDados()
      modalExibir("")
      if (resposta.status === 201) return toast.success("Projeto Cadastrado com Sucesso")
    } catch (error) {
      toast.error("Erro ao Cadastrar Projeto")
    } finally {
      atualizarDados()
      setProcessando(false)
    }
  }

  const handleUpdateProjeto = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    if (!projeto) return toast.error("Projeto não encontrado")
    try {

      const payload = {
        titulo,
        descricao,
        situacao
      }

      const idProjetoCript = criptografarDado(projeto?.id.toString())

      const resposta = await atualizarProjeto(idProjetoCript, payload)
      modalExibir("")
      if (resposta.status === 200) return toast.success("Projeto Atualizado com Sucesso")
    } catch (error) {
      toast.error("Erro ao Atualizar Projeto")
    } finally {
      atualizarDados()
      setProcessando(false)
    }
  }

  const cadastrarMembro = async (e: FormEvent) => {
    e.preventDefault();
    setProcessando(true);

    if (!projeto) {
      toast.error("Projeto não encontrado");
      setProcessando(false);
      return;
    }

    if (!membro) {
      toast.error("Membro não encontrado");
      setProcessando(false);
      return;
    }

    const idProjetoCript = criptografarDado(projeto?.id.toString());
    const idMembroCript = criptografarDado(membro);

    try {
      const payload = {
        idProjeto: idProjetoCript,
        idMembro: idMembroCript,
      };

      const resposta = await cadastrarMembroProjeto(payload);

      if (resposta.status === 201) {
        atualizarDados()
        adicionarMembro()
        modalExibir("")
        toast.success("Membro cadastrado com sucesso!");
      }
    } catch (error) {
      toast.error("Não foi possível cadastrar um membro no projeto");
    } finally {
      setProcessando(false);
    }
  };

  const adicionarMembro = () => {
    if (!membro || membrosAdicionados.some((m) => m.id === membro)) return;

    const membroSelecionado = usuarios.find((user) => user.id === membro);
    if (membroSelecionado) {
      setMembrosAdicionados([...membrosAdicionados, membroSelecionado]);
    }
    setMembro("");
  };

  const removerMembro = (id: string | number) => {
    setMembrosAdicionados(membrosAdicionados.filter((m) => m.id !== id));
  };

  const excluirMembroProjetoExistente = async (idMembro: string) => {
    if (projeto && projeto.projetosMembros.length === 1) return toast.info("O Membro não pode ser excluído, pois é a único Membro existente no projeto.")
    setProcessando(true)
    try {
      const idMembroCript = criptografarDado(idMembro)
      const resposta = await excluirMembroProjeto(idMembroCript)

      if (resposta.status === 200) {
        toast.success("Membro Excluido com Sucesso")
        atualizarDados()
        modalExibir("")
      }
    } catch (error) {
      toast.error("Erro ao Excluir Membro")
    } finally {
      setProcessando(false)
    }
  }

  const membrosSelecionados = [
    ...membrosAdicionados,
    ...(projeto?.projetosMembros.map(membro => ({
      ...membro,
      nome: membro.usuario?.nome
    })) || [])
  ];

  const membrosFiltrados = membrosSelecionados.filter(
    (membro, index, self) =>
      self.findIndex(m => m.id === membro.id) === index
  );

  useEffect(() => {
    const buscarClientes = async () => {
      try {
        const params = {
          tipo: 'cliente',
          termo: '',
          pagina: 1,
          situacao: 'ativo'
        }

        const resposta = await listarCadastros(params)
        if (resposta.status === 200) {
          return setClientes(resposta.data)
        }

      } catch (error) {
        setProcessando(false)
        return toast.error("Falha ao Buscar o Clientes.")
      }
    }
    buscarClientes()
  }, [])

  useEffect(() => {
    const buscarUsuarios = async () => {
      try {
        const params = {
          termo: '',
          situacao: 'ativo'
        }

        const resposta = await listarUsuariosOrganizacao(params)
        if (resposta.status === 200) {
          return setUsuarios(resposta.data)
        }

      } catch (error) {
        setProcessando(false)
        return toast.error("Falha ao Buscar os Usuarios.")
      }
    }
    buscarUsuarios()
  }, [])

  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        <span id='novoContainerTitulo'>
            <img id='adicionarModalIcone' src={projetoImg} alt='' />
            <p>{!projeto ? 'Novo Projeto' : 'Vizualizar Projeto'}</p>
        </span>
        <form id='formModalNovo' onSubmit={projeto ? handleUpdateProjeto : handleSubmitProjeto}>
          <section>
            <span>
                <label>Cliente</label>
                <select value={projeto?.idCadastro || cliente} onChange={(e) => setCliente(e.target.value)} required={!projeto} disabled={!!projeto}>
                    <option value="" disabled>Selecione o Cliente</option>
                    {clientes.map((cliente, index) => (
                    <option value={cliente.id} key={index}>{cliente.nomeFantasia}</option>
                    ))}
                </select>
                </span>
                <span>
                    <label>Grupo</label>
                    <select onChange={(e) => setGrupo(e.target.value)} required={!projeto} defaultValue={projeto?.grupo || ""} disabled={!!projeto}>
                        <option value="" disabled>Selecione o Grupo</option>
                        <option value="Gestão Financeira" >Gestão Financeira</option>
                        <option value="Gestão de Pessoas" >Gestão de Pessoas</option>
                        <option value="Desenvolvimento de Software" >Desenvolvimento de Software</option>
                        <option value="Outros">Outros</option>
                    </select>
                </span>
                { projeto &&
                    <span>
                        <label>Situação</label>
                        <select onChange={(e) => setSituacao(e.target.value)} required={!projeto} defaultValue={situacao}>
                            <option value="Ativo">Ativo</option>
                            <option value="Cancelado">Cancelado</option>
                            <option value="Concluído">Concluído</option>
                        </select>
                    </span>
                }
            </section>
            <section>
                <span>
                    <label>Título</label>
                    <input type="text" placeholder="Titulo do Projeto" onChange={(e) => setTitulo(e.target.value)} required={!projeto} defaultValue={projeto?.titulo} />
                </span>
            </section>

            <section>
                <span>
                    <label>Descrição</label>
                    <textarea className="textAreaModal" rows={5} placeholder="Descrição do projeto" onChange={(e) => setDescricao(e.target.value)} defaultValue={projeto?.descricao}></textarea>
                </span>
            </section>
            
            <section>
                <span>
                    <details className="details-container-tags">
                        <summary>
                            Adicionar Membros
                        </summary>
                        <span className="container-input-menbros-projetos">
                            <select defaultValue="" onChange={(e) => setMembro(e.target.value)}>
                                <option value="" disabled>Selecione um Membro</option>
                                {usuarios.map((membro) => (
                                    <option key={membro.id} value={membro.id} disabled={membrosSelecionados.some((membroSelecionado) => (membroSelecionado.idUsuario === membro.id || membroSelecionado.id === membro.id))}>
                                        {membro.nome}
                                    </option>
                                ))}
                            </select>

                            <IoMdAddCircle onClick={projeto ? cadastrarMembro : adicionarMembro} className="btn-add-membro" title="Clique para Adicionar o Membro" />
                        </span>
                        <span className="container-input-menbros-projetos-adicionados">
                            {membrosFiltrados.length > 0 && membrosFiltrados.map((membro) => (
                            <p key={membro.id} className="tag-span" title="Clique para Remover o Membro" onClick={() => projeto ? excluirMembroProjetoExistente(membro.id.toString()) : removerMembro(membro.id)}>
                                {membro.nome}
                            </p>
                            ))
                            }
                        </span>
                    </details>
                </span>
            </section>

            <span id="botoesContainer">
              <Button type='button' cor='cancelar' isLoading={processando} onClick={() => modalExibir("")}>Fechar</Button>
              <Button type='submit' cor='salvar' isLoading={processando}>
                {!processando && !projeto && 'Cadastrar'}
                {!processando && projeto && 'Atualizar'}
                {processando && <Loader isLoading={processando} />}
              </Button>
            </span>
        </form>
      </section>
    </div>
  );
}

export default ModalProjeto;