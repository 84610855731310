import { useState, useContext, useEffect, FormEvent } from 'react'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Loader, Button } from '../Styles'
import { listarCadastros } from '../../services/cadastros'
import { buscarTags } from "../../services/Atendimento"
import { toast } from 'react-toastify'
import { modeloTag } from '../../interfaces/atendimento'
import ModulosContratados from '../../middleware/SistemasContratados'
import avatarDefault from '../../assets/img/avatarDefault.png'
import FiltrosDefault from '../FiltrosDefault'
import DivSituacao from '../DivSituacao'
import Perfil from '../../middleware/Perfil'
import LoagindBarra from '../LoadingBarra'
import LoadingCirculo from '../LoadingCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ModalContatos from '../ModalContatos'
import maisFiltrosImg from "../../assets/img/maisFiltros.png"

function Contatos() {

  const { modal, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext)

  const [cadastros, setCadastros] = useState<cadastrosRespostaPayload[]>([])
  const [cadastroSelecionado, setCadastroSelecionado] = useState<cadastrosRespostaPayload>()
  const [maisCadastros, setMaisCadastros] = useState(false)
  const [maisFiltros, setMaisFiltros] = useState<boolean>(false)
  const [gruposOuTags, setGruposOuTags] = useState<any>([])
  const [tag, setTag] = useState<string>("")
  const [pagina, setPagina] = useState(2)
  const [termo, setTermo] = useState('')
  const [situacao, setSituacao] = useState('ativo')
  const [processando, setProcessando] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)


  useEffect(() => {
    
    const listarContatos = async () => {

        setProcessando(true)
        
        try {
            const payload = {
                tipo: 'cliente',
                termo: "",
                tag: '',
                pagina: 1,
                situacao: 'ativo',
            }
            const resposta = await listarCadastros(payload)
        
            setCadastros(resposta.data)
            setProcessando(false)

            return

        } catch (error) {
            setProcessando(false)
            toast.error('Ocorreu um Eror ao Listar os Dados')
        }
    }

    listarContatos()

  }, [atualizarInformacoes])

  const filtrarCadastro = async (e: FormEvent) => {

    e.preventDefault()
    setNenhumResultado(false)
    setProcessando(true)

    try {

      const params = {
        tipo: 'cliente',
        tag,
        termo,
        pagina: 1,
        situacao
      }

      const resposta = await listarCadastros(params)

      if (resposta.data.length === 0) {
        setCadastros(resposta.data)
        setProcessando(false)
        setNenhumResultado(true)
        setMaisFiltros(false)

        return
      }

      if (resposta.status === 200) {
        setCadastros(resposta.data)
        setProcessando(false)
        setMaisFiltros(false)

        return 
      }

    } catch (error) {

      setProcessando(false)
      return toast.error("Erro ao Filtrar Contatos")
    }
  }

  const scrollInfinito = (e: any) => {

    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const posicaoScroll = (scrollTop / (scrollHeight - clientHeight) * 100)

    if (posicaoScroll >= 95) {
      setPagina(prevtState => prevtState + 1)

      const buscarMaisCadastros = async () => {
        setMaisCadastros(true)

        const params = {
          tipo: 'cliente',
          tag: '',
          pagina,
          termo,
          situacao
        }

        try {
          const resposta = await listarCadastros(params)
          if (resposta.status === 200) {
            setCadastros(prevDate => [...prevDate, ...resposta.data])
            return setMaisCadastros(false)
          }

        } catch (error) {
          setMaisCadastros(false)
          toast.error("Erro ao listar mais cadastros")
        }
      }
      buscarMaisCadastros()
    }
  }

  useEffect(() => {

      const buscarTodasTags = async () => {

        setProcessando(true)
        try {

          const resposta = await buscarTags()
          const tags = resposta.data
  
          const gruposTag = tags.map((tag: modeloTag) => tag.nome)
          const gruposTagUnicos = Array.from(new Set(gruposTag));
          setGruposOuTags(gruposTagUnicos)
  
          setProcessando(false)

        } catch (error) {
          setProcessando(false)
        }
      }
      buscarTodasTags()
    }, [])

  return (
    <ModulosContratados modulo={["Atendimento"]}>
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
        <div id='paginaContainer'>
            {processando && <LoagindBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {modal.nome === "ModalContatos" && <ModalContatos cadastro={cadastroSelecionado} />}
            <FiltrosDefault termo={termo} situacao={situacao} tipo={""} placeholder='Pesquise pelo nome fantasia ou razão social' onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={filtrarCadastro} acaoBtn={() => { }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Ativos', valor: 'ativo' }, { nome: 'Inativos', valor: 'inativo' }]} genero='masculino' isLoading={processando} maisFiltros={true} acaoMaisFiltro={() => setMaisFiltros(true)}/>
            {maisFiltros && <section className='maisOpcoesFiltrosComponente'>
            <form className='maisOpcoesFiltros' onSubmit={filtrarCadastro}>
                <span className='filtrosContainerTitulo'>
                <img className='filtrosAvancados' src={maisFiltrosImg} alt='' />
                <p>Filtros Avançados</p>
                </span>
                <section className='opcoesFiltros'>
                <section>
                    <span>
                    <label htmlFor="">Tags</label>
                    <select name="" id="" onChange={(e) => setTag(e.target.value)} defaultValue={tag}>
                        <option value="">Todas</option>
                        {gruposOuTags.map((grupoTag: any, index: number) => (
                        <option value={grupoTag} key={index}>{grupoTag.charAt(0).toUpperCase() + grupoTag.substring(1)}</option>
                        ))}
                    </select>
                    </span>
                </section>
                </section>
                <span id="botoesContainerFiltrosTarefas">
                <Button type='reset' cor='cancelar' isLoading={processando} onClick={() => setMaisFiltros(false)}>Cancelar</Button>
                <Button type='submit' cor='salvar' isLoading={processando}>
                    {!processando && 'Buscar Dados'}
                    {processando && <Loader isLoading={processando} />}
                </Button>
                </span>
            </form>
            </section>}

            <section id='fundoContainerPagina' onScroll={scrollInfinito}>
            <table id='tabelaListaContainer'>
                <thead>
                <tr>
                    <th id='ladoEsquerdoContent' align='left'>Nome Fantasia</th>
                    <th id='cadastroRazao' align='left'>Razão Social</th>
                    <th id='cidadeListaCadastro' align='left'>Cidade</th>
                    <th id='estadoCadastro' align='left'>Estado</th>
                    <th align='center'>Contatos Vinculados</th>
                    <th align='center'>Tags</th>
                    <th align='center'>Situação</th>
                </tr>
                </thead>
                <tbody>
                {cadastros.map((cadastro) => (
                    <tr key={cadastro.id} onClick={() => {
                    modalExibir("ModalContatos")
                    setCadastroSelecionado(cadastro)
                    }}>
                    <td className='primeiraColuna avatarCadastroContent'>
                        <div>
                        <img className='avatar' src={cadastro.avatar ? cadastro.avatar : avatarDefault} alt='' title='Alterar Avatar' />
                        <span>{cadastro.nomeFantasia}</span>
                        </div>
                    </td>
                    <td className='hide-responsive'>
                        <span>{cadastro.razaoSocial}</span>
                        <br />
                        <span><b>CNPJ/CPF: </b>
                        {cadastro.cnpjCpf}</span>
                    </td>
                    <td className='hide-responsive'>{cadastro.cidade}</td>
                    <td className='hide-responsive' align='left'>{cadastro.estado === null ? '' : cadastro.estado.charAt(0).toUpperCase()}{cadastro.estado === null ? '' : cadastro.estado.substring(1)}</td>
                    <td align='center'>{cadastro.contatos.length === 0 ? 'Sem Contatos' : `${cadastro.contatos.length.toString().padStart(2, '0')} contato(s)`}</td>
                    <td>
                        { cadastro.contatos.flatMap((contato) => contato.tags).filter((tag, index, self) =>
                            index === self.findIndex((t) => t.nome === tag.nome)
                            )
                            .map((tag) => (
                                <p key={tag.nome} style={{ background: tag.cor, padding: "5px 10px", margin: "5px", borderRadius: "5px", color: "#FFFFFF" }}>{tag.nome}</p>
                            ))
                        }
                    </td>
                    <td id='ladoDireitoContent' align='center'>
                        <DivSituacao texto={`${cadastro.situacao.charAt(0).toUpperCase()}${cadastro.situacao.substring(1)}`} cor={cadastro.situacao} />
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {maisCadastros && <div id='buscarMaisDados'>
                <LoadingCirculo />
            </div>}
            </section>
        </div>
        </Perfil>
    </ModulosContratados>
  )
}

export default Contatos