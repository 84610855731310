import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import ContratosModelos from "../../components/ContratosModelos"

function ModelosContratos () {

  useEffect(() => {
    document.title = 'Modelos Contratos | Gesttor'
  }, [])

  return (
    <DadosContainerDefault>
      <ContratosModelos />
    </DadosContainerDefault>
  )
}

export default ModelosContratos