import BASEAPI from '../api/config'
import { payloadCadastrarModulo, payloadAtualizarModulo } from '../../interfaces/modulos'

export function cadastrarModulo(payload: payloadCadastrarModulo) {
  return BASEAPI.post('/modulos', payload)
}

export function atualizarModulo(idModulo: string, payload: payloadAtualizarModulo) {
  return BASEAPI.patch(`/modulos/${idModulo}`, payload)
}

export function buscarModulos(idOrganizacaoEmpresa: string) {
  return BASEAPI.get(`/modulos/${idOrganizacaoEmpresa}`)
}

export function buscarModulosAtivos(idOrganizacaoEmpresa: string) {
  return BASEAPI.get(`/modulosAtivos/${idOrganizacaoEmpresa}`)
}

export function buscarSaldos(idOrganizacaoEmpresa: string) {
  return BASEAPI.get(`/saldoDoc/${idOrganizacaoEmpresa}`)
} 