import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarModelosProcessosTamplet } from '../../services/modelosProcessosTamplets'
import { modelosProcessosTampletsResposta } from '../../interfaces/modelosProcessosTamplets'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { toast } from 'react-toastify'
import ModalProcessosTamplat from '../ModalProcessosTamplet'
import Organizacao from '../../middleware/Organizacao'
import ModalTarefaModelo from '../ModalTarefaModelo'
import Perfil from '../../middleware/Perfil'
import FiltrosDefault from '../FiltrosDefault'
import DivSituacao from '../DivSituacao'
import LoagindBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './modelosprocessostamplet.css'

function ModelosProcessosTamplets () {

    const { modal, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location

    const [templats, setTemplats] = useState<modelosProcessosTampletsResposta[]>([])
    const [modeloSelecionado, setModeloSelecionado] = useState<modelosProcessosTampletsResposta>()
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('Ativo')
    const [processando, setProcessando] = useState(true)
    const [nenhumResultado, setNenhumResultado] = useState(false)



    const exibirModalDadosModelosProcessosTemplat = (tamplet: modelosProcessosTampletsResposta, idTamplat: string) => {

        navigate(`${pathname}?idTemplat=${idTamplat}`)
        modalExibir('modelosTamplets')

        return setModeloSelecionado(tamplet)
    }

    const exibirModalTarefasTemplats = (e: any, idTemplat: string) => {

        e.stopPropagation()
        navigate(`${pathname}?idTemplat=${idTemplat}`)
        return modalExibir('tarefasTemplats')
    }

    const buscarModelos = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        const params = {
            termo,
            situacao
        }

        try {

            const resposta = await listarModelosProcessosTamplet(params)

            if (resposta.data.length === 0) {
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setTemplats(resposta.data)

                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)
        }
    }

    useEffect (() => {

        if(!modal.nome) setModeloSelecionado(undefined)

    }, [modal])



    useEffect(() => {
        
        const buscarModelos = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                termo,
                situacao
            }

            try {

                const resposta = await listarModelosProcessosTamplet(params)

                if (resposta.data.length === 0) {
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {

                    setTemplats(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                setProcessando(false)
                
                return toast.error('Falha ao Listar os Tamplets')
            }
        }

        buscarModelos ()

    }, [atualizarInformacoes])


    return (
        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
            <Perfil perfil={['master']}>
                <div id='paginaContainer'>
                    {modal.nome === 'modelosTamplets' && <ModalProcessosTamplat tamplet={modeloSelecionado}/>}
                    {modal.nome === 'tarefasTemplats' && <ModalTarefaModelo/>}
                    {processando && <LoagindBarra />}
                    {nenhumResultado && <NenhumResultadoEncontrado />}
                    <FiltrosDefault termo={termo} situacao={situacao} placeholder='Pesquise pelo título ou descrição do tamplet' tipo={'Templat'} onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarModelos} acaoBtn={() => modalExibir('modelosTamplets')} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'Ativo'}, {nome: 'Inativos', valor: 'Inativo'}]} genero='masculino' maisFiltros={false} isLoading={processando}/>
                    <div className='tamplatsProcessosContainer' id='fundoContainerPagina'>
                        {templats.map((templat) => (
                            <section key={templat.id}  onClick={() => exibirModalDadosModelosProcessosTemplat(templat, templat.id)}>
                                    <span>
                                        <DivSituacao texto={templat.situacao} cor={templat.situacao.toLowerCase()}/>
                                    </span>
                                    <span>
                                        <img src={templat.icone} alt=''/>
                                    </span>
                                    <span>
                                        <h1>{templat.titulo}</h1>
                                        <p>{templat.descricao}</p>
                                    </span>
                                    <p className='adiconarTarefasTemplats' onClick={(e) => exibirModalTarefasTemplats(e, templat.id)}>Adiconar Tarefas
                                    </p>
                            </section>
                        ))}
                    </div>
                </div>
            </Perfil>
        </Organizacao>
    )
}

export default ModelosProcessosTamplets