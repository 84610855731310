import { useEffect } from "react";
import { ProcessosProvider } from "../../contexts/Processos";
import { DadosContainerDefault } from "../../components/Styles";
import Projetos from "../../components/Projetos";

function ProjetosPage() {
  useEffect(() => {
    document.title = 'Projetos | Gesttor'
  })
  return (
    <ProcessosProvider>
      <DadosContainerDefault>
        <Projetos />
      </DadosContainerDefault>
    </ProcessosProvider>
  );
}

export default ProjetosPage;