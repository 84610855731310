import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import Organizacao from '../../middleware/Organizacao'
import Perfil from '../../middleware/Perfil'
import SubMenuOpcoes from '../SubMenuOpcoes'
import ModulosContratados from '../../middleware/SistemasContratados'

function MenuOpcoesRecrutamento(props: menuOpcoes) {

  const [subMenu, setSubMenu] = useState(false)

  function exibirSubMenu() {
    setSubMenu(subMenu => !subMenu)
  }

  return (
    <ModulosContratados modulo={["Recrutamento"]}>
        <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
            <div>
            <div id="menuOpcoes" onClick={exibirSubMenu}>
                {<img src={props.icone} alt={props.alt} id="iconeMenu" />}
                <p id='tituloMenu'>{props.titulo}</p>
                <h3>Novo</h3>
            </div>
            <div id="submenuOpcoes">
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect={'/processos-seletivos'} texto='Todos os Recrutamentos' />}
                    </>
                </Perfil>
                {subMenu && <SubMenuOpcoes redirect={'/processos-seletivos?meus-processos=true'} texto='Meus Recrutamentos' />}
                <Perfil perfil={['master', 'atendimento', 'administrativo']}>
                <>
                    {subMenu && <SubMenuOpcoes redirect={'/processo-seletivo-nps'} texto='Nps' />}
                </>
                </Perfil>
                <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                    <>
                        <Perfil perfil={['master']}>
                            <>
                                {subMenu && <SubMenuOpcoes redirect={'/questionario-disc'} texto='Questionário Disc' />}
                            </>
                        </Perfil>
                        {subMenu && <SubMenuOpcoes redirect={'/banco-talentos'} texto='Banco de Talentos' />}
                    </>
                </Organizacao>
            </div>
            </div>
        </Perfil>
    </ModulosContratados>
  )
}

export default MenuOpcoesRecrutamento
