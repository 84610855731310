const converterNumero = (valor: any) => {

    if(valor === null) {
        return 0
    }

    valor = valor.toString().replace(",", ".")
    
    valor = valor.replace(/[^\d.]/g, '')

    return Number(valor.replace(/[^0-9,]/g, '').replace(',', '.')) || 0;

}

export default converterNumero