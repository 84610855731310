import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { clientesAtivosMes } from '../../services/dashboard'
import Perfil from '../../middleware/Perfil'
import LoadingCirculo from '../LoadingCirculo'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import './novosclientesmes.css'

function GraficoNovosClientesMes () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState ([])
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const graficoClienteAtivoMes = async () => {

            try {
                const resposta = await clientesAtivosMes ()
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        graficoClienteAtivoMes ()

    }, [navigate])


    return (

        <Perfil perfil={['master']}>
            <div id = 'graficoNovosClientesMes'>
                <p id='graficoNovosClientesMesP'>NOVOS CLIENTES POR MÊS</p>
                {carregando && <LoadingCirculo/>}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="clientes_ativos_mes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#25AE88" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#25AE88" stopOpacity={0.4}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="mes" />
                    <YAxis/>
                    <CartesianGrid strokeDasharray="6 6" />
                    <Tooltip />
                    <Area type="monotone" dataKey="quantidade" strokeWidth={2} stroke="#25AE88"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#clientes_ativos_mes)" />
                    </AreaChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Sem Dados para' subtexto='exibir o gráfico'/>}
            </div>
        </Perfil>
        
    )
}

export default GraficoNovosClientesMes