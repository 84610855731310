import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { novoModeloProcessoTamplat, atualizarModeloProcessoTamplet } from '../../services/modelosProcessosTamplets'
import { listarModelosProcessosTampletTarefas } from '../../services/modelosProcessosTamplets'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { tampletDados } from '../../interfaces/modelosProcessosTamplets'
import { Button, Loader } from '../Styles'
import { toast } from 'react-toastify'
import { listaTarefasModelosPayload } from '../../interfaces/modelosTarefas'
import { deletarTarefaModelo } from '../../services/modelosTarefas'
import ModalTarefaModelo from '../ModalTarefaModelo'
import processoImg from '../../assets/img/processos.png'
import apagarImg from '../../assets/img/excluir.png'
import '../../styles/modal.css'


function ModalProcessosTamplat ({ tamplet }: tampletDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { atualizarDadosProcesso, atualizarInformacoesProcesso, exibirModalProcesso, modalTarefasProcesso, exibirModalTarefasProcesso, modalProcessoEstado} = useContext(ModelosProcessosContext)

    const { modalExibir, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [titulo, setTitulo] = useState(tamplet?.titulo || '')
    const [descricao, setDescricao] = useState(tamplet?.descricao || '' )
    const [situacao, setSituacao] = useState(tamplet?.situacao || '')
    const [iconePreview, setIconePreview] = useState<string | ArrayBuffer | null>(null)
    const [icone, setIcone] = useState<File | null>(null)
    const [tarefasModelos, setTarefasModelos] = useState<listaTarefasModelosPayload []>([])
    const [tarefaModeloSelecionada, setTarefaModeloSelecionada] = useState<listaTarefasModelosPayload>()
    const [modalTarefaModelo, setModalTarefaModelo] = useState(false)
    const [processando, setProcessando] = useState(false)


    function ocultarModalProcessosTamplets () {

        navigate(`${pathname}`)

        return modalExibir('')
    }
    
    const exibirModalDadosTarefaModelo = (tarefaModelo: listaTarefasModelosPayload, idTarefaModelo: string) => {

        setModalTarefaModelo(!modalTarefaModelo)
        navigate(`${pathname}${search}#${idTarefaModelo}`)

        return setTarefaModeloSelecionada(tarefaModelo)
    }

    const atualizarIconeLocal = (e:any) => {

        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.png', '.jpg', '.jpeg']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {

                const rendenrizar = new FileReader()
        
                rendenrizar.onloadend = () => {
                    setIconePreview(rendenrizar.result)
                }
        
                rendenrizar.readAsDataURL(arquivo)

                return setIcone(arquivo)
            } else {

                setIcone(null)

                return alert('Tipo de Imagem não Permitida')
            }
        }
    }

    const cadastrarProcessoTamplet = async (e:FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        if(!icone) {
            setProcessando(false)

            return toast.warn('Você precisa inserir um icone')
        }

        try {


            const payload = {
                titulo,
                descricao,
                icone
            }

            const resposta = await novoModeloProcessoTamplat(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDadosProcesso()

                return toast.success('Dados Cadastrados com Sucesso')
            }
            
        } catch (error) {

            setProcessando(false)

            return toast.error('Falha ao Processar a sua Solicitação')
            
        }
    }

    const updateProcessoTamplet = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idTamplet = paramsUrl.get('idTemplat')

        if (idTamplet === null) {
            return setProcessando(false)
        }

        const payload = {
            titulo,
            descricao,
            situacao
        }

        try {

            const resposta = await atualizarModeloProcessoTamplet(idTamplet, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDadosProcesso()
                atualizarDados()
                modalExibir('')

                return toast.success('Dados Atualizados com Sucesso')
            }
            
        } catch (error) {

            setProcessando(false)

            return toast.error('Falta ao Salvar os Dados')
        }
    }


    const deletarModeloTarefa = async (idTarefaModelo: string) => {

        setProcessando(true)

        try {

            const resposta = await deletarTarefaModelo(idTarefaModelo)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDadosProcesso()
                atualizarDados()
                
                return toast.success('Tarefa Deletada com Sucesso')
            }
            
        } catch (error) {

            setProcessando(false)

            return toast.error('Falha ao Processar sua Solicitação')
            
        }
    }

    useEffect(() => {

        const buscarTarefas = async () => {

            try {

                const idTamplet = tamplet?.id

                if(!idTamplet) {
                    return
                }

                const resposta = await listarModelosProcessosTampletTarefas(idTamplet)

                if(resposta.status === 200) {
                    return setTarefasModelos(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarTarefas()

    }, [atualizarInformacoesProcesso, atualizarInformacoes, tamplet])

    return (

        <div id = 'fundoModal'>
            {modalTarefaModelo && <ModalTarefaModelo tarefaModelo={tarefaModeloSelecionada} />}
            <section id={tamplet ? 'containerModalNovo': ''} className={!tamplet ? 'containerModalCentro' : ''}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>{!tamplet ? 'Novo Tamplet': 'Vizualizar Tamplet'}</p>
                </span>
                <form id='formModalNovo' onSubmit={tamplet ? updateProcessoTamplet : cadastrarProcessoTamplet}>
                    {iconePreview && <section>
                        <span>
                            <img
                                src={iconePreview as string}
                                alt=''
                                style={{ 
                                    width: '300px', 
                                    height: '300px', 
                                    objectFit: 'cover', 
                                    borderRadius: '50%', 
                                    padding: '10px'
                                }}
                            />
                        </span>
                    </section>}
                    <section>
                        <span>
                            <label htmlFor="">Título</label>
                            <input 
                                type="text"
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                                required 
                            />
                        </span>
                        {tamplet && <span>
                            <label htmlFor="situacaoTamplet">Situação</label>
                            <select id="situacaoTamplet" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value="Ativo">Ativo</option>
                                <option value="Inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>
                    <section>
                        <span>
                            <label htmlFor="">Descrição</label>
                            <textarea name="" id="" value={descricao} onChange={(e) => setDescricao(e.target.value)} required rows={5} maxLength={1000} />
                        </span>
                    </section>
                    {!tamplet && <section>
                        <span>
                            <label htmlFor="">Selecione um Ícone</label>
                            <input type='file'accept='image/*' onChange={atualizarIconeLocal} required/>
                        </span>
                    </section>}

                    {tamplet && <div id='tabelaModelosProcessosTarefas'>
                        <p>Tarefas do Tamplat</p>
                        <div>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th id='ladoEsquerdoContent' align='left'>Título</th>
                                        <th id='tipoTarefaModeloProcesso' align='left'>Tipo</th>
                                        <th align='right'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tarefasModelos?.map((tarefa) => (
                                        <tr key={tarefa.id}>
                                            <td id='primeiraColuna' onClick={() => exibirModalDadosTarefaModelo(tarefa, tarefa.id)}>{tarefa.titulo}</td>
                                            <td id='tipoTarefaModeloProcesso' align='left' onClick={() => exibirModalDadosTarefaModelo(tarefa, tarefa.id)}>{tarefa.tipo.charAt(0).toUpperCase()}{tarefa.tipo.substring(1)}</td>
                                            <td id='ladoDireitoContent' align='right' onClick={() => deletarModeloTarefa(tarefa.id)}>
                                                <img id='apagarIconeTarefa' title='Excluir Tarefa do Modelo' src={apagarImg} alt=''/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModalProcessosTamplets}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalProcessosTamplat
