import BASEAPI from '../api/config'
import { cadastrarSubTarefaModeloPayload, atualizarSubTarefaModeloPayload} from '../../interfaces/subTarefasModelos'


export function novaSubTarefaModelo (idTarefa: number, payload: cadastrarSubTarefaModeloPayload) {
    return BASEAPI.post(`/subtarefas/modelo/${idTarefa}`, payload)
} 

export function listarSubtarefasModelos (idTarefa: string) {
    return BASEAPI.get (`/subtarefas/modelo/${idTarefa}`)
}

export function atualizarSubTarefaModelo (idSubTarefa: number, payload: atualizarSubTarefaModeloPayload) {
    return BASEAPI.patch(`/subtarefas/modelo/${idSubTarefa}`, payload)
}

export function deletarSubTarefaModelo (idSubTarefa: number) {
    return BASEAPI.delete(`/subtarefas/modelo/${idSubTarefa}`)
}

