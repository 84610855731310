import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import Contratos from "../../components/Contratos"

function ContratosPage () {

  useEffect(() => {
    document.title = 'Contratos | Gesttor'
  }, [])

  return (
    <DadosContainerDefault>
      <Contratos />
    </DadosContainerDefault>
  )
}

export default ContratosPage