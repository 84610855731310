import { useContext, useEffect, useState } from "react";
import { Button } from "../Styles";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { MdEdit } from "react-icons/md";
import "./index.css"
import { cadastrarContato, listarContatosPorIdCadastro, removerContato } from "../../services/Atendimento";
import { criptografarDado, telefoneMascara } from "../../helpers";
import { toast } from "react-toastify";
import ModalEditarContato from "../ModalEditarContato";
import { listaContato, tags } from "../../interfaces/atendimento";
import { FaTrash } from "react-icons/fa";
import ModalAvisoCancelar from "../ModalAvisoCancelar";
import { ModalCadastrosDados } from "../../interfaces/cadastros";

function ModalContatos({ cadastro }: ModalCadastrosDados) {
  const { modalExibir, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

  const [processando, setProcessando] = useState(false)

  const [contatos, setContatos] = useState<listaContato[]>([])
  const [contatoSelecionado, setContatoSelecionado] = useState<listaContato>()
  const [modalEditarContato, setModalEditarContato] = useState(false)

  const [nome, setNome] = useState("")
  const [telefone, setTelefone] = useState("")
  const [email, setEmail] = useState("")
  const [tipo, setTipo] = useState("")

  const [isOpen, setIsOpen] = useState(false);

  const [modalConfirmacaoRemoverContato, setModalConfirmacaoRemoverContato] = useState(false)
  const [idContatoSelecionado, setIdContatoSelecionado] = useState<string>()

  const cadastrarContatos = async () => {
    setProcessando(true)
    if (!cadastro) return toast.error("Não foi possivel encontrar o cadastro.")
    try {
      const payload = {
        idCadastro: criptografarDado(cadastro.id.toString()),
        nome,
        telefone,
        email,
        tipo
      }

      const resposta = await cadastrarContato(payload)

      if (resposta.status === 201) {
        toast.success("Contato Cadastrado com Sucesso!")
        setProcessando(false)
        atualizarDados()
        setIsOpen(false)
        setTelefone("")
        setTipo("")
        setNome("")

        return

      }
    } catch (error: any) {
      if (error.response.status === 400) {
        toast.info("Verifique se os Campos Obrigatórios estão Preenchidos!")
        return setProcessando(false)
      }
      setProcessando(false)
      toast.error("Erro ao Cadastrar Contato")
    }
  }

  const handleRemoveContato = async () => {
    setProcessando(true)
    try {
      if (!idContatoSelecionado) return toast.info("Contato não encontrado")
      const idContatoCript = criptografarDado(idContatoSelecionado)
      const resposta = await removerContato(idContatoCript)
      if (resposta.status === 200) {
        atualizarDados()
        setModalConfirmacaoRemoverContato(false)
        setProcessando(false)
        toast.success("Contato Removido com Sucesso.")
      }

    } catch (error) {
      setModalConfirmacaoRemoverContato(false)
      setProcessando(false)
      toast.info("Não é possível remover este contato, pois ele já recebeu um comunicado.");
    }
  }

  useEffect(() => {
    const listarTodosContatos = async () => {
      setProcessando(true)
      try {
        if (!cadastro) return toast.error("Não foi possivel encontrar o cadastro.")
        const idCadastroCript = criptografarDado(cadastro.id.toString())
        const resposta = await listarContatosPorIdCadastro(idCadastroCript)
        setContatos(resposta.data)
        setProcessando(false)

      } catch (error) {
        setProcessando(false)
        toast.error("Erro ao listar Contatos de Cadastros")
      }
    }
    listarTodosContatos()

  }, [atualizarInformacoes, cadastro, cadastro?.id])

  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        <span className='novoContainerTitulo'>
          <h1>
            {cadastro?.nomeFantasia}
          </h1>
        </span>
        <section className="lista-contatos">
          {contatos.map((contato) => (
            <span key={contato.id} className="cartao-contato">
              <span className="cartao-conteudo">
                <span>
                  <p>{contato.nome}</p>
                  <p className="email-contato">{contato.email}</p>
                  <p className="telefone-contato">{contato.telefone}</p>
                </span>
                <span id="botoes-acoes">
                  <span>
                    <MdEdit fontSize={"20px"} onClick={() => { setContatoSelecionado(contato); setModalEditarContato(true) }} title="Clique para Editar a Tag" />
                  </span>
                  <span>
                    <FaTrash fontSize={"20px"} color="#FF6B6C" onClick={() => { contato.id && setIdContatoSelecionado(contato.id.toString()); setModalConfirmacaoRemoverContato(true) }} title="Clique para Desvincular o Contato" />
                  </span>
                </span>
              </span>
              <span className="tagContatoContainer tagContatoContainerModalEditar">
                {contato.tags && contato.tags.map((tag: tags) => (<p key={tag.id} style={{ backgroundColor: tag.cor }}>{tag.nome}</p>))}
              </span>
            </span>
          ))}
        </section>
        <section>
          <details className="details-tags" open={isOpen} onToggle={(e) => setIsOpen(e.currentTarget.open)}>
            <summary>
              Adicionar Contatos
            </summary>
            <section>
              <span className="container-input-tag">
                <input type="text" placeholder="Nome do Contato" value={nome} onChange={(e: any) => setNome(e.target.value)} maxLength={100} required/>
              </span>
            </section>
            <section>
              <span className="container-input-tag">
                <input type="email" placeholder="Email do Contato" value={email} onChange={(e: any) => setEmail(e.target.value)} maxLength={100} />
              </span>

            </section>
            <section>
              <span className="container-input-tag">
                <input type="text" placeholder="Telefone do Contato" onChange={(e: any) => setTelefone(telefoneMascara(e.target.value))} value={telefone} minLength={18} maxLength={18} required />

                <select value={tipo} onChange={(e) => setTipo(e.target.value)} defaultValue="" required>
                  <option value="" disabled>Selecione o Tipo</option>
                  <option value="Sócio(a)">Sócio(a)</option>
                  <option value="Empresário(a)">Empresário(a)</option>
                  <option value="Colaborador">Colaborador</option>
                  <option value="Terceirizado">Terceirizado</option>
                  <option value="Outro">Outro</option>
                </select>
              </span>
            </section>
            <span className="botoesContainer">
              <Button type='button' cor='salvar' isLoading={processando} onClick={cadastrarContatos}>Adicionar Contato</Button>
            </span>

          </details>
        </section>

        <span className="botoesContainer">
          <Button type='button' cor='cancelar' isLoading={processando} onClick={() => { modalExibir("") }}>Fechar</Button>
        </span>
      </section>
      {modalEditarContato && contatoSelecionado && <ModalEditarContato contatoSelecionado={contatoSelecionado} onClose={setContatoSelecionado} processando={processando} />}
      {modalConfirmacaoRemoverContato && <ModalAvisoCancelar titulo={"Tem certeza que deseja remover o Contato do Cadastro?"} processando={processando} funcaoSubmit={handleRemoveContato} onClose={setModalConfirmacaoRemoverContato} />}
    </div>
  );
}

export default ModalContatos;