import { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { propsLayout } from "../../interfaces/propsLayout"
import { AuthContext } from '../../contexts/Auth'
import MenuLateral from "../MenuLateral"

const Layout = ({ children }: propsLayout ) => {

    const { auth } = useContext(AuthContext)
    const location = useLocation()

    const [usuarioLogado, setUsuarioLogado] = useState(false)
  
    useEffect(() => {

        const isAuth = () => {
            setUsuarioLogado(!!localStorage.getItem('authGesttor'))
        }


        isAuth()
        
    }, [auth])
   
    return (
      <>
        {usuarioLogado && location.pathname !== '/' && !location.pathname.startsWith('/nps') && <MenuLateral />}
        { children }
      </>
    )
}

export default Layout