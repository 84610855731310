import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { lisatarLancamentos, apagarLancamento } from '../../services/lancamentos'
import { LancamentosRespostaPayload } from '../../interfaces/lancamentos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Button, Loader } from '../Styles'
import { toast } from 'react-toastify'
import ModalLancamento from '../ModalLancamentos'
import Perfil from '../../middleware/Perfil'
import FiltrosDefault from '../FiltrosDefault'
import apagarImg from '../../assets/img/excluir.png'
import DivSituacao from '../DivSituacao'
import maisFiltrosImg from '../../assets/img/maisFiltros.png'
import LoagindBarra from '../LoadingBarra'
import LoadingCirculo from '../LoadingCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import './lancamentos.css'
import '../../styles/paginaListaDados.css'

function Lancamentos () {

    const { modalEstado, exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location

    const paramsUrl = new URLSearchParams(search)

    const tipoUrl = paramsUrl.get('tipo')
    const recorrenciaLancamento = paramsUrl.get('recorrencia')
    const inadimplencia = paramsUrl.get('inadimplencia')

    const data = new Date()

    const ano = data.getFullYear()
    const qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()

    const dataInicial = `${ano}-${(data.getMonth() + 1).toString().padStart(2, '0')}-01`
    const dataFinal = `${ano}-${(data.getMonth() + 1).toString().padStart(2,'0')}-${qtnDias}`

    const [lancamentos, setLancamentos] = useState<LancamentosRespostaPayload[]>([])
    const [lancamentoSelecionado, setLancamentoSelecionado] = useState<LancamentosRespostaPayload>()
    const [modalLancamento, setModalLancamento] = useState(false)
    const [maisFiltros, setMaisFiltros] = useState(false)
    const [maisTarefas, setMaisTarefas] = useState(false)
    const [pagina, setPagina] = useState(2)
    const [cliente, setCliente] = useState('')
    const [descricao, setDescricao] = useState('')
    const [vencimentoInicial, setVencimentoInicial] = useState(dataInicial)
    const [vencimentoFinal, setVencimentoFinal] = useState(dataFinal)
    const [situacao, setSituacao] = useState(`${tipoUrl === 'receita' ? 'Aguardando Recebimento':'Aguardando Pagamento'}`)
    const [tipo, setTipo] = useState<string | null>(null)
    const [lancamentosRecorrentes, setLancamentosRecorrentes] = useState<string | null >(null)
    const [tipos, setTipos] = useState<{nome: string, valor: string}[]>([])
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)

    const exibirModalLancamento = () => exibirModal()

    const modalMaisFiltros = () => setMaisFiltros(!maisFiltros)

    const scrollInfinito = (e: any) => {

        if(tipo === null) {
            return
        }

        const { scrollTop, clientHeight, scrollHeight } = e.target;

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)

        if (posicaoScroll === 100) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisTarefas = async () => {

                setMaisTarefas(true)

                const params = {
                    pagina,
                    tipo,
                    cliente,
                    descricao,
                    situacao,
                    vencimentoInicial,
                    vencimentoFinal
                }

                if(lancamentosRecorrentes) {
                    Object.assign(params, {
                        recorrencia: lancamentosRecorrentes
                    })
                }
    
                if(inadimplencia) {
                    Object.assign(params, {
                        inadimplencia
                    })
                }

                try {

                    const resposta = await lisatarLancamentos(params)

                    if (resposta.status === 200) {
                        setLancamentos(prevDate => [...prevDate, ...resposta.data])
                        return setMaisTarefas(false)
                    }
            
                } catch (error) {
                    setMaisTarefas(false)

                    toast.error('Falta ao Buscar mais Lançamentos')

                }
            }

            buscarMaisTarefas()
        }
    }

    const exibirModalDadosLancamento = (lancamento: LancamentosRespostaPayload , id: string) => {

        navigate(`${pathname}${search}&id=${id}`)
        exibirModal()

        return setLancamentoSelecionado(lancamento)
    }

    const buscarLancamentos = async (e: FormEvent) => {

        e.preventDefault()
        setMaisFiltros(false)
        setNenhumResultado(false)
        setProcessando(true)

        if(tipo === null) {
            return
        }

        const params = {
            pagina: 1,
            tipo,
            cliente,
            descricao,
            situacao,
            vencimentoInicial,
            vencimentoFinal
        }

        if(lancamentosRecorrentes) {
            Object.assign(params, {
                recorrencia: lancamentosRecorrentes
            })
        }

        if(inadimplencia) {
            Object.assign(params, {
                inadimplencia
            })
        }

        try {

            const resposta = await lisatarLancamentos(params)

            if (resposta.data.length === 0) {
                setLancamentos(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setLancamentos(resposta.data)
                setNenhumResultado(false)
                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)

            toast.error('Falha ao Listar os Lançamentos')
        }
    }

    const deletarLancamento = async (id: string) => {

        setProcessando(true)

        try {

            const resposta = await apagarLancamento(id)

            if(resposta.status === 200) {
                setProcessando(false)

                toast.success('Lançamento Excluído com Sucesso')

                return atualizarDados()

            }
            
        } catch (error) {

            toast.error('Fala ao Excluir o Lançamento')
            
        }

    }

    useEffect (() => {
        
        setModalLancamento(modalEstado)

        if(!modalEstado) setLancamentoSelecionado(undefined)

    }, [modalEstado])

    useEffect (() => {

        if(tipoUrl === undefined || !tipoUrl || recorrenciaLancamento === undefined) {
            return
        }

        setTipo(tipoUrl)
        setLancamentosRecorrentes(recorrenciaLancamento)

        return


    }, [tipoUrl, recorrenciaLancamento])

    useEffect(() => {

        const atualizarTipos = () => {

            if(!tipo) {
                return {nome: '', valor: ''}
            }
    
            const tipos: {[key: string]: {nome: string, valor: string}[]} = {
                receita: [
                    { nome: 'Todas', valor: '' },
                    { nome: 'Recebidas', valor: 'Recebido' },
                    { nome: 'Atrasadas', valor: 'atrasado' },
                    { nome: 'Recebimento Parcial', valor: 'Recebimento Parcial' },
                    { nome: 'Aguardando Recebimento', valor: 'Aguardando Recebimento' }
                ],
                despesa: [
                    { nome: 'Todas', valor: '' },
                    { nome: 'Pagas', valor: 'Pago'},
                    { nome: 'Atrasadas', valor: 'atrasado' },
                    { nome: 'Pagamento Parcial', valor: 'Pagamento Parcial' },
                    { nome: 'Aguardando Pagamento', valor: 'Aguardando Pagamento' },
                ]
            }
    
            return setTipos(tipos[tipo])
        }

        atualizarTipos()

    }, [tipo])

    useEffect(() => {
        
        const buscarLancamentos = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            if(tipo === null) {
                return
            }

            const params = {
                pagina: 1,
                tipo,
                cliente: '',
                descricao: '',
                situacao: `${tipoUrl === 'receita' ? 'Aguardando Recebimento':'Aguardando Pagamento'}`,
                vencimentoInicial,
                vencimentoFinal
            }

            if(lancamentosRecorrentes) {
                Object.assign(params, {
                    recorrencia: lancamentosRecorrentes
                })
            }

            if(inadimplencia) {
                Object.assign(params, {
                    inadimplencia
                })
            }

            try {

                const resposta = await lisatarLancamentos(params)

                if (resposta.data.length === 0) {
                    setLancamentos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setLancamentos(resposta.data)
                    setNenhumResultado(false)

                    return setProcessando(false)
                }
                
            } catch (error) {
                
            }
        }

        buscarLancamentos ()

    }, [vencimentoInicial, vencimentoFinal, tipo, atualizarInformacoes, lancamentosRecorrentes, inadimplencia, tipoUrl])


    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div id='paginaContainer'>
                {modalLancamento && <ModalLancamento lancamento={lancamentoSelecionado}/>}
                {processando && <LoagindBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                <FiltrosDefault termo={cliente} situacao={situacao} placeholder={tipo === 'receita' ? 'Pesquise pelo nome fantasia do cliente': 'Pesquise pelo nome do Fornecedor/Colaborador'} tipo={tipo === null ? '' : tipo} onChangeTermo={(e) => setCliente(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarLancamentos} acaoBtn={exibirModalLancamento} listaSituacao={tipos} genero='feminino' maisFiltros={true} acaoMaisFiltro={modalMaisFiltros} isLoading={processando}/>

                {maisFiltros && <section id='maisOpcoesFiltrosTarefas'>
                    <form id='maisOpcoesFiltrosLancamentosContainer' onSubmit={buscarLancamentos}>
                        <span id='filtrosContainerTitulo'>
                            <img id='filtrosAvancadosTarefas' src={maisFiltrosImg}  alt=''/>
                            <p>Filtros Avançados</p>
                        </span>
                        <section id='opcoesFiltrosTarefas'>
                            {!inadimplencia && !recorrenciaLancamento && <section>
                                <span>
                                    <label htmlFor="dataInicialFiltrotarefas">Vencimento Inicial</label>
                                    <input 
                                        id="dataInicialFiltrotarefas"
                                        type="date" 
                                        value={vencimentoInicial}
                                        onChange={(e) => setVencimentoInicial(e.target.value)}/>
                                </span>

                                <span>
                                    <label htmlFor="dataFinalFiltrotarefas">Vencimento Final</label>
                                    <input 
                                        id="dataFinalFiltrotarefas"
                                        type="date" 
                                        value={vencimentoFinal}
                                        onChange={(e) => setVencimentoFinal(e.target.value)}/>
                                </span>

                                <span>
                                    <label htmlFor="situacaoFiltroTarefa">Situação</label>
                                    <select id="situacaoFiltroTarefa" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                        {tipos.map((tipo) => (
                                            <option value={tipo.valor}>{tipo.nome}</option>
                                        ))}
                                    </select>
                                </span>
                            </section>}

                            <section>
                                <span>
                                    <label htmlFor="clienteFiltroTarefas">{tipo === 'receita' ? 'Cliente' : 'Fornecedor/Colaborador'}</label>
                                    <input 
                                        id='clienteFiltroTarefas'
                                        placeholder={tipo === 'receita' ? 'Pesquise pelo nome fantasia do cliente' : 'Pesquise pelo nome do\ fornecedor/colaborador'}
                                        type="text" 
                                        value={cliente}
                                        onChange={(e) => setCliente(e.target.value)}/>
                                </span>
                            </section>
                            
                            <section>
                                <span>
                                    <label htmlFor="descricaoTarefaFiltro">Descrição do Lançamento</label>
                                    <input
                                        id='descricaoTarefaFiltro' 
                                        type="text" 
                                        value={descricao}
                                        placeholder='Pesquise pela descrição do lançamento'
                                        onChange={(e) => setDescricao(e.target.value)}/>
                                </span>
                            </section>
                        </section>

                        <span id="botoesContainerFiltrosTarefas">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={modalMaisFiltros}>Cancelar</Button>
                            <Button type='submit' cor='salvar' isLoading={processando}>
                                {!processando && 'Buscar Dados'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>
                    </form>
                </section>}

                <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>{tipo === 'receita' ? 'Cliente' : 'Fornecedor/Colaborador'}</th>
                                <th align='left'>Descrição</th>
                                <Perfil perfil={['master', 'administrativo']}>
                                    <th align='left'>Valor Total</th>
                                </Perfil>
                                {!recorrenciaLancamento && <th align='center'>Vencimento</th>}
                                {!recorrenciaLancamento && <th align='center'>Situação</th>}
                                {recorrenciaLancamento && <th align='left'>Recorrência</th>}
                                {recorrenciaLancamento && <th align='center'>Recorrência Mês</th>}
                                {recorrenciaLancamento && <th align='center'>Recorrência Dia</th>}
                                {!recorrenciaLancamento && <Perfil perfil={['master']}>
                                    <th>Ação</th>
                                </Perfil>}
                            </tr>
                        </thead>
                        <tbody>
                            {lancamentos.map((lancamento) => (
                                <tr key={lancamento.id}>
                                    <td id='primeiraColuna' onClick={() => exibirModalDadosLancamento(lancamento, lancamento.id)}>{lancamento.cadastro.nomeFantasia}</td>
                                    <td onClick={() => exibirModalDadosLancamento(lancamento, lancamento.id)}>{lancamento.descricao}</td>
                                    <Perfil perfil={['master', 'administrativo']}>
                                        <td align='left' onClick={() => exibirModalDadosLancamento(lancamento, lancamento.id)}>{Number(lancamento.valorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </Perfil>
                                    {!recorrenciaLancamento && <td align='center' onClick={() => exibirModalDadosLancamento(lancamento, lancamento.id)}>{`${lancamento.vencimento.toString().slice(8,10)}-${lancamento.vencimento.toString().slice(5,7)}-${lancamento.vencimento.toString().slice(0,4)}`}</td>}
                                    {!recorrenciaLancamento && <td align='center' onClick={() => exibirModalDadosLancamento(lancamento, lancamento.id)}>
                                        <DivSituacao texto={lancamento.situacao} cor={lancamento.situacao.toLowerCase().replace(/\s+/g, '')}/>
                                    </td>}
                                    {recorrenciaLancamento && <td align='left'>{`${lancamento.recorrencia?.charAt(0).toUpperCase()}${lancamento.recorrencia?.substring(1)}`}</td>}
                                    {recorrenciaLancamento && <td align='center'>{lancamento.recorrenciaMes?.toString().padStart(2, '0')}</td>}
                                    {recorrenciaLancamento && <td id='ladoDireitoContent' align='center'>{lancamento.recorrenciaDia?.toString().padStart(2, '0')}</td>}
                                    {!recorrenciaLancamento && <Perfil perfil={['master']}>
                                        <td id='ladoDireitoContent' align='center'>
                                            {lancamento.situacao !== "Recebimento Parcial" && lancamento.situacao !== "Pagamento Parcial" && lancamento.situacao !== "Recebido" && <img id='apagarIconeTarefa' title='Excluir Lançamento' src={apagarImg} alt='' onClick={() => deletarLancamento(lancamento.id)}/>}
                                        </td>
                                    </Perfil>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {maisTarefas && <div id='buscarMaisDados'>
                        <LoadingCirculo />
                    </div>}
                </section>
            </div>
        </Perfil>
    )
}

export default Lancamentos