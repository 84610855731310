import BASEAPI from '../api/chat'

export function iniciarSessaoWa () {
    return BASEAPI.put('/wa/sessao')
}

export function dadosWa () {
    return BASEAPI.get('/wa/sessao')
}

export function apagarSessaoWa () {
    return BASEAPI.delete('/wa/sessao')
}
