import { useState, useEffect, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarSuporteOrganizacoes } from '../../services/suporte'
import Perfil from '../../middleware/Perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'

function MenuGesttor (props: menuOpcoes) {

    const { atualizarInformacoes } = useContext(ModalEstadoContext)

    const [atendimento, setAtendimento] = useState(0)
    const [bug, setBug] = useState(0)
    const [melhoria, setMelhoria] = useState(0)

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect(() => {
        
        const buscarSuporte = async () => {

            const paramsSuporte = {
                pagina: 1,
                termo: '',
                tipo: 'suporte',
                status: ''
            }

            const paramsBug = {
                pagina: 1,
                termo: '',
                tipo: 'bug',
                status: ''
            }

            const paramsSugestao = {
                pagina: 1,
                termo: '',
                tipo: 'sugestão',
                status: ''
            }

            const promisses = [
                await listarSuporteOrganizacoes(paramsSuporte),
                await listarSuporteOrganizacoes(paramsBug),
                await listarSuporteOrganizacoes(paramsSugestao)
            ]
            
            try {

                const resposta = await Promise.all(promisses)

                const suporte = resposta[0]
                const bug = resposta[1]
                const sugestao = resposta[2]

                if(suporte.status === 200) {
                    setAtendimento(suporte.data.length)
                }

                if(bug.status === 200) {
                    setBug(bug.data.length)
                }

                if(sugestao.status === 200) {
                    setMelhoria(sugestao.data.length)
                }
                
            } catch (error) {
                
            }
        }

        buscarSuporte ()

    }, [atualizarInformacoes])


    return (

        <div>
            <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                <p id = 'tituloMenu'>{props.titulo}</p>
                {(atendimento + bug + melhoria) !== 0 ? <h2>{atendimento + bug + melhoria}</h2> : null}
            </div>
            <div id = 'submenuOpcoes'>
                <Perfil perfil= {['master', 'administrativo', 'atendimento']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/gesttor/cliente' texto = 'Gerenciar Licenças' />}
                    </>
                </Perfil>
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/gesttor/ajuda?tipo=suporte' texto = 'Solicitações de Atendimento' notificacao={atendimento === 0 ? null : atendimento}/>}
                    </>
                </Perfil>
                <Perfil perfil={['master', 'administrativo', 'suporte']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/gesttor/ajuda?tipo=bug' texto = 'Solicitações de Suporte' notificacao={bug === 0 ? null : bug}/>}
                    </>
                </Perfil>
                <Perfil perfil={['master', 'administrativo']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/gesttor/ajuda?tipo=sugestão' texto = 'Sugestão de Melhoria' notificacao={melhoria === 0 ? null : melhoria} />}    
                    </>
                </Perfil>
            </div>        
        </div>
    )
}

export default MenuGesttor