import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { lucroMes } from '../../services/dashboardFinanceiro'
import { graficoLucroMes } from '../../interfaces/graficos'
import Perfil from '../../middleware/Perfil'
import LoadingCirculo from '../LoadingCirculo'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import './lucromes.css'

function GraficoLucroMes () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState<graficoLucroMes []>([])
    const [valorMaximo, setValorMaximo] = useState(0)
    const [valorMinimo, setValorMinimo] = useState(0)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const lucroPorMes = async () => {

            try {
                const resposta = await lucroMes ()
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        lucroPorMes ()

    }, [navigate])

    const gradientOffset = () => {
        const dataMax = Math.max(...dados.map((i) => i.lucroMes));
        const dataMin = Math.min(...dados.map((i) => i.lucroMes));
      
        if (dataMax <= 0) {
          return 0;
        }
        if (dataMin >= 0) {
          return 1;
        }
      
        return dataMax / (dataMax - dataMin);
      };
      
      const off = gradientOffset();

      useEffect (() => {

        let valorMaximo = 0

        dados.forEach (valor => {
            if (Number(valor.lucroMes) > valorMaximo) {
                valorMaximo = Number(valor.lucroMes)
            }
        })

        return setValorMaximo(Number(valorMaximo.toFixed()))

    }, [dados])

    useEffect(() => {

        let valorMinimo = 0

        dados.forEach(valor => {
            if(Number(valor.lucroMes) < valorMinimo) {
                valorMinimo = Number(valor.lucroMes)
            }
        })

        return setValorMinimo(valorMinimo)

    }, [dados])


    return (

        <Perfil perfil={['master']}>
            <div id = 'graficoLucroMes'>
                <p id='graficoLucroMesP'>LUCRO POR MÊS</p>
                {carregando && <LoadingCirculo/>}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 40, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="lucroMes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset={off} stopColor="#25AE88" stopOpacity={1} />
                            <stop offset={off} stopColor="#E21B1B" stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="competência" />
                    <YAxis domain={[(0 - Math.abs(valorMinimo)), valorMaximo]} allowDecimals={false}/>
                    <CartesianGrid strokeDasharray="6 6" />
                    <Tooltip />
                    <Area type="monotone" dataKey="lucroMes" strokeWidth={0} stroke="#25AE88"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#lucroMes)" />
                    </AreaChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Sem Dados para' subtexto='exibir o gráfico'/>}
            </div>
        </Perfil>
        
    )
}

export default GraficoLucroMes