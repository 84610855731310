import { useEffect, useState, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { iniciarSessaoWa } from '../../services/wa'
import io from 'socket.io-client'
import ProcessandoSolicitacao from '../ProcessandoSolicitacao'
import ModalAlerta from '../ModalAlerta'
import erroImg from '../../assets/img/erroFechar.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import './modalqrcode.css'

function ModalQrCodeWhatsApp () {

    const { atualizarDados } = useContext(ModalEstadoContext)

    const { modalExibir } = useContext(ModalEstadoContext)

    const [processando, setProcessando] = useState(true)
    const [qrCode, setQrCode] = useState('')
    const [sucessoQrCode, setSucessoQrCode] = useState(false)
    const [erro, setErro] = useState(false)


    const ocultarModal = () => modalExibir('')
 

    useEffect(() => {
        
        const iniciarSocket = () => {

            const organizacao = localStorage.getItem("organizacaoGesttor")

            const socket = io(`${process.env.REACT_APP_API_CHAT}?idOrganizacao=${organizacao}`)
        
            socket.on('qrCodeWa', (data) => {

                const { idOrganizacao,  qrCode } = data

                if(idOrganizacao === organizacao ) {

                    setQrCode(qrCode)
                    
                    atualizarDados()

                    setProcessando(false)
                }
            })

            socket.on('qrCodeWaSucesso', (data) => {

                const { idOrganizacao, situacao } = data

                if( idOrganizacao === organizacao && situacao === 'Conectado') {
                    atualizarDados()
                    setSucessoQrCode(true)
                }
            })

            return () => {
                socket.disconnect()
            }

        }

        iniciarSocket()

    }, [atualizarDados])

    useEffect (() => {

        const iniciarSessao = async () => {

            try {

                await iniciarSessaoWa()
                
            } catch (error) {
                setProcessando(false)
                return setErro (true)
            }
        }

        iniciarSessao ()

    }, [])

    return (
        
        <div id='modalQrCode'>
            {sucessoQrCode && <ModalAlerta icone={confirmacaoImg} texto='Conexão com WhatsApp Estabelecida' btnTitulo='Continuar' cor='continuarSucesso' acao={ocultarModal}/>}
            {erro && <ModalAlerta icone={erroImg} texto='Ocorreu um erro ao gerar o QrCode. Tente Novamente, se o erro persistir entre em contato com o nosso atendimento.' cor='continuarErro' btnTitulo='Tentar Novamente' acao={ocultarModal} />}
            {!erro && !sucessoQrCode && <section id = 'modalFundoQrCodeWhatsApp'>
            {!processando && <img src={qrCode} alt=''/>}
            {processando && <ProcessandoSolicitacao textoInferior='Gerando QrCode, por favor aguarde!' />}
            </section>}
        </div>
    )
}

export default ModalQrCodeWhatsApp