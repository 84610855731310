import { useContext } from "react";
import { Button } from "../Styles";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import projetoImg from '../../assets/img/projeto.png'
import "./index.css"

function ModalModeloProcessoOpcaoTamplet () {

  const { modalExibir } = useContext(ModalEstadoContext)

  return (
    <div className="fundoModal">
        <section className='containerModalCentro'>
            <span id='novoContainerTitulo'>
                <img id='adicionarModalIcone' src={projetoImg} alt='' />
                <p>Escolha uma Opção</p>
            </span>
            <section>
                <span className="escolhaTempltsContainer">
                    <p onClick={() => modalExibir('modalTamplesDisponiveis')}>Templats de Processos</p>
                    <p onClick={() => modalExibir('modalModeloProcesso')}>Modelo em Branco</p>
                </span>
                <span id="botoesContainer" className="btnEscolaTemplat">
                    <Button type='button' cor='cancelar' isLoading={false} onClick={() => modalExibir("")}>
                        Fechar
                    </Button>
                </span>
            </section>
        </section>
    </div>
  )
}

export default ModalModeloProcessoOpcaoTamplet