import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { atualizarModeloProcesso, listarModelosProcessos } from '../../services/modelosProcessos'
import { modelosProcessosListaResposta } from '../../interfaces/modelosProcessos'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ToogleDefault from '../ToogleDafault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import ModalModeloProcesso from '../ModalModeloProcesso'
import ModalModeloProcessoOpcaoTamplet from '../ModalModeloProcessoOpcaoTemplat'
import Perfil from '../../middleware/Perfil'
import FiltrosDefault from '../FiltrosDefault'
import DivSituacao from '../DivSituacao'
import LoagindBarra from '../LoadingBarra'
import Notificacoes from '../Notificacoes'
import ModalAlerta from '../ModalAlerta'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './modelosprocessos.css'
import ModalModelosTemplets from '../ModalModelosProcessosTamplet'

function ModelosProcessos () {

    const { modalProcessoEstado, exibirModalProcesso, atualizarDadosProcesso, atualizarInformacoesProcesso } = useContext(ModelosProcessosContext)
    const { modal, modalExibir } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location

    const [modelosProcessos, setModelosProcessos] = useState<modelosProcessosListaResposta[]>([])
    const [modeloSelecionado, setModeloSelecionado] = useState<modelosProcessosListaResposta>()
    const [modalModeloProcesso, setModalModeloProcesso] = useState(false)
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('ativo')
    const [processando, setProcessando] = useState(true)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)

    const exibirModaltarefas = () => exibirModalProcesso()

    const ocultarModalAlerta = () => setConfirmacao(false)


    const exibirModalDadosModelosProcessos = (modeloProcesso: modelosProcessosListaResposta, idModelo: string) => {

        navigate(`${pathname}?idModelo=${idModelo}`)
        exibirModalProcesso()

        return setModeloSelecionado(modeloProcesso)
    }

    const toggleModeloProcesso = async (idModelo: string, situacao: string) => {

        setProcessando(true)

        try {

            const payload = {
                situacao: situacao === 'ativo' ? 'inativo' : 'ativo'
            }

            const resposta = await atualizarModeloProcesso(idModelo, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDadosProcesso()
                setConfirmacao(true)
            }

        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }

    const buscarModelos = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        const params = {
            termo,
            situacao
        }

        try {

            const resposta = await listarModelosProcessos(params)

            if (resposta.data.length === 0) {
                setModelosProcessos(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setModelosProcessos(resposta.data)

                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    useEffect (() => {
        
        setModalModeloProcesso(modalProcessoEstado)

        if(!modalProcessoEstado) setModeloSelecionado(undefined)

    }, [modalProcessoEstado])



    useEffect(() => {
        
        const buscarModelos = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                termo: '',
                situacao: 'ativo'
            }

            try {

                const resposta = await listarModelosProcessos(params)

                if (resposta.data.length === 0) {
                    setModelosProcessos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {

                    setModelosProcessos(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
                
            }
        }

        buscarModelos ()

    }, [atualizarInformacoesProcesso])


    return (
        <Perfil perfil={['master']}>
            <div id='paginaContainer'>
                {modal.nome === 'modalEscolhaTemplat' && <ModalModeloProcessoOpcaoTamplet/>}
                {modal.nome === 'modalModeloProcesso' && <ModalModeloProcesso/>}
                {modal.nome === 'modalTamplesDisponiveis' && <ModalModelosTemplets/>}
                {modalModeloProcesso && <ModalModeloProcesso modeloProcesso={modeloSelecionado}/>}
                {processando && <LoagindBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {confirmacao && <ModalAlerta texto= 'Dados atualizados com sucesso' btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModalAlerta} />}
                {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro'/>}
                <FiltrosDefault termo={termo} situacao={situacao} placeholder='Pesquise pelo título do modelo do processo' tipo={'Processo Modelo'} onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarModelos} acaoBtn={() => modalExibir('modalEscolhaTemplat')} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'ativo'}, {nome: 'Inativos', valor: 'inativo'}]} genero='masculino' maisFiltros={false} isLoading={processando}/>

                <section id='fundoContainerPagina'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Título do Modelo</th>
                                <th id='ultimaAlteracaoModeloProcesso' align='left'>Última Alteração</th>
                                <th id='situacaoModeloProcesso' align='center'>Situação</th>
                                <th align='center'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modelosProcessos.map((modelo) => (
                                <tr key={modelo.id}>
                                    <td id='primeiraColuna' onClick={() => exibirModalDadosModelosProcessos(modelo, modelo.id)}>{`${modelo.titulo.charAt(0).toUpperCase()}${modelo.titulo.substring(1)}`}</td>
                                    <td id='usuarioAlteracaoModeloProcessoContent' onClick={() => exibirModalDadosModelosProcessos(modelo, modelo.id)}>
                                        <img id='ultimaAlteracaoUsuarioModeloProcesso' src={modelo.ultimaAlteracao.avatar ? modelo.ultimaAlteracao.avatar : avatarDefault} alt='' />
                                        {modelo.ultimaAlteracao.nome}
                                    </td>
                                    <td id='situacaoModeloProcesso' align='center' onClick={() => exibirModalDadosModelosProcessos(modelo, modelo.id)}>
                                        <DivSituacao texto={`${modelo.situacao.charAt(0).toUpperCase()}${modelo.situacao.substring(1)}`} cor={modelo.situacao}/>
                                    </td>
                                    <td id='ladoDireitoContent' align='center' onClick={() => toggleModeloProcesso(modelo.id, modelo.situacao)}>
                                        <ToogleDefault id={modelo.id} checked={() => modelo.situacao === 'ativo' ? true : false}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </Perfil>
    )
}

export default ModelosProcessos