import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import { ProcessosProvider } from "../../contexts/Processos"
import ProcessosTamplatsPagina from "../../components/ModelosProcessosTamplet"

function ProccessosModelosTamplets () {

    useEffect (() => {
        document.title = 'Processos Tamplets | Gesttor'
    }, [])
    
    return (
        <ProcessosProvider>
            <DadosContainerDefault>
                <ProcessosTamplatsPagina />
            </DadosContainerDefault>
        </ProcessosProvider>
    )
}

export default ProccessosModelosTamplets
