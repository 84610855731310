import { Button, Loader } from "../Styles";

function ModalAvisoCancelar({ titulo, processando, funcaoSubmit, onClose }: any) {
  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        <form>
          <span className='novoContainerTitulo'>
            <h1>{titulo}</h1>
          </span>
          <span id="sectionDadosOrganizacaoHeard">
            <Button type='button' cor='cancelar' isLoading={processando} onClick={() => onClose("")}>Fechar</Button>
            <Button type='button' cor='salvar' isLoading={processando} onClick={funcaoSubmit}>
              {!processando && 'Sim'}
              {processando && <Loader isLoading={processando} />}
            </Button>
          </span>
        </form>
      </section>
    </div>
  );
}

export default ModalAvisoCancelar;