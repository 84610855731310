import { useEffect, useState, useContext, ChangeEvent, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarContas, transferenciaBancaria, extratosBancarios } from '../../services/contas'
import { contasListaResposta, extratosRespostaPayload, extratosPorDia } from '../../interfaces/contas'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { moedaMascara, converterNumero } from '../../helpers'
import { Button, Loader } from '../Styles'
import ModalContaBancaria from '../ModalConta'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import LoagindBarra from '../LoadingBarra'
import Perfil from '../../middleware/Perfil'
import editarImg from '../../assets/img/editar.png'
import './contas.css'

function Contas () {

    const { atualizarInformacoes, atualizarDados, exibirModal, modalEstado } = useContext(ModalEstadoContext)


    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location
    const paramsUrl = new URLSearchParams(search)

    const idConta = paramsUrl.get('id')


    const data = new Date()

    const ano = data.getFullYear()
    const mesAtual = data.getMonth() + 1
    const qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()

    const mesAtualFormatado = (`0${mesAtual}`).slice (-2)

    const dataInicio = `${ano}-${mesAtualFormatado}-01`
    const dataTermino = `${ano}-${mesAtualFormatado}-${qtnDias}`

    const [contasBancarias, setContasBancarias] = useState<contasListaResposta[]>([])
    const [contaSelecionada, setContaSelecionada] = useState<contasListaResposta>()
    const [extratos, setExtratos] = useState<extratosRespostaPayload[]>([])
    const [modalContaBancaria, setModalContaBancaria] = useState(false)
    const [saldoTotal, setSaldoTotal] = useState(0)
    const [contaSaida, setContaSaida] = useState('')
    const [contaEntrada, setContaEntrada] = useState('')
    const [dataTransacao, setDataTransicao] = useState('')
    const [pagina, setPagina] = useState(2)
    const [dataInicial, setDataInicial] = useState(dataInicio)
    const [dataFinal, setdataFinal] = useState(dataTermino)
    const [valor, setValor] = useState('')
    const [processando, setProcessando] = useState(false)
    const [contasIguais, setContasIguais] = useState(false)
    const [sucesso, setSucesso] = useState(false)
    const [erro, setErro] = useState(false)

    const exibirModalNovaConta = () => {
        return exibirModal()
    }

    const exibirModalContaDados = (conta: contasListaResposta, id: string, idTransacao: string) => {

        exibirModal()
        navigate(`${pathname}?id=${id}#${idTransacao}`)

        return setContaSelecionada(conta)
    }

    const changeValor = (e: ChangeEvent<HTMLInputElement>) => {

        return setValor(moedaMascara(e.target.value))
    }

    const extratoPorConta = (id: string) => {
        
        return navigate(`${pathname}?id=${id}`)
    }

    const saldoDia = (extratos: extratosRespostaPayload[]): extratosPorDia[] => {

        const agrupado: Record<string, extratosRespostaPayload[]> = {}

        extratos.forEach(extrato => {
            const data = extrato.dataTransacao.split('T')[0]

            if (!agrupado[data]) {
                agrupado[data] = []
            }
            agrupado[data].push(extrato)
        })

        let saldoAcumulado = 0


        return Object.entries(agrupado).map(([data, transacoes]) => {

            const saldoDoDia = transacoes.reduce((total, extrato) => {

                const valor = Number(extrato.valor)
                return total + valor

            }, 0)

            saldoAcumulado += saldoDoDia
    
            return { data, transacoes, saldoDoDia, saldoAcumulado }
        })
    }

    const extratosAgrupados = saldoDia(extratos)

    const transferirSaldo = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        if(contaSaida === contaEntrada) {
            setProcessando(false)
            setContasIguais(true)

            return setTimeout(() => {
                setContasIguais(false)
            }, 2500)
        }

        try {

            const payload = {
                idContaOrigem: contaSaida,
                idContaDestino: contaEntrada,
                dataTransacao,
                valor: converterNumero(valor)/100
            }

            const resposta = await transferenciaBancaria(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setSucesso (true)
                atualizarDados()

                return setTimeout(() => {
                    setSucesso(false)
                }, 2500)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2500)
            
        }
    }

    const scrollInfinito = (e: any) => {

        const { scrollTop, clientHeight, scrollHeight } = e.target;

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)

        if (posicaoScroll === 100) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisExtratos = async () => {


                const params = {
                    idConta: '',
                    pagina,
                    dataInicial,
                    dataFinal
                }

                if (idConta) {
                    Object.assign(params, {
                        idConta
                    })
                }

                try {

                    const resposta = await extratosBancarios(params)

                    if (resposta.status === 200) {

                        setExtratos(prevDate => [...prevDate, ...resposta.data])

                        return
                    }
            
                } catch (error) {

                    setErro(true)

                    return setTimeout(() => {
                        setErro(false)
                    }, 2000)
                }
            }

            buscarMaisExtratos()
        }
    }

    useEffect(() => {

        setModalContaBancaria(modalEstado)
        if(!modalEstado) setContaSelecionada(undefined)

        return

    }, [modalEstado])

    useEffect(() => {
        let saldoToal = 0

        contasBancarias.map((conta) => (
            saldoToal += Number(conta.baixas[0].saldoFinal)
        ))

        return setSaldoTotal(saldoToal)

    }, [contasBancarias])

    useEffect(() => {

        const buscarContas = async () => {

            setProcessando(true)

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa',
                    dataFinal
                }

                const resposta = await listarContas(params)

                if(resposta.status === 200) {
                    setProcessando(false)

                    return setContasBancarias(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarContas()

    }, [atualizarInformacoes, dataFinal])

    useEffect(() => {

        const buscarExtratos = async () => {

            setProcessando(true)

            try {

                const params = {
                    idConta: '',
                    pagina: 1,
                    dataInicial,
                    dataFinal
                }

                if (idConta) {
                    Object.assign(params, {
                        idConta
                    })
                }

                const resposta = await extratosBancarios(params)

                if(resposta.status === 200) {
                    setProcessando(false)

                    return setExtratos(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarExtratos()

    }, [idConta, dataInicial, dataFinal, atualizarInformacoes])

    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div id='paginaContasBancarias'>
                {modalContaBancaria && <ModalContaBancaria conta={contaSelecionada}/>}
                {contasIguais && <Notificacoes icone={erroImg} texto='A conta de origem não pode ser igual a de destino' cor='erro' />}
                {erro && <Notificacoes icone={confirmacaoImg} texto='Ocorreu um Erro na Solicitação. Tente Novamente' cor='erro' />}
                {sucesso && <Notificacoes icone={confirmacaoImg} texto='Dados Salvos com Sucesso' cor='sucesso' />}
                {processando && <LoagindBarra />}
                <section id='tituloContasBancarias'>
                    <h1>Contas Bancárias</h1>
                    <Button type='button' cor='salvar' isLoading={processando} onClick={exibirModalNovaConta} >Cadastrar Conta</Button>
                </section>

                <div id='contasBancariasContainer' onScroll={scrollInfinito}>
                    <section id='contasBancáriasContent'>
                        <div>
                            <span className='tituloCardContasBancarias'>
                                <h1>Saldo Total</h1>
                                <p className='saldoContaBancaria'>{saldoTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                            </span>
                            <span id='tabelaListaContasBancariasContainer'>
                                <table id='tabelaListaContasBancarias'>
                                    <tbody>
                                        {contasBancarias.map((conta) => (
                                            <tr key={conta.id}>
                                                <td className='primeiraColuna' onClick={() => extratoPorConta(conta.id)}>{conta.descricao}</td>
                                                <td align='right' onClick={() => extratoPorConta(conta.id)}>{Number(conta.baixas[0].saldoFinal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                <td className='ladoDireitoContent' align='right'>
                                                    <img id='imgEditarConta' src={editarImg} alt='' onClick={() => exibirModalContaDados(conta, conta.id, conta.idTransacao)}/>
                                                </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </span>
                        </div>

                        <div>
                            <span className='tituloCardContasBancarias'>
                                <h1>Transferência Bancárias</h1>
                            </span>
                            <form id='formModalNovo' onSubmit={transferirSaldo}>
                                <section>
                                    <span>
                                        <label htmlFor="contaSaida">Conta Origem</label>
                                        <select id="contaSaida" value={contaSaida} onChange={(e) => setContaSaida(e.target.value)} required>
                                            <option value=""></option>
                                            {contasBancarias.map((conta) => (
                                                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                            ))}
                                        </select>
                                    </span>
                                    <span>
                                        <label htmlFor="contaDestino">Conta Destino</label>
                                        <select id="contaDestino" value={contaEntrada} onChange={(e) => setContaEntrada(e.target.value)} required>
                                            <option value=""></option>
                                            {contasBancarias.map((conta) => (
                                                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                            ))}
                                        </select>
                                    </span>
                                </section>
                                <section>
                                        <span>
                                            <label htmlFor="dataTransicao">Data da Transação</label>
                                            <input
                                                id='dataTransicao'
                                                value={dataTransacao}
                                                onChange={(e) => setDataTransicao(e.target.value)}
                                                type="date"
                                                required/>
                                        </span>

                                        <span>
                                            <label htmlFor="valorTransferencia">Valor</label>
                                            <input
                                                id='valorTransferencia'
                                                value={valor}
                                                placeholder='R$ 0,00'
                                                onChange={(e) => changeValor(e)}
                                                type="text" 
                                                required/>
                                        </span>
                                    </section>
                                    <span id="botoesContainerTransferenciaBancaria">
                                        <Button type='submit' cor='salvar' isLoading={processando}>
                                            {!processando && 'Salvar'}
                                            {processando && <Loader isLoading={processando}/>}
                                        </Button>
                                    </span>
                                </form>
                        </div>   
                    </section>
                    <section id='tabelaExtratoContasContainer'>
                        <span className='tituloCardContasBancarias'>
                            <h1>Extrato</h1>
                            <form id='dataExtratoContainer'>
                                <span className='dataExtrato'>
                                    <label htmlFor="dataInicioExtrato">Data Inicial</label>
                                    <input
                                        id='dataInicioExtrato'
                                        value={dataInicial}
                                        onChange={(e) => setDataInicial(e.target.value)} 
                                        type="date" />
                                </span>
                                <span className='dataExtrato'>
                                    <label htmlFor="dataFinalExtrato">Data Final</label>
                                    <input
                                        id='dataFinalExtrato' 
                                        value={dataFinal}
                                        onChange={(e) => setdataFinal(e.target.value)}
                                        type="date" />
                                </span>
                            </form>
                        </span>
                        <table id='tabelaExtratoContas'>
                            <thead>
                                <tr>
                                    <th className='ladoEsquerdoContent' align='left'>Data</th>
                                    <th align='left'>Tipo</th>
                                    <th align='left'>Descrição</th>
                                    <th align='left'>Cliente/Colaborador/Fornecedor</th>
                                    <th align='left'>Conta Bancária</th>
                                    <th align='left'>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extratosAgrupados.map(({ data, transacoes, saldoDoDia }) => (
                                    <>
                                        <tr className="saldoDia">
                                            <td className='primeiraColuna saldoDiaConta' colSpan={5}><strong>Saldo do dia {data.split('-').reverse().join('/')}</strong></td>
                                            <td className='ladoDireitoContent saldoDiaConta'><strong>{saldoDoDia.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
                                        </tr>
                                        {transacoes.map((extrato, index) => (
                                            <tr key={`${data}-${index}`}>
                                                <td className='primeiraColuna' align='left'>{`${extrato.dataTransacao.slice(8, 10)}-${extrato.dataTransacao.slice(5, 7)}-${extrato.dataTransacao.slice(0, 4)}`}</td>
                                                <td>{extrato.tipo}</td>
                                                <td>{extrato.descricao}</td>
                                                <td>{extrato.cadastro?.nomeFantasia || '-'}</td>
                                                <td>{extrato.conta.descricao}</td>
                                                <td className='ladoDireitoContent'>{Number(extrato.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        </Perfil>
    )
}

export default Contas