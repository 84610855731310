import { useState, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import Perfil from '../../middleware/Perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuConfiguracao (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    const { modalExibir } = useContext(ModalEstadoContext)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    function exibirModalMinhaAssinatura () {
        modalExibir('minhaAssinatura')
    }

    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div>
                <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                    {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                    <p id = 'tituloMenu'>{props.titulo}</p>
                </div>
                <div id = 'submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect = '/organizacao' texto = 'Dados da Empresa' />}
                    {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Minha Assinatura' acao={exibirModalMinhaAssinatura}/>}
                    {subMenu && <SubMenuOpcoes redirect = '/usuarios' texto = 'Usuários' />}
                    {subMenu && <SubMenuOpcoes redirect = '/integracoes' texto = 'Integrações' />}
                    {subMenu && <SubMenuOpcoes redirect = '/centros' texto = 'Centro de Resultado/Custo' />}
                    {subMenu && <SubMenuOpcoes redirect = '/categorias' texto = 'Plano de Contas' />}
                </div>
            </div>
        </Perfil>
    )
}

export default MenuConfiguracao