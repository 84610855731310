import { useState, useEffect, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarProcessos } from '../../services/processos'
import Organizacao from '../../middleware/Organizacao'
import Perfil from '../../middleware/Perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuProcessos (props: menuOpcoes) {

    const { atualizarInformacoes } = useContext(ModalEstadoContext)

    const [subMenu, setSubMenu] = useState(false)
    const [meusProcessos, setMeusProcessos] = useState(0)
    const [processosEstritorio, setProcessosEscritorio] = useState(0)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect(() => {
        
        const buscarMeusProcessos = async () => {

            const params = {
                pagina: 1,
                titulo: '',
                descricao: '',
                cliente: '',
                responsavel: '',
                situacao: 'ativo',
                minhasSolicitacoes: false,
                processosEscritorio: 'false',
            }

            try {

                const resposta = await listarProcessos(params)

                if (resposta.status === 200) return setMeusProcessos(resposta.data.length)
                
            } catch (error) {
                
            }
        }

        buscarMeusProcessos ()

    }, [atualizarInformacoes])

    useEffect(() => {
        
        const buscarProcessosEscritorio = async () => {

            const params = {
                pagina: 1,
                titulo: '',
                descricao: '',
                cliente: '',
                responsavel: '',
                situacao: 'ativo',
                minhasSolicitacoes: false,
                processosEscritorio: 'true',
            }

            try {

                const resposta = await listarProcessos(params)

                if (resposta.status === 200) return setProcessosEscritorio(resposta.data.length)
                
            } catch (error) {
                
            }
        }

        buscarProcessosEscritorio ()

    }, [atualizarInformacoes])

    return (
        <div>
            <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                <p id = 'tituloMenu'>{props.titulo}</p>
                {meusProcessos !== 0 ? <h2>{meusProcessos}</h2> : null}
            </div>
            <div id = 'submenuOpcoes'>
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/processos?escritorio=true' texto = 'Todos os Processos' notificacao={processosEstritorio === 0 ? null : processosEstritorio} />}
                    </>
                </Perfil>
                {subMenu && <SubMenuOpcoes redirect = '/processos' texto = 'Meus Processos' notificacao={meusProcessos === 0 ? null : meusProcessos} />}
                <Perfil perfil={['master']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/modelos-processos' texto = 'Modelos de Processos' />}
                    </>
                </Perfil>
                <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                    <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                        <>
                            {subMenu && <SubMenuOpcoes redirect = '/modelos-processos/tamplets' texto = 'Tamplets' />}
                        </>
                    </Perfil>
                </Organizacao>
            </div>
        </div>
    )
}

export default MenuProcessos