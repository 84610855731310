import { useState } from 'react'
import Perfil from '../../middleware/Perfil'
import SubMenuOpcoes from '../SubMenuOpcoes'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuDocumentosDigitais (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div>
                <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                    {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                    <p id = 'tituloMenu'>{props.titulo}</p>
                    <h3>Novo</h3>
                </div>
                <div id = 'submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect = {'/contratos'} texto = 'Contratos' />}
                    {subMenu && <SubMenuOpcoes redirect = {'/documentos-digitais'} texto = 'Solicitar Assinaturas' />}
                    <Perfil perfil={['master', 'administrativo']}>
                        <>
                            {subMenu && <SubMenuOpcoes redirect = {'/documentos-digitais?escritorio=true'} texto = 'Todas as Solicitações' />}
                            {subMenu && <SubMenuOpcoes redirect = {'/modelos-contratos'} texto = 'Modelos de Contratos' />}
                        </>
                    </Perfil>
                </div>
            </div>
        </Perfil>
    )
}

export default MenuDocumentosDigitais