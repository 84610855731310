import { payloadAdicionarContato, payloadAtualizarComunicado, payloadCadastrarComunicado, payloadAtualizarContato, payloadCriarTag, payloadCadastrarContato } from '../../interfaces/atendimento'
import BASEAPI from '../api/config'

export function buscarComunicados() {
  return BASEAPI.get('/comunicados')
}

export function filtrarComunicado({ usuario, grupo, situacao, dataInicial, dataFinal, titulo }: any) {
  return BASEAPI.get(`/comunicados?usuario=${usuario || ""}&grupo=${grupo || ""}&situacao=${situacao || ""}&dataInicio=${dataInicial || ""}&dataFinal=${dataFinal || ""}&titulo=${titulo || ""}`)
}

export function cadastrarComunicado(payload: payloadCadastrarComunicado) {
  return BASEAPI.post('/comunicados', payload)
}

export function atualizarComunicado(payload: payloadAtualizarComunicado, idComunicado: string) {
  return BASEAPI.patch(`/comunicados/${idComunicado}`, payload)
}

export function buscarTags() {
  return BASEAPI.get('/tags')
}

export function listarContatos(idComunicado: string) {
  return BASEAPI.get(`/destinatarios/${idComunicado}`)
}

export function listarTodosContatos() {
  return BASEAPI.get(`/contato`)
}

export function listarContatosPorIdCadastro(idCadastro: string) {
  return BASEAPI.get(`/contato/${idCadastro}`)
}

export function adicionarContato(payload: payloadAdicionarContato, idComunicado: string) {
  return BASEAPI.post(`/destinatarios/${idComunicado}`, payload)
}

export function listarTodosCandidatos(notificacaoEmail: string, notificacaoWhatsapp: string) {
  return BASEAPI.get(`/listarCandidatosParaComunicado?notificacaoEmail=${notificacaoEmail}?notificacaoWhatsapp=${notificacaoWhatsapp}`)
}

export function cancelarDestinatario(idDestinatario: string) {
  return BASEAPI.patch(`/destinatarios/${idDestinatario}`)
}

export function atualizarContato(idContato: string, payload: payloadAtualizarContato) {
  return BASEAPI.patch(`/contato/${idContato}`, payload)
}

export function criarTag(payload: payloadCriarTag) {
  return BASEAPI.post(`/tags`, payload)
}

export function cadastrarContato(payload: payloadCadastrarContato) {
  return BASEAPI.post(`/contato`, payload)
}

export function removerTagContato(idTag: string) {
  return BASEAPI.delete(`/tags/${idTag}`)
}

export function removerContato(idContato: string) {
  return BASEAPI.delete(`/contato/${idContato}`)
}