import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { licencasGesttor } from '../../services/organizacao'
import { ModalOrganizacaoDados } from '../../interfaces/organizacao'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Button, Loader } from '../Styles'
import licencaImg from '../../assets/img/licenca.png'
import { RiCustomerService2Fill, RiDraftFill } from "react-icons/ri";
import '../../styles/modal.css'
import './index.css'
import { toast } from 'react-toastify'
import { criptografarDado } from '../../helpers'
import { atualizarModulo, buscarModulos } from '../../services/modulos'
import { listaModulo } from '../../interfaces/modulos'
import ModalNovoModulo from '../ModalNovoModulo'
import DivSituacao from '../DivSituacao'
import { MdOutlineCancelScheduleSend, MdOutlineFindInPage } from 'react-icons/md'
import { IoDocumentOutline } from 'react-icons/io5'
import { IoIosPaper } from 'react-icons/io'
import { CiChat1 } from "react-icons/ci";

function ModalGerenciarLicenca({ organizacao }: ModalOrganizacaoDados) {

    const { modal, modalExibir, exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()
    const { pathname, search } = location

    const [usuariosAdicionais, setUsuariosAdicionais] = useState<number | null | undefined>(organizacao?.usuariosAdicionais)
    const [validadeLicenca, setValidadeLicenca] = useState<string | undefined>(organizacao?.validadeLicenca)

    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState(false)

    const [modulos, setModulos] = useState<listaModulo[]>([])
    const [saldoDoc, setSaldoDoc] = useState<number>()

    function ocultarModal() {

        navigate(pathname)
        exibirModal()
    }

    const atualizarLicencas = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const parms = new URLSearchParams(search)
        const id = parms.get('idCliente')

        if (validadeLicenca == null || validadeLicenca === undefined || id === null || usuariosAdicionais === undefined) {
            return setProcessando(false)
        }

        const payload = {
            validadeLicenca,
            usuariosAdicionais
        }

        try {

            const resposta = await licencasGesttor(id, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                toast.success("Dados atualizados com sucesso.")
                atualizarDados()

                return
            }

        } catch (error: any) {

            setProcessando(false)

            return toast.error("Ocorreu um erro ao tentar salvar os dados!")

        }
    }

    const bloquearModulo = async (moduloSelecionado: listaModulo) => {
        setProcessando(true)
        if (!moduloSelecionado) return toast.error("Não foi possivel Encontrar o Módulo que Deseja Bloquear")
        const idModuloCript = criptografarDado(moduloSelecionado.id.toString())
        try {
            const payload = {
                situacao: "Inativo"
            }

            await atualizarModulo(idModuloCript, payload)
            setProcessando(false)
            atualizarDados()
            modalExibir("")
            return toast.success("Módulo Bloqueado com Sucesso.")
        } catch (error) {
            setProcessando(false)
            return toast.error("Erro ao tentar Bloquear Módulo")
        }
    }

    const ativarModulo = async (moduloSelecionado: listaModulo) => {
        setProcessando(true)
        if (!moduloSelecionado) return toast.error("Não foi possivel Encontrar o Módulo que Deseja Ativar")
        const idModuloCript = criptografarDado(moduloSelecionado.id.toString())
        try {
            const payload = {
                situacao: "Ativo"
            }

            await atualizarModulo(idModuloCript, payload)
            setProcessando(false)
            atualizarDados()
            modalExibir("")
            return toast.success("Módulo Ativado com Sucesso.")
        } catch (error) {
            setProcessando(false)
            return toast.error("Erro ao tentar Ativar Módulo")
        }
    }

    const atualizarSaldoModulo = async (moduloSelecionado: listaModulo) => {
        setProcessando(true)

        if (!moduloSelecionado) return toast.error("Não foi possivel Encontrar o Módulo para Atualizar o Saldo")
        if (moduloSelecionado.situacao === "Inativo") {
            toast.info("O Modulo precisa estar ativo para Atualizar o Saldo")
            return setProcessando(false)
        }
        if (!saldoDoc) {
            toast.info("Digite um valor para Atualizar o Saldo")
            return setProcessando(false)
        }
        const idModuloCript = criptografarDado(moduloSelecionado.id.toString())

        try {
            const payload = {
                saldoDoc
            }

            await atualizarModulo(idModuloCript, payload)
            setProcessando(false)
            atualizarDados()
            modalExibir("")
            return toast.success("Saldo Atualizado com Sucesso.")
        } catch (error: any) {
            if (saldoDoc !== undefined && saldoDoc < 0) {
                setProcessando(false)
                return toast.info("O Saldo não pode ser Negativo")
            }
            setProcessando(false)
            return toast.error("Erro ao tentar Atualizar Saldo")
        }
    }

    useEffect(() => {
        const listarModulosOrganizacao = async () => {

            const parms = new URLSearchParams(search)
            const idOrganizacao = parms.get('idCliente')
            if (!idOrganizacao) return;
            try {
                const idOrganizacaoCript = criptografarDado(idOrganizacao)
                const resposta = await buscarModulos(idOrganizacaoCript)

                return setModulos(resposta.data)
            } catch (error) {
                return toast.error("Erro ao tentar listar os Modulos da Organização")
            }
        }
        listarModulosOrganizacao()

    }, [atualizarDados, search])

    return (
        <div id='fundoModal'>
            {modal.nome === "ModalNovoModulo" && <ModalNovoModulo />}
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={licencaImg} alt='' />
                    <p>Gerenciar Licenças</p>
                </span>
                <form id='formModalNovo' onSubmit={atualizarLicencas}>
                    <section>
                        <span>
                            <label htmlFor="nomeFantasia">Nome Fantasia</label>
                            <input
                                id='nomeFantasia'
                                type="text"
                                value={organizacao?.nomeFantasia}
                                disabled />
                        </span>
                        <span>
                            <label htmlFor="razaoSocial">Razão Social</label>
                            <input
                                id='razaoSocial'
                                type="text"
                                value={organizacao?.razaoSocial === null ? '' : organizacao?.razaoSocial}
                                disabled />
                        </span>
                    </section>

                    <section className='novoContainerTitulo'>
                        <span>
                            <label htmlFor="usuariosAdicionais">Usuários Adicionais</label>
                            <input
                                id="usuariosAdicionais"
                                type="number"
                                min={0}
                                value={usuariosAdicionais === null ? 0 : usuariosAdicionais}
                                onChange={(e) => setUsuariosAdicionais(parseInt(e.target.value))}
                                required />
                        </span>
                        <span>
                            <label htmlFor="validadeLicenca">Validade da Licença</label>
                            <input
                                id="validadeLicenca"
                                type="date"
                                value={validadeLicenca}
                                onChange={(e) => setValidadeLicenca(e.target.value)}
                                required />
                        </span>
                    </section>
                    <label>Módulos da Organização</label>
                    {modulos && modulos.map((modulo) => (
                        <details id='container-gerenciar-modulos' key={modulo.id}>
                            <summary className='summary-gerenciar-modulos'>
                                <div className='header-modulo'>
                                    {modulo.nome === "Atendimento" && <RiCustomerService2Fill fontSize={"20px"} />}
                                    {modulo.nome === "Documentos" && <IoDocumentOutline  fontSize={"20px"} />}
                                    {modulo.nome === "Recrutamento" && <MdOutlineFindInPage fontSize={"20px"} />}
                                    {modulo.nome === "Projetos" && <RiDraftFill fontSize={"20px"} />}
                                    {modulo.nome === "Agendamento" && <MdOutlineCancelScheduleSend fontSize={"20px"} />}
                                    {modulo.nome === "Obrigações" && <IoIosPaper fontSize={"20px"} />}
                                    {modulo.nome === "Chat" && <CiChat1 fontSize={"20px"} />}
                                    <p>Módulo de {modulo.nome}</p>
                                </div>
                            </summary>
                            <section id='section-modulos'>
                                <div className='modulo-especifico'>
                                    <div className='situacao-modulo'>
                                        <DivSituacao texto={modulo.situacao} cor={modulo.situacao.toLowerCase()} />
                                        {modulo.nome === "Documentos" && <p>Saldo de documentos: <strong>{modulo.saldoDoc}</strong></p>}
                                    </div>
                                    <div>
                                        {modulo.situacao === "Ativo" && <Button type='button' cor='cancelar' isLoading={processando} onClick={(e: FormEvent) => bloquearModulo(modulo)}>Bloquear Módulo</Button>}
                                        {modulo.situacao === "Inativo" && <Button type='button' cor='salvar' isLoading={processando} onClick={(e: FormEvent) => ativarModulo(modulo)}>Liberar Módulo</Button>}
                                    </div>
                                </div>
                                {modulo.nome === "Documentos" && <div className='modulo-especifico'>
                                    <span>
                                        <input type="number" min={modulo.saldoDoc} placeholder='Novo Saldo de Documentos' onChange={(e) => setSaldoDoc(parseInt(e.target.value))} required/>
                                    </span>
                                    <div>
                                        <Button type='button' cor='salvar' isLoading={processando} onClick={(e: FormEvent) => atualizarSaldoModulo(modulo)}>Atualizar Saldo</Button>
                                    </div>
                                </div>}
                            </section>
                        </details>
                    ))}
                    <section>
                        <span className='btn-novo-modulo'>
                            <Button type='button' cor='novo' isLoading={processando} onClick={() => modalExibir("ModalNovoModulo")}>Adicionar Novo Módulo</Button>
                        </span>
                    </section>
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando} onClick={atualizarLicencas}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>
            </section>
        </div >
    )
}

export default ModalGerenciarLicenca
