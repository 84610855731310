import { useState, useEffect, FormEvent, useContext, ChangeEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { telefoneMascara, cpfCnpjMascara, validarCpfCnpj, cepMascara, descriptografarDado } from '../../helpers'
import { cadastrosRespostaPayload, ModalCadastrosDados } from '../../interfaces/cadastros'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novoCadastro, atualizarCadastro, listarCobrancas, listarCadastros } from '../../services/cadastros'
import { visualizarCobranca } from '../../services/cobrancas'
import { enviarCobrancaWa } from '../../services/cobrancas'
import { cobrancasResposta } from '../../interfaces/contratarSistema'
import { Button, Loader } from '../Styles'
import Organizacao from '../../middleware/Organizacao'
import ModalQrCode from '../ModalQrCodePix'
import DivSituacao from '../DivSituacao'
import ModalAlerta from '../ModalAlerta'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import boletoImg from '../../assets/img/codigoBarras.png'
import pixImg from '../../assets/icons/pix.svg'
import waImg from '../../assets/img/whatsappCobranca.png'
import adicionarIcone from '../../assets/img/novoUsuario.png'
import atualizarIcone from '../../assets/img/usuario.png'
import './cadastro.css'
import '../../styles/modal.css'


function ModalCadstro ({ cadastro }: ModalCadastrosDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { atualizarDados, modalExibir, atualizarInformacoes, modalSecundario, modalSecundarioExibir } = useContext(ModalEstadoContext)

    const { pathname } = location
    
    const idCadastro = location.hash.substring(1)

    const [responsaveis, setResponsaveis] = useState<cadastrosRespostaPayload[]>([])
    const [responsavel, setResponsavel] = useState(cadastro?.responsavelCadastro?.id)
    const [tipo, setTipo] = useState(cadastro?.tipo ?? 'cliente')
    const [classificacao, setClassificacao] = useState(cadastro?.classificacao || 'pessoa jurídica')
    const [cnpjCpf, setCnpjCpf] = useState(cadastro?.cnpjCpf)
    const [dataNascimento, setDataNascimento] = useState(cadastro?.dataNascimento || null)
    const [nomeFantasia, setNomeFantasia] = useState(cadastro?.nomeFantasia || null)
    const [razaoSocial, setRazaoSocial] = useState(cadastro?.razaoSocial)
    const [email, setEmail] = useState(cadastro?.email)
    const [telefone, setTelefone] = useState(cadastro?.telefone)
    const [rua, setRua] = useState(cadastro?.rua || null)
    const [numero, setNumero] = useState(cadastro?.numero || null)
    const [complemento, setComplemento] = useState(cadastro?.complemento || null)
    const [cep, setCep] = useState(cadastro?.cep || null)
    const [bairro, setBairro] = useState(cadastro?.bairro || null)
    const [cidade, setCidade] = useState(cadastro?.cidade || null)
    const [estado, setEstado] = useState(cadastro?.estado || 'alagoas')
    const [situacao, setSituacao] = useState (cadastro?.situacao)
    const [opocesCadastros, setOpcoesCadastros] = useState('cadastro')
    const [cobbrancas, setCobrancas] = useState<cobrancasResposta[]>([])
    const [cobrancasSelecionada, setCobrancaSelecionada] = useState({
        index: 0,
        exibir: false
    })
    const [cpfCnpjInvalido, setCpfCnpjInvalido] = useState(false)
    const [cnpjCpfCadastrado, setCnpjCpfCadastrado] = useState(false)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoWa, setConfimacaoWa] = useState(false)
    const [confirmacaoLink, setConfirmacaoLink] = useState(false)
    const [erro, setErro] = useState(false)

    const exibirOpcoesCadastro = (opcao: string) => {

        const opcoes = Array.from(document.querySelectorAll('.opcoesCadastrosLabel'))

        opcoes.forEach((label) => {
            label.addEventListener('click', () => {

                opcoes.forEach((element) => {
                    element.classList.remove('labelOpcoesCadastroAtiva')
                })
                
                label.classList.add('labelOpcoesCadastroAtiva')
                
            })
        })

        return setOpcoesCadastros(opcao)
    }

    function ocultarModal () {

        navigate(pathname)

        return modalExibir ('')
    }

    function ocultarModalAlerta () {
        
        return setErro(false)
    }

    const linkPagamento = async (link: string | undefined) => {

        setProcessando(true)

        if(!link) {
            return setProcessando(false)
        }

        try {

            await navigator.clipboard.writeText(link)

            setConfirmacaoLink(true)

            return setTimeout(() => {
                setProcessando(false)
                setConfirmacaoLink(false)
            }, 5000)
            
        } catch (error) {
            
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
        }
    }

    const modalQrCodePix = (idCobranca: number) => {
        
        localStorage.setItem('idCobrancaGesttor', idCobranca.toString())
        
        return modalSecundarioExibir('qrCodePix')
    }

    const exbirCobrancas = (index: number) => {
        setCobrancaSelecionada(prevState => {

            const lancamentoAtual = index === prevState.index
            
            const novoEstado = {
                index: (index === prevState.index && prevState.exibir) ? -index : index,
                exibir: lancamentoAtual ? !prevState.exibir : true
            }
            
            return novoEstado
        })
    }

    const visualizarCobrancaBoleto = async (idCobranca: number) => {

        setProcessando(true)

        try {

            const resposta = await visualizarCobranca(idCobranca)

            if (resposta.status === 200) {

                const dadosPdf = URL.createObjectURL(resposta.data)

                const pdf = document.createElement('a')

                pdf.href = dadosPdf
                pdf.download = 'boleto.pdf'

                document.body.appendChild(pdf)

                pdf.click()

                document.body.removeChild(pdf)

                setProcessando(false)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
            
        }
    }

    const faturaWa = async (idCobranca: number) => {

        setProcessando(true)

        try {

            const resposta = await enviarCobrancaWa(idCobranca)

            if (resposta.status === 200) {

                setProcessando(false)
                setConfimacaoWa(true)

                return setTimeout(() => {
                    setConfimacaoWa(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
            
        }
    }

    const cnpjCpfInvalido = (e: ChangeEvent<HTMLInputElement>) => {

        const resposta = validarCpfCnpj(e.target.value)

        if (resposta === true) {
            
            return
        }
        
        setCnpjCpf('')
        setCpfCnpjInvalido (true)

        return setTimeout(() => {
            setCpfCnpjInvalido(false)
        }, 2500)
    }


    const changeTelefone = (e: ChangeEvent<HTMLInputElement>) => {
        return setTelefone(telefoneMascara(e.target.value))
    }

    const changeCnpjCpf = (e: ChangeEvent<HTMLInputElement>) => {
        return setCnpjCpf(cpfCnpjMascara(e.target.value))
    }

    const changeCep = (e: ChangeEvent<HTMLInputElement>) => {
        return setCep(cepMascara(e.target.value))
    }

    const enviarCadastro = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(tipo === undefined || tipo === null || email === null || email === undefined || telefone === null || telefone === undefined || razaoSocial === null || razaoSocial === undefined) {
            return
        }

        try {

            const payload = {
                tipo,
                classificacao,
                cnpjCpf,
                dataNascimento,
                nomeFantasia,
                razaoSocial,
                email,
                telefone,
                rua,
                numero,
                bairro,
                complemento,
                cep,
                cidade,
                estado
            }


            const resposta = await  novoCadastro (payload)

            if (resposta.status === 201) {
                setConfirmacao(true)
                atualizarDados()
                return
            }
            
        } catch (error: any) {

            setProcessando(false)

            if(error.response.status === 410) {
                setCnpjCpfCadastrado(true)

                return setTimeout(() => {
                    setCnpjCpfCadastrado(false)
                }, 2500)
            }

            setErro(true)

            return
        }
    }

    const updateCadastro = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(nomeFantasia === undefined || razaoSocial === undefined || telefone === undefined || email === undefined || idCadastro === null) {
            return
        }

        const payload = {
            idResponsavel: responsavel,
            tipo,
            dataNascimento,
            nomeFantasia,
            razaoSocial,
            email,
            telefone,
            rua,
            numero,
            complemento,
            cep,
            cidade,
            bairro,
            estado,
            situacao
        }

        try {

            const resposta = await atualizarCadastro(payload, idCadastro)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }

    useEffect(() => {
        
        const buscarDados = async () => {

            if(!idCadastro) {
                return
            }

            try {

                const paramsCadastros = {
                    tipo: 'responsavel',
                    termo: '',
                    pagina: 1,
                    situacao: ''
                }

                const promisses = [
                    await listarCobrancas(idCadastro),
                    
                    await listarCadastros(paramsCadastros)
                ]

                const resposta = await Promise.all(promisses)

                const cobrancas = resposta[0]
                const responsavais = resposta[1]

                if(cobrancas.status === 200) {

                    setCobrancas(cobrancas.data)
                }

                if(responsavais.status === 200) {
                    setResponsaveis(responsavais.data)
                }
                
            } catch (error: any) {

            }
        }

        buscarDados ()

    }, [atualizarInformacoes, idCadastro])


    return (
        <div id = 'fundoModal'>
            {modalSecundario.nome === 'qrCodePix' && <ModalQrCode/>}
            {cpfCnpjInvalido && <Notificacoes icone={erroImg} texto='CNPJ/CPF Inválido. Confira os Dados' cor='erro'/>}
            {cnpjCpfCadastrado && <Notificacoes icone={erroImg} texto='Já existe um cadastrado para o CNPJ/CPF informado' cor='erro'/>}
            {confirmacao && <ModalAlerta texto= {cadastro ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {confirmacaoWa && <Notificacoes texto='Mensagem Enviada com Sucesso' icone={confirmacaoImg} cor='sucesso'/>}
            {confirmacaoLink && <Notificacoes texto='Link Copiado com Sucesso' icone={confirmacaoImg} cor='sucesso'/>}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            <section id={cadastro ? 'containerModalNovo' : ''} className={!cadastro ? 'containerModalCentro': ''}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={!cadastro ? adicionarIcone : atualizarIcone} alt='' />
                    <p>{!cadastro ? `Novo ${tipo?.charAt(0).toUpperCase()}${tipo?.substring(1)}`: 'Visualizar Dados'}</p>
                </span>
                
                <div className='opcoesCadastrosContainer'>
                    {cadastro && <label className='opcoesCadastrosLabel labelOpcoesCadastroAtiva' onClick={() => exibirOpcoesCadastro('cadastro')}>Cadastro</label>}
                    {cadastro && <label className='opcoesCadastrosLabel' onClick={() => exibirOpcoesCadastro('financeiro')}>Financeiro</label>}
                </div>
                {opocesCadastros === 'cadastro' && <form id='formModalNovo' onSubmit={!cadastro ? enviarCadastro : updateCadastro}>
                    <section>
                        <span>
                            <label htmlFor="classificacaoCadastro">Classificação</label>
                            <select id='classificacaoCadastro' value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value="cliente">Cliente</option>
                                <option value="responsavel">Responsavel</option>
                                {pathname !== '/leads' && <option value="colaborador">Colaborador</option>}
                                {pathname !== '/leads' && <option value="fornecedor">Fornecedor</option>}
                            </select>
                        </span>
                        <span>
                            <label htmlFor="tipoCadastro">Tipo</label>
                            <select id='tipoCadastro' value={classificacao} onChange={(e) => setClassificacao(e.target.value)} required>
                                <option value="pessoa física">Pessoa Física</option>
                                <option value="pessoa jurídica">Pessoa Jurídica</option>
                            </select>
                        </span>
                        <span>
                            <label htmlFor="cnpjCpfCadastro">CNPJ/CPF</label>
                            <input 
                                value={cnpjCpf}
                                id='cnpjCpfCadastro'
                                onChange={(e) => changeCnpjCpf(e)}
                                onBlur={(e) => cnpjCpfInvalido(e)}
                                maxLength={classificacao === 'pessoa jurídica' ? 20 : 16}
                                minLength={classificacao === 'pessoa jurídica' ? 20 : 16}
                                type="text"
                                required />
                        </span>
                        {cadastro && <span>
                            <label htmlFor="situacaoCadastro">Situação</label>
                            <select id="situacaoCadastro" value={situacao === null ? 'ativo' : situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="nomeFantasiaCadastro">Nome Fantasia</label>
                            <input
                                id='nomeFantasiaCadastro'
                                value={nomeFantasia === null ? '': nomeFantasia}
                                onChange={(e) => setNomeFantasia(e.target.value)}        
                                type="text"
                                required/>
                        </span>
                        <span>
                            <label htmlFor="razaoSocialCadastro">Razão Social</label>
                            <input
                                id='razaoSocialCadastro'
                                value={razaoSocial === null ? '': razaoSocial}
                                onChange={(e) => setRazaoSocial(e.target.value)} 
                                type="text"
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="telefoneCadastro">Telefone</label>
                            <span>
                            <input
                                id='telefoneCadastro'
                                value={telefone === null ? '' : telefone}
                                maxLength={18}
                                minLength={18}
                                onChange={(e) => changeTelefone(e)} 
                                placeholder='(00) 0.0000 - 0000'
                                type="text" 
                                required/>
                        </span>
                        </span>
                        <span>
                            <label htmlFor="emailCadastro">E-mail</label>
                            <input
                                id='emailCadastro'
                                value={email === null ? '' : email}
                                onChange={(e) => setEmail(e.target.value)} 
                                type="email" 
                                required/>
                        </span>
                        {(tipo === 'responsavel' || tipo === 'colaborador') && <span>
                            <label htmlFor="dataNascimento">Data de Nascimento</label>
                            <input
                                id='dataNascimento'
                                value={dataNascimento === null ? '' : dataNascimento}
                                onChange={(e) => setDataNascimento(e.target.value)} 
                                type="date" 
                                required/>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="ruaCadastro">Rua</label>
                            <span>
                            <input 
                                id='ruaCadastro'
                                value={rua === null ? '' : rua}
                                onChange={(e) => setRua(e.target.value)}
                                type="text" />
                        </span>
                        </span>
                        <span>
                            <label htmlFor="numeroCadastro">Número</label>
                            <input 
                                id='numeroCadstro'
                                value={numero === null ? '' : numero}
                                onChange={(e) => setNumero(e.target.value)}
                                type="text" />
                        </span>
                        <span>
                            <label htmlFor="bairroCadastro">Bairro</label>
                            <input 
                                id='bairroCadastro'
                                value={bairro === null ? '' : bairro}
                                onChange={(e) => setBairro(e.target.value)}
                                type="text" />
                        </span>
                        <span>
                            <label htmlFor="complementoCadastro">Complemento</label>
                            <input 
                                id='complementoCadastro'
                                value={complemento === null ? '' : complemento}
                                onChange={(e) => setComplemento(e.target.value)}
                                type="text" />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="cepCadastro">CEP</label>
                            <span>
                            <input
                                id='cepCadastro'
                                placeholder='00000 - 000'
                                value={cep === null ? '' : cep}
                                onChange={(e) => changeCep(e)}
                                minLength={11}
                                maxLength={11} 
                                type="text" 
                                required/>
                        </span>
                        </span>
                        <span>
                            <label htmlFor="cidadeCadastro">Cidade</label>
                            <input 
                                id='cidadeCadastro'
                                value={cidade === null ? '' : cidade}
                                onChange={(e) => setCidade(e.target.value)}
                                type="text" 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="emailCadastro">Estado</label>
                            <select name="estadosOrganizacao" id="estadosOrganizacao" value={estado === null ? '' : estado} onChange={(e) => setEstado(e.target.value)} required>
                                    <option value = 'acre'>Acre</option>
                                    <option value = 'alagoas'>Alagoas</option>
                                    <option value = 'amapá'>Amapá</option>
                                    <option value = 'amazonas'>Amazonas</option>
                                    <option value = 'bahia'>Bahia</option>
                                    <option value = 'ceará'>Ceará</option>
                                    <option value = 'espírito santo'>Espírito Santo</option>
                                    <option value = 'goiás'>Goiás</option>
                                    <option value = 'maranhão'>Maranhão</option>
                                    <option value = 'mato grosso'>Mato Grosso</option>
                                    <option value = 'mato grosso do sul'>Mato Grosso do Sul</option>
                                    <option value = 'minas gerais'>Minas Gerais</option>
                                    <option value = 'pará'>Pará</option>
                                    <option value = 'paraíba'>Paraíba</option>
                                    <option value = 'paraná'>Paraná</option>
                                    <option value = 'pernanbuco'>Pernambuco</option>
                                    <option value = 'piauí'>Piauí</option>
                                    <option value = 'rio de janeiro'>Rio de Janeiro</option>
                                    <option value = 'rio grande do norte'>Rio Grande do Norte</option>
                                    <option value = 'rio grande do sul'>Rio Grande do Sul</option>
                                    <option value = 'rondônica'>Rondônia</option>
                                    <option value = 'roraima'>Roraima</option>
                                    <option value = 'santa catarina'>Santana Catarina</option>
                                    <option value = 'são paulo'>São Paulo</option>
                                    <option value = 'sergipe'>Sergipe</option>
                                    <option value = 'tocantins'>Tocantins</option>
                                </select>
                        </span>
                    </section>

                    {cadastro && tipo === 'cliente' && <section>
                        <span>
                            <label htmlFor="responsavelCadastro">Responsável pelo Cliente</label>
                            <select id='responsavelCadastro' value={responsavel} onChange={(e) => setResponsavel(e.target.value)} required>
                                {!cadastro.responsavelCadastro && <option value=''></option>}
                                {
                                    responsaveis.map((responsavel) => (
                                        <option key={responsavel.id} value={responsavel.id}>{responsavel.nomeFantasia}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>}

                {opocesCadastros === 'financeiro' && <section id='fundoContainerPagina' className='tabelaCobrancasContainer'>
                    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                        <>
                            {tipo === 'cliente' && <Button type='button' isLoading={processando} cor='link' onClick={() => linkPagamento(cadastro?.link)}>Copiar Link de Pagamento</Button>}
                        </>
                    </Organizacao>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Descrição</th>
                                <th align='left'>Valor Total</th>
                                <th align='center'>Vencimento</th>
                                <th align='center'>Situação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cobbrancas.map((cobranca, index) => (
                                <>
                                    <tr key={index} onClick={() => exbirCobrancas(index)}>
                                        <td className='primeiraColuna descricaoCobranca'>{cobranca.descricao.toUpperCase()}</td>
                                        <td className='dadosCobrancas valorTotalCobranca'>{Number(cobranca.valorTotal).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                                        <td className='dadosCobranca' align='center'>{cobranca.vencimento.slice(8,10)}/{cobranca.vencimento.slice(5,7)}/{cobranca.vencimento.slice(0,4)}</td>
                                        <td className='dadosCobranca ladoDireitoContent' align='center'>
                                            <DivSituacao texto={`${cobranca.situacao.charAt(0).toUpperCase()}${cobranca.situacao.substring(1)}`} cor={cobranca.situacao.toLowerCase().replace(/\s+/g, '')}/>
                                        </td>
                                    </tr>
                                    { cobrancasSelecionada.exibir && cobrancasSelecionada.index === index && tipo === 'cliente' &&
                                        <tr className='linhaTabelaListaCobrancasAssiantens' key={Math.random()}>
                                            <td colSpan={4}>
                                                <table id='tabelaListaContainer'>
                                                    <thead>
                                                        <tr>
                                                            <th className='ladoEsquerdoContent' align='left'>Vencimento da Cobrança</th>
                                                            <th className='contaBaixaLancamento' align='left'>Tipo</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Valor</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Data do Recebimento</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Situação</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cobranca.cobrancas.map((cobrancaGerada) => (
                                                            <tr key={cobrancaGerada.id}>
                                                                <td className='primeiraColuna'>{cobrancaGerada.dataCobranca.slice(8,10)}-{cobrancaGerada.dataCobranca.slice(5,7)}-{cobrancaGerada.dataCobranca.slice(0,4)}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.tipo}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.valor?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.dataRecebimento?.slice(8,10)}-{cobrancaGerada.dataRecebimento?.slice(5,7)}-{cobrancaGerada.dataRecebimento?.slice(0,4)}</td>
                                                                <td>
                                                                    <DivSituacao texto={cobrancaGerada.situacao} cor={cobrancaGerada.situacao.toLowerCase().replace(/\s+/g, '')} />
                                                            </td>
                                                            <td id='ladoDireitoContent' className='ladoDireitoCobrancasMinhasAssinaturas' align='center'>
                                                                {cobrancaGerada.situacao === 'Em Cobrança' && <img id='apagarIconeTarefa' title='Visualizar Boleto' src={boletoImg} alt='' onClick={() => visualizarCobrancaBoleto(Number(cobrancaGerada.id))}/>}
                                                                {cobrancaGerada.situacao === 'Em Cobrança' && <img id='apagarIconeTarefa' title='Pagar com o Pix' src={pixImg} alt='' onClick={() => modalQrCodePix(cobrancaGerada.id)}/>}
                                                                <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                                                                    <>
                                                                        {cobrancaGerada.situacao === 'Em Cobrança' && <img id='apagarIconeTarefa' title='Enviar no WhatsApp' src={waImg} alt='' onClick={() => faturaWa(Number(cobrancaGerada.id))}/>}
                                                                    </>
                                                                </Organizacao>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    }
                                </>
                            ))}
                        </tbody>
                    </table>
                </section>}
            </section>
        </div>
    )
}

export default ModalCadstro
