import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import Comunicados from "../../components/Comunicados";

function ComunicadosPage() {
  useEffect(() => {
    document.title = 'Comunicados | Gesttor'
  }, [])

  return (
    <DadosContainerDefault>
      <Comunicados />
    </DadosContainerDefault>
  )
}

export default ComunicadosPage;