import { useEffect, useState, useContext } from 'react'
import { statusIntegracao } from '../../services/inter'
import { buscarIntegracao } from '../../services/asaas'
import { dadosWa, apagarSessaoWa } from '../../services/wa'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { statusIntegracaoResposta } from '../../interfaces/inter'
import { dadosIntegracaoWaResposta } from '../../interfaces/wa'
import { dadosIntegracaoAsaasResposta } from '../../interfaces/asaas'
import { toast } from 'react-toastify'
import ModalIntegracaoInter from '../ModalIntegracaoInter'
import ModalQrCodeWhatsApp from '../ModalQrCodeWhatsApp'
import ModalIntegracaoAsaas from '../ModalAsaas'
import Perfil from '../../middleware/Perfil'
import DivSituacao from '../DivSituacao'
import bancoInteterImg from '../../assets/img/bancoInter.png'
import asaasImg from '../../assets/img/asaas.png'
import whatsAppImg from '../../assets/img/whatsappCobranca.png'
import cancelarImg from '../../assets/img/erroFechar.png'
import qrCodeImg from '../../assets/img/qrCodeImagem.png'
import './integracoes.css'

function Integracoes () {

    const { modalEstado, exibirModal, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { modal , modalExibir } = useContext(ModalEstadoContext)

    const [asaas, setAsaas] = useState<dadosIntegracaoAsaasResposta>()
    const [dadosIntegracao, setDadosIntegracao] = useState<statusIntegracaoResposta>()
    const [dadosIntegracaoWa, setDadosIntegracaoWa] = useState<dadosIntegracaoWaResposta>()
    const [modalInter, setModalInter] = useState(false)


    const exibirModaltarefas = () => exibirModal()

    const exibirModalQrCodeWhatsApp = () => modalExibir('qrCodeWa')
    const exibirModalAsaas = () => modalExibir('asaas')


    const finalizarSessao = async () => {

        try {

            await toast.promise(
                apagarSessaoWa(), {
                    pending: 'Processsando a sua Solicitação',
                    success: 'Sessão de WhatsApp Desconectada com Sucesso'
                }
            ) 
            
        } catch (error) {

            toast.error('Falha ao tentar Finalizar a Sessão de WhatsApp')

            return
        }
    }


    useEffect (() => {
        
        setModalInter(modalEstado)

    }, [modalEstado])

    useEffect(() => {
        
        const buscarDadosIntegracaoWa = async () => {


            try {

                const resposta = await toast.promise(
                    dadosWa(), {
                        pending: 'Buscando Dados da Integração com o WhatsApp'
                    }
                )
                    
                if(resposta.status === 200) {
                    setDadosIntegracaoWa(resposta.data)
                }
                
            } catch (error) {

                toast.error('Falha ao Buscar dos Dados da Integração com o WhatsApp')

                return
                
            }
        }

        buscarDadosIntegracaoWa()

    }, [atualizarInformacoes])

    useEffect(() => {

        const buscarDadosIntegracao = async () => {

            try {

                const resposta = await toast.promise (
                    statusIntegracao(), {
                        pending: 'Buscando dados da Integração com o Inter',
                    }
                )
                if(resposta.status === 200) {
                    setDadosIntegracao(resposta.data)
                }
                
            } catch (error) {
                
                toast.error('Falha ao Buscar os Dados da Integração com o Inter')

                return
            }
        }

        buscarDadosIntegracao()

    }, [atualizarInformacoes])

    useEffect(() => {

        const buscarDadosIntegracaoAsaas = async () => {

            try {

                const resposta = await toast.promise(
                    buscarIntegracao(),
                    {
                        pending: 'Buscando dados da Integração Asaas',
                    }
                )

                if(resposta.status === 200) {
                    setAsaas(resposta.data)
                }
                
            } catch (error) {
                
                toast.error('Falha ao Buscar os Dados da Integração com o Asaas')

                return
            }
        }

        buscarDadosIntegracaoAsaas()

    }, [atualizarInformacoes])


    
    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div id='paginaIntegracoes'>
                {modalInter && <ModalIntegracaoInter dadosIntegracaoInter={dadosIntegracao}/>}
                {modal.nome === 'qrCodeWa' && <ModalQrCodeWhatsApp/>}
                {modal.nome === 'asaas' && <ModalIntegracaoAsaas dados={asaas}/>}
                <section className='tituloIntegracaoContainer'>
                    <h1>Integrações Disponíveis</h1>
                </section>
                <div className = 'integracoesContainer'>
                    <section className='cardIntegracoesContainer' onClick={exibirModaltarefas}>
                        <span className='cardIntegracao'>
                            <img className='cardIntegracaoIcone' src={bancoInteterImg} alt='' />
                            <p>Gere cobranças automaticamente com o Banco Inter</p>
                            {dadosIntegracao?.validade && <p>Validade: <b>{dadosIntegracao?.validade?.slice(8,10)}/{dadosIntegracao.validade?.slice(5,7)}/{dadosIntegracao.validade?.slice(0,4)}</b></p>}
                            {dadosIntegracao && <span className='situacaoIntegracao'>
                                <DivSituacao texto={dadosIntegracao.situacao.charAt(0).toUpperCase() + dadosIntegracao.situacao.substring(1)} cor={dadosIntegracao.situacao?.toLowerCase()}/>
                            </span>}
                        </span>
                    </section>
                    <section className='cardIntegracoesContainer'>
                        <span className='cardIntegracao'>
                            <img className='cardIntegracaoIcone' src={whatsAppImg} alt='' />
                            <p>Conecte seu WhatsApp e envie cobranças e avisos automáticos</p>
                            {dadosIntegracaoWa && <span className='situacaoIntegracao'>
                                <DivSituacao texto={dadosIntegracaoWa.situacao} cor={dadosIntegracaoWa.situacao.toLowerCase()}/>
                            </span>}
                            <div className='contentOpcoesIntegracoesWhatsApp'>
                                {!dadosIntegracaoWa && <img className='qrCodeIntegracaoWhatsApp' src={qrCodeImg} alt='' title='Gerar QrCode' onClick={() => exibirModalQrCodeWhatsApp()}/>}
                                {dadosIntegracaoWa?.situacao === 'Desconectado' && <img className='qrCodeIntegracaoWhatsApp' src={qrCodeImg} alt='' title='Gerar QrCode' onClick={() => exibirModalQrCodeWhatsApp()}/>}
                                {dadosIntegracaoWa?.situacao === 'Conectado' && <img className='desativarIntegracaoWhatsApp' src={cancelarImg} alt='' title='Desativar Integração' onClick={finalizarSessao}/>}
                            </div>
                        </span>
                    </section>
                    <section className='cardIntegracoesContainer' onClick={exibirModalAsaas}>
                        <span className='cardIntegracao'>
                            <img className='cardIntegracaoIcone' src={asaasImg} alt='' />
                            <p>Gere Cobranças com Cartão de Crédito e Débito com sua conta Asaas</p>
                            {asaas && <span className='situacaoIntegracao'>
                                <DivSituacao texto={asaas !== undefined ? asaas.situacao : ''} cor={asaas !== undefined ? asaas.situacao.toLowerCase() : ''}/>
                            </span>}
                        </span>
                    </section>
                </div>
            </div>
        </Perfil>
    )
}

export default Integracoes