import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarProcessos, apagarProcesso } from '../../services/processos'
import { processosListaResposta } from '../../interfaces/processos'
import { ProcessosContext } from '../../contexts/Processos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalProcessos from '../ModalProcessos'
import Perfil from '../../middleware/Perfil'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import apagarImg from '../../assets/img/excluir.png'
import DivSituacao from '../DivSituacao'
import LoagindBarra from '../LoadingBarra'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './processos.css'

function Processos () {

    const { modal , modalExibir} = useContext(ModalEstadoContext)
    const { atualizarDadosProcesso, atualizarInformacoesProcesso } = useContext(ProcessosContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location

    const [processos, setProcessos] = useState<processosListaResposta[]>([])
    const [processoSelecionado, setProcessoSelecionado] = useState<processosListaResposta>()
    const [cliente, setCliente] = useState('')
    const [situacao, setSituacao] = useState('ativo')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [excluirProcesso, setExluirProcesso] = useState(false)
    const [erro, setErro] = useState(false)

    const paramsUrl = new URLSearchParams(search)
    const processosEscritorio = paramsUrl.get('escritorio')

    const exibirModalProcessos = () => modalExibir('processo')

    const exibirModalDadosProcessos = (processo: processosListaResposta, idProcesso: string) => {

        if (processosEscritorio) {
            
            navigate(`${pathname}?escritorio=true&idProcesso=${idProcesso}`)
            modalExibir('processo')

            return setProcessoSelecionado(processo)
        }

        navigate(`${pathname}?idProcesso=${idProcesso}`)
        modalExibir('processo')

        return setProcessoSelecionado(processo)
    }

    const buscarProcessos = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        const params = {
            cliente,
            situacao,
            processosEscritorio
        }

        try {

            const resposta = await listarProcessos(params)

            if (resposta.data.length === 0) {
                setProcessos(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setProcessos(resposta.data)

                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    const deletarProcesso = async (idProcesso: string) => {

        setProcessando(true)

        try {

            const resposta = await apagarProcesso(idProcesso)

            if(resposta.status === 200) {
                setProcessando(false)
                setExluirProcesso(true)
                atualizarDadosProcesso()

                return setTimeout (() => {
                    setExluirProcesso(false)
                }, 2000)

            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
            
        }

    }

    useEffect (() => {

        if(!modal.nome) setProcessoSelecionado(undefined)

    }, [modal])

    useEffect(() => {
        
        const buscarProcessos = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                cliente: '',
                situacao: 'ativo',
                processosEscritorio
            }

            try {

                const resposta = await listarProcessos(params)

                if (resposta.data.length === 0) {
                    setProcessos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setProcessos(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                
            }
        }

        buscarProcessos ()

    }, [processosEscritorio, atualizarInformacoesProcesso])


    return (
        <div id='paginaContainer'>
            {modal.nome === 'processo' && <ModalProcessos processo={processoSelecionado}/>}
            {processando && <LoagindBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {excluirProcesso && <Notificacoes icone={confirmacaoImg} texto='Processo Exclúido com Sucesso' cor='sucesso' />}
            {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro'/>}
            <FiltrosDefault termo={cliente} situacao={situacao} placeholder='Pesquise pelo nome fantasia do cliente' tipo={'processo'} onChangeTermo={(e) => setCliente(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarProcessos} acaoBtn={exibirModalProcessos} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'ativo'}, {nome: 'Cancelados', valor: 'cancelado'}, {nome: 'Concluídos', valor: 'concluído'}]} genero='masculino' maisFiltros={false} isLoading={processando}/>

            <section id='fundoContainerPagina'>
                <table id='tabelaListaContainer'>
                    <thead>
                        <tr>
                            <th id='ladoEsquerdoContent' align='left'>Cliente</th>
                            <th align='left'>Processo</th>
                            <th align='left'>Título</th>
                            <th align='left' className='usuarioResponsavelProcesso'>Responsável</th>
                            <th id='progressoProcesso' align='left'>Progresso</th>
                            <th className='prazoProcesso' align='center'>Prazo</th>
                            <th id='situacaoProcesso' align='center'>Situação</th>
                            <Perfil perfil={['master']}>
                                <th>Ação</th>
                            </Perfil>
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((processo) => (
                            <tr key={processo.id}>
                                <td id='primeiraColuna' onClick={() => exibirModalDadosProcessos(processo, processo.id)}>{processo.clienteProcesso.nomeFantasia}</td>
                                <td onClick={() => exibirModalDadosProcessos(processo, processo.id)}>{processo.modelosProcesso.titulo}</td>
                                <td onClick={() => exibirModalDadosProcessos(processo, processo.id)}>{processo.titulo}</td>
                                <td className='usuarioResponsavelContent usuarioResponsavelProcesso' onClick={() => exibirModalDadosProcessos(processo, processo.id)}>
                                    <img className='usuarioResponsavel' src={processo.responsavelProcesso.avatar ? processo.responsavelProcesso.avatar : avatarDefault} alt='' />
                                    {processo.responsavelProcesso.nome}
                                </td>
                                <td id='progressoProcesso'>
                                    <svg width={100} height={20} rx={5} ry={5}>
                                        <rect rx={5} ry={5} width={100} height={20} stroke='rgba(106, 108, 114, 0.2)' strokeWidth={2} fill='none'/>
                                        <rect rx={5} ry={5} width={(processo.tarefasConcluidas/processo.tarefasTotais)*100} height={20} fill='#25ae88'/>
                                    </svg>
                                </td>
                                <td className='prazoProcesso' align='center' onClick={() => exibirModalDadosProcessos(processo, processo.id)}>{`${processo.previsaoTermino.slice(8,10)}-${processo.previsaoTermino.slice(5,7)}-${processo.previsaoTermino.slice(0,4)}`}</td>
                                <td id='situacaoProcesso' align='center' onClick={() => exibirModalDadosProcessos(processo, processo.id)}>
                                    <DivSituacao texto={`${processo.situacao.charAt(0).toUpperCase()}${processo.situacao.substring(1)}`} cor={processo.situacao}/>
                                </td>
                                <Perfil perfil={['master']}>
                                    <td id='ladoDireitoContent' align='center'>
                                        {processo.situacao !== 'concluída' ? <img className='apagarIconeItemTabela' title='Excluir Processo' src={apagarImg} alt='' onClick={() => deletarProcesso(processo.id)}/> : null}
                                    </td>
                                </Perfil>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    )
}

export default Processos