import { ResponsiveContainer } from "recharts";
import Perfil from "../../middleware/Perfil";

function GraficoComponent({ children, title, col }: any) {
  return (
    <Perfil perfil={['master']}>
      <div className={`graficoDashboardDefaultDiv ${col}`}>
        <p className='graficoDashboardDefaultP'>{title}</p>
        <ResponsiveContainer width="90%" height="100%">
          {children}
        </ResponsiveContainer>
      </div>
    </Perfil>);
}

export default GraficoComponent;