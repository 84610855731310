import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { leadFormaConhecimento } from '../../services/dashboard'
import LoadingCirculo from '../LoadingCirculo'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import Perfil from '../../middleware/Perfil'
import './leadformaconhecimento.css'

function GraficoLeadsFormaConhecimento () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState ([])
    const [semDados, setSemDados] = useState (false)
    const cores = ['#FF6B6C', '#78869C', '#25AE88', '##018ABE', '#FF8042']

    const raio = Math.PI / 180;
    const renderizarLabelCustomizada = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * raio);
        const y = cy + radius * Math.sin(-midAngle * raio);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    useEffect (() => {

        const graficoCliente = async () => {

            try {
                const resposta = await leadFormaConhecimento()
                setCarregando (false)
                setDados (resposta.data)
                setGrafico (true)

                if (resposta.data.length === 0) {
                    setGrafico(false)
                    return setSemDados(true)
                }

                setGrafico (true)

            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
                
            }
        }

        graficoCliente ()

    }, [navigate])


    return (
        <Perfil perfil = {['master']}>
            <div id = 'graficoLeadFormaConhecimento'>
                <p id = 'leadFormaConhecimentoP'>CANAIS DE VENDAS</p>
                {carregando && <LoadingCirculo />}
                {grafico && <ResponsiveContainer>
                    <PieChart>
                        <Pie 
                            data={dados} 
                            nameKey = 'formaConhecimento' 
                            dataKey = 'quantidade' 
                            labelLine={false}
                            label = {renderizarLabelCustomizada}>
                            {dados.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={cores[index % cores.length]} />
                            ))}
                        </Pie>
                        <Legend verticalAlign="bottom" height={40}/>
                    </PieChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Sem Dados para' subtexto='exibir o gráfico'/>}
            </div>
        </Perfil>
        
    )
}

export default GraficoLeadsFormaConhecimento