import { useState, useEffect, useContext } from 'react'
import { listarTarefas } from '../../services/tasks'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import Perfil from '../../middleware/Perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuTarefas (props: menuOpcoes) {

    const { atualizarInformacoes } = useContext(ModalEstadoContext)

    const [subMenu, setSubMenu] = useState(false)
    const [minhasTarefas, setMinhasTarefas] = useState(0)
    const [minhasSolicitacoes, setMinhasSolicitacoes] = useState(0)

    const data = new Date()

    const ano = data.getFullYear()
    const qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()
    const dataAnterior = new Date (new Date().setDate(new Date().getDate() - 60))
    
    const dataInicial = `${dataAnterior.getFullYear()}-${(dataAnterior.getMonth() + 1).toString().padStart(2, '0')}-01`
    const dataFinal = `${ano}-${(data.getMonth() + 1).toString().padStart(2, '0')}-${qtnDias}`

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect(() => {
        
        const buscarTarefas = async () => {

            const params = {
                pagina: 1,
                titulo: '',
                descricao: '',
                cliente: '',
                responsavel: '',
                situacao: '',
                minhasSolicitacoes: false,
                tarefasEscritorio: false,
                tarefasAutomaticas: false,
                dataInicio: dataInicial,
                dataTermino: dataFinal,
            }

            try {

                const resposta = await listarTarefas(params)

                if (resposta.status === 200) return setMinhasTarefas(resposta.data.length)
                
            } catch (error) {
                
            }
        }

        buscarTarefas ()

    }, [dataInicial, dataFinal])

    useEffect(() => {
        
        const buscarTarefas = async () => {

            const params = {
                pagina: 1,
                titulo: '',
                descricao: '',
                cliente: '',
                responsavel: '',
                situacao: 'pendente',
                minhasSolicitacoes: true,
                tarefasEscritorio: false,
                tarefasAutomaticas: false,
                dataInicio: dataInicial,
                dataTermino: dataFinal,
            }

            try {

                const resposta = await listarTarefas(params)

                if (resposta.status === 200) return setMinhasSolicitacoes(resposta.data.length)
                
            } catch (error) {
                
            }
        }

        buscarTarefas ()

    }, [dataInicial, dataFinal, atualizarInformacoes])

    return (
        <div>
            <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                <p id = 'tituloMenu'>{props.titulo}</p>
                {(minhasTarefas + minhasTarefas) !== 0 ? <h2>{minhasTarefas + minhasSolicitacoes}</h2> : null}
            </div>
            <div id = 'submenuOpcoes'>
                {subMenu && <SubMenuOpcoes redirect = {'/tarefas'} texto = 'Minhas Tarefas' notificacao={minhasTarefas === 0 ? null : minhasTarefas}/>}
                {subMenu && <SubMenuOpcoes redirect = {'/tarefas?minhasSolicitacoes=true'} texto = 'Minhas Solicitações' notificacao={minhasSolicitacoes === 0 ? null : minhasSolicitacoes}/>}
                <Perfil perfil={['master', 'administrativo']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = {'/tarefas?automaticas=true'} texto = 'Tarefas Recorrentes'/>}
                        {subMenu && <SubMenuOpcoes redirect = '/tarefas?escritorio=true' texto = 'Visão Geral'/>}
                    </>
                </Perfil>
            </div>
        </div>
    )
}

export default MenuTarefas