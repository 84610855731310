import { useState, FormEvent, useContext, useEffect } from 'react'
import { contratosModelosListaResposta } from '../../interfaces/contratosModelos'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarCadastros } from '../../services/cadastros'
import { cadastrarContrato } from '../../services/contratos'
import { listarContratoModelo } from '../../services/contratosModelos'
import { Button, Loader } from '../Styles'
import { converterNumero, moedaMascara } from '../../helpers'
import Notificacoes from '../Notificacoes'
import documentoImg from '../../assets/img/documentos.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import './contratos.css'
import '../../styles/modal.css'

function ModalContratos() {

    const { atualizarDados, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext)

    const [clientes, setClientes] = useState<cadastrosRespostaPayload []>([])
    const [cliente, setCliente] = useState< string | null>(null)
    const [contratos, setContratos] = useState<contratosModelosListaResposta []>([])
    const [contrato, setContrato] = useState<string | null>(null)
    const [descricaoServico, setDescricaoServico] = useState<string | null>(null)
    const [descricaoPagamento, setDescricaoPagamento] = useState<string | null>(null)
    const [valorContrato, setValorContrato] = useState(0)
    const [observacoes, setObservacoes] = useState<string | null>(null)
    const [processando, setProcessando] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)

    function ocultarModal() {

        return modalExibir('')
    }

    const cadastrarContratos = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(!cliente || !contrato || !descricaoPagamento || !descricaoServico) return setProcessando(false)

        try {

            const payload = {
                idCadastro: cliente,
                idContratoModelo: contrato,
                descricaoServico,
                descricaoPagamento,
                valorContrato: converterNumero(valorContrato)/100,
                observacoes
            }

            const resposta = await cadastrarContrato(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    useEffect(() => {

        const buscarDados = async () => {

            try {

                const paramsCadastros = {
                    pagina: 1,
                    tipo: 'cliente',
                    termo: '',
                    situacao: 'ativo'
                }

                const paramsContratosModelos = {
                    termo: '',
                    situacao: 'Ativo'
                }

                const promisses = [
                    await listarCadastros(paramsCadastros),
                    await listarContratoModelo(paramsContratosModelos)
                ]

                const resposta = await Promise.all(promisses)

                const clientes = resposta[0]
                const modelosContratos = resposta[1]

                if(clientes.status === 200) {
                    setProcessando(false)
                    setClientes(clientes.data)
                }

                if(modelosContratos.status === 200) {
                    setProcessando(false)
                    setContratos(modelosContratos.data)
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 5000)
                
            }
        }

        buscarDados ()

    }, [atualizarInformacoes])


    return (
        <div id='fundoModal'>
            {confirmacao && <Notificacoes texto={'Contrato Salvo com Sucesso'} icone={confirmacaoImg} cor='sucesso' />}
            {erro && <Notificacoes texto='Ocorreu um erro ao processar a solicitação!' icone={erroImg} cor='erro' />}
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={documentoImg} alt='' />
                    <p>Novo Contrato</p>
                </span>
                <form id='formModalNovo' onSubmit={cadastrarContratos}> 
                    <section>
                        <span>
                            <label htmlFor="clienteNovoContrato">Cliente</label>
                            <select id='clienteNovoContrato' value={cliente ? cliente : ''} onChange={(e) =>setCliente(e.target.value)} required>
                                <option value=""></option>
                                {clientes.map((cliente) => (
                                    <option key={cliente.id} value={cliente.id}>{cliente.nomeFantasia}</option>
                                ))}
                            </select>
                        </span>   

                        <span>
                            <label htmlFor="contatosModelos">Contrato</label>
                            <select id='contatosModelos' value={contrato ? contrato : ''} onChange={(e) =>setContrato(e.target.value)} required>
                                <option value=""></option>
                                {contratos.map((contrato) => (
                                    <option key={contrato.id} value={contrato.id}>{contrato.descricao}</option>
                                ))}
                            </select>
                        </span>   
                    </section>

                    <section>
                        <span>
                            <label htmlFor="descricaoServico">Descrição do Serviço</label>
                            <textarea
                                id='descricaoServico' 
                                rows={8}
                                value={descricaoServico ? descricaoServico : ''}
                                onChange={(e) => setDescricaoServico(e.target.value)}
                                required/>
                        </span>  
                        <span>
                            <label htmlFor="valorPagamento">Descrição do Pagamento</label>
                            <textarea
                                id='valorPagamento' 
                                rows={8} 
                                value={descricaoPagamento ? descricaoPagamento : ''}
                                onChange={(e) => setDescricaoPagamento(e.target.value)}
                                required/>
                        </span>     
                    </section>

                    <section>
                        <span>
                            <label htmlFor="valorContrato">Valor Contratado</label>
                            <input
                                id='valorContrato' 
                                type="text" 
                                value={valorContrato}
                                onChange={(e) => setValorContrato(moedaMascara(e.target.value))}
                                required/>
                        </span>    
                    </section>

                    <section>
                        <span>
                            <label htmlFor="observacoes">Observações</label>
                            <textarea
                                id='observacoes' 
                                rows={5}
                                value={observacoes ? observacoes : ''}
                                onChange={(e) => setObservacoes(e.target.value)}/>
                        </span>   
                    </section>

                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Contrato'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalContratos