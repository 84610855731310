import { FormEvent, useContext, useState } from "react";
import { Button, Loader } from "../Styles";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import "./index.css"
import { toast } from "react-toastify";
import { cadastrarModulo } from "../../services/modulos";
import { useLocation } from "react-router-dom";

function ModalNovoModulo() {
  const [processando, setProcessando] = useState(false)
  const [saldoDoc, setSaldoDoc] = useState<number>()

  const { modalExibir, atualizarDados } = useContext(ModalEstadoContext)

  const location = useLocation()
  const { search } = location

  const [nomeModulo, setNomeModulo] = useState("")

  const adicionarNovoModulo = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    const parms = new URLSearchParams(search)
    const idOrganizacao = parms.get('idCliente')

    if (!idOrganizacao) return toast.error("Erro ao tentar adicionar um novo modulo. Id da Organição não encontrado")

    if (nomeModulo === "Documentos" && saldoDoc === undefined) {
      toast.info("Digite um valor no Saldo")
      return setProcessando(false)
    }

    try {
      const payload = {
        nome: nomeModulo,
        saldoDoc,
        idOrganizacaoEmpresa: idOrganizacao
      }
      await cadastrarModulo(payload)

      atualizarDados()
      modalExibir("")
      return toast.success("Módulo Adicionado com Sucesso.")
    } catch (error: any) {
      if (error.response.status === 409) {
        toast.error("Esse módulo já existe na Organização")
        return setProcessando(false)
      }
      toast.error("Erro ao Adicionar o Módulo")
      console.log(error)
      return setProcessando(false)
    }
  }

  return (
    <div className="fundoModal">
      <section className='containerModalCentro'>
        <form onSubmit={adicionarNovoModulo}>
          <span className='novoContainerTitulo'>
            <h1>Adicionar um novo módulo na organização</h1>
          </span>
          <section>
            <span>
              <select name="" defaultValue="" onChange={(e) => setNomeModulo(e.target.value)} className="select-modal-modulo">
                <option disabled value="">Selecione um Módulo</option>
                {["Atendimento", "Recrutamento", "Documentos", "Projetos", "Agendamento", "Obrigações", "Chat"].map((modulo, index) => (
                  <option value={modulo} key={index}>{modulo}</option>
                ))}
              </select>
            </span>
            {nomeModulo === "Documentos" &&
              <span>
                <input min={0} type="number" placeholder='Adicione um Saldo para os Documentos' onChange={(e) => setSaldoDoc(parseInt(e.target.value))} className="input-modal-modulo-documento" required/>
              </span>
            }
          </section>
          <span className="container-btn-modal-modulo">
            <Button type='button' cor='cancelar' isLoading={processando} onClick={() => modalExibir("")}>Fechar</Button>
            <Button type='submit' cor='salvar' isLoading={processando}>
              {!processando && 'Adicionar'}
              {processando && <Loader isLoading={processando} />}
            </Button>
          </span>
        </form>
      </section >
    </div >
  );
}

export default ModalNovoModulo;