import axios from 'axios'
import { verificarTokenAutenticacao } from '../../middleware/Token'

export const BASEURLPROJETOS = axios.create({
  baseURL: process.env.REACT_APP_API_CHAT,
  headers: {
    'Content-Type': 'application/json',
  }

})

BASEURLPROJETOS.interceptors.request.use(async config => {
  const token = verificarTokenAutenticacao()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default BASEURLPROJETOS