import { useState, FormEvent, useContext, useEffect, ChangeEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Assinante, ModalDocumentos, Cadastros } from '../../interfaces/documentosDigitais'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { adicionarAssinanteAoDocumentoExistente, atualizarDocumento, criarDocumentoDigital, solicitarAssinaturaWhatsapp } from '../../services/documentosDigitais'
import { buscarModulos } from '../../services/modulos'
import { Button, Loader } from '../Styles'
import { listarCadastros } from '../../services/cadastros'
import { criptografarDado, telefoneMascara } from '../../helpers'
import { toast } from 'react-toastify'
import { listaModulo } from '../../interfaces/modulos'
import DivSituacao from '../DivSituacao'
import whatsAppImg from '../../assets/img/whatsappCobranca.png'
import documentoImg from '../../assets/img/documentos.png'
import apagarImg from '../../assets/img/excluir.png'
import copiarImg from '../../assets/img/copiar.png'
import './documento.css'
import '../../styles/modal.css'

function ModalDocumentosDigitais({ documento }: ModalDocumentos) {

    const navigate = useNavigate()
    const location = useLocation()

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const paramsUrl = new URLSearchParams(search)

    const idOrganizacao = localStorage.getItem("organizacaoGesttor")

    const [cliente, setCliente] = useState(documento?.cadastro.nomeFantasia)
    const [tituloDocumento, setTituloDocumento] = useState(documento?.tituloDocumento)

    const [cadastros, setCadastros] = useState<Cadastros[]>([])

    const [nomeNovoAssinante, setNomeNovoAssinante] = useState<null | string>(null)
    const [emailNovoAssinante, setEmailNovoAssinante] = useState<null | string>(null)
    const [telefoneNovoAssinante, setTelefoneNovoAssinante] = useState<string>()

    const [assinantesLocais, setAssinantesLocais] = useState<Assinante[]>([])

    const [arquivoSelecionado, setArquivoSelecionado] = useState<File>();
    const [processando, setProcessando] = useState(false)

    const [moduloDocumento, setIdModuloDocumento] = useState<string>()

    const params = new URLSearchParams(search)

    function adicionarAssinantesLocais(e: FormEvent) {

        e.preventDefault()

        if (!nomeNovoAssinante || !emailNovoAssinante || !telefoneNovoAssinante) return alert('Os campos nome, email e telefone são obrigatorios.')

        const assinante = {
            nomeCompleto: nomeNovoAssinante,
            telefone: telefoneNovoAssinante,
            email: emailNovoAssinante
        }

        return setAssinantesLocais((prevState) => [...prevState, assinante])
    }

    function ocultarModal() {

        const escritorio = paramsUrl.get('escritorio')

        if (escritorio) {

            navigate(`${pathname}?escritorio=true`)
            exibirModal()

            return
        }

        navigate(`${pathname}`)

        return exibirModal()
    }

    const updateDocumento = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idDocumento = paramsUrl.get('idDocumento')

        if (idDocumento === null || cliente === undefined || tituloDocumento === undefined) {
            return setProcessando(false)
        }

        const payload = {
            tituloDocumento
        }

        try {

            const resposta = await atualizarDocumento(idDocumento, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                toast.success("Documento Atualizado com Sucesso.")
                atualizarDados()
            }

        } catch (error) {

            setProcessando(false)
            toast.error("Erro ao Atualizar Documento.")

            return
        }
    }

    const adicionarAssinanteAoDocumento = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idDocumento = params.get('idDocumento')

        if (!moduloDocumento) return toast.error("Não foi possivel encontrar o saldo disponivel")

        if (!idDocumento || !nomeNovoAssinante || !emailNovoAssinante || !telefoneNovoAssinante) {

            setProcessando(false)
            return toast.info('Os campos nome, email e telefone são obrigatorios.')
        }

        try {

            const payload = {
                nomeCompleto: nomeNovoAssinante,
                telefone: telefoneNovoAssinante,
                email: emailNovoAssinante
            }

            const resposta = await adicionarAssinanteAoDocumentoExistente(idDocumento, payload)

            if (resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                toast.success("Adicionado Assinantes ao Documento Existente.")
            }
        } catch (error) {

            setProcessando(false)
            toast.error("Erro ao Adicionar Assinantes ao Documento Existente")
        }
    }

    const excluirAssinantes = (posicao: number) => {

        setAssinantesLocais(items => [...items])

        return assinantesLocais.splice(posicao, 1)
    }

    const handleChangeDocumento = (event: FormEvent) => {

        const target = event.target as HTMLInputElement
        const files = target.files as FileList;

        if (files && files.length > 0) {
            setArquivoSelecionado(files[0]);
        }
    };

    const cadastrarDocumento = async (e: FormEvent) => {
        e.preventDefault()
        setProcessando(true)

        if (!moduloDocumento) return toast.error("Não foi possivel encontrar o saldo disponivel")

        if (tituloDocumento === undefined || cliente === undefined || arquivoSelecionado === undefined) {
            return alert('Erro ao cadastrar Documento, titulo do documento ou id não foi definido.')
        }

        if (assinantesLocais.length === 0) {
            toast.info("Você precisa adicionar pelo menos um Assinante")
            setProcessando(false)
        }

        try {

            const payload = {
                tituloDocumento,
                assinantes: assinantesLocais,
                idCadastro: cliente,
                arquivoSelecionado
            }

            const resposta = await criarDocumentoDigital(payload)

            if (resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                toast.success("Documento Cadastrado com Sucesso.")
                exibirModal()
            }
        } catch (error: any) {

            if(error.response.status === 406) {
                setProcessando(false)
                toast.error('Saldo Infuciente. Por favor realize uma nova recarga de documentos para continuar a usar')
                
                toast.error('Acesse o menu suporte > Solicitar Atendimento > Realize a solicitação de quantos documentos precisar')
                
                toast.error('O pedido mínimo é de 50 documentos e o custo de cada um é R$ 2,10')

                return
            }
            setProcessando(false)
            toast.error("Erro ao Cadastrar Documento.")
        }
    }

    const changeTelefone = (e: ChangeEvent<HTMLInputElement>) => {
        return setTelefoneNovoAssinante(telefoneMascara(e.target.value))
    }

    const enviarSolicitacaoNoWhatsapp = async (idAssinante: string | undefined) => {

        if (idAssinante === undefined) {
            return
        }

        setProcessando(true)

        try {

            const resposta = await solicitarAssinaturaWhatsapp(idAssinante)

            if (resposta.status === 200) {
                setProcessando(false)
                toast.success("Mensagem Enviada com Sucesso.")
            }

        } catch (error) {

            return setProcessando(false)
        }

    }

    const copiarUrlSolicitacao = async (tokenZapSign: string) => {

        setProcessando(true)

        try {

            await navigator.clipboard.writeText(`${process.env.REACT_APP_ZAPSIGN_URL_ASSINATURA}/verificar/${tokenZapSign}`)

            toast.success("Link Copiado com Sucesso.")
            setProcessando(false)

        } catch (error) {

            return setProcessando(false)
        }
    }

    useEffect(() => {
        const buscarModuloDocumento = async () => {
            try {
                if (!idOrganizacao) return toast.error("Não foi Possivel Encontrar o Saldo Disponivel")
                const idOrganizacaoCript = criptografarDado(idOrganizacao)
                const resposta = await buscarModulos(idOrganizacaoCript)

                const moduloDoc = resposta.data.filter((modulo: listaModulo) => modulo.nome === "Documentos")

                setIdModuloDocumento(moduloDoc[0].id)

            } catch (error) {
                toast.error("Erro ao Buscar o Modulo de Documento")
            }
        }
        buscarModuloDocumento()
    }, [atualizarDados, idOrganizacao])

    useEffect(() => {

        const buscarCadastros = async () => {

            try {

                const paramsCliente = {
                    tipo: 'cliente',
                    termo: '',
                    pagina: 1,
                    situacao: 'ativo'
                }

                const paramsColaborador = {
                    tipo: 'colaborador',
                    termo: '',
                    pagina: 1,
                    situacao: 'ativo'
                }

                const promisses = [
                    listarCadastros(paramsCliente),
                    listarCadastros(paramsColaborador)
                ]

                const resposta = await Promise.all(promisses)

                const clientes = resposta[0]
                const colaboradores = resposta[1]

                if (clientes.status === 200 && colaboradores.status === 200) {
                    const novosCadastros = [...clientes.data, ...colaboradores.data]

                    setCadastros((prevStat) => {

                        const idsExistentes = prevStat.map(cadastro => cadastro.id)
                        const cadastrosUnicos = novosCadastros.filter(cadastro => !idsExistentes.includes(cadastro.id))

                        return [...prevStat, ...cadastrosUnicos]
                    })
                }

            } catch (error) {

                return toast.error('Erro ao Listar os Dados de Cadastros')

            }
        }

        buscarCadastros()

    }, [])

    return (
        <div id='fundoModal'>
            <section id={documento ? 'containerModalNovo' : ''} className={documento ? 'containerModalDocumento' : 'containerModalCentro'}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={documentoImg} alt='' />
                    <p>{!documento ? 'Novo Documento' : 'Vizualizar Documento'}</p>
                </span>
                <form id='formModalNovo' onSubmit={documento ? updateDocumento : (e) => cadastrarDocumento(e)}>
                    <section>
                        {documento && <span>
                            <label htmlFor="responsavelDocumento">Solicitado por</label>
                            <input id='solicitanteDoc tituloDocumento' disabled value={documento.usuario.nome} type="text" />
                        </span>}
                    </section>
                    <section>
                        {documento && <span>
                            <label htmlFor="clienteNovoDocumento">Cliente/Colaborador</label>
                            <input type="text" name={cliente} value={cliente} id="inputClienteNome" disabled />
                        </span>}

                        {!documento && <span>
                            <label htmlFor="clienteNovoDocumento">Cliente/Colaborador</label>
                            <select name={cliente} onChange={(e) => { setCliente(e.target.value); }} id="inputClienteNome" required >
                                <option value=""></option>
                                {
                                    cadastros.map((cadastro) => (
                                        <option key={cadastro.id} value={cadastro.id}>{cadastro.nomeFantasia}</option>
                                    ))
                                }
                            </select>
                        </span>}

                        <span>
                            <label htmlFor="tituloDocumento">TÍtulo do Documento</label>
                            <input
                                id='tituloDocumento'
                                value={tituloDocumento}
                                onChange={(e) => setTituloDocumento(e.target.value)}
                                type='text'
                                required />
                        </span>
                    </section>

                    {!documento && <section className='containerDocumento'>
                        {<span>
                            <input
                                type="file"
                                accept=".pdf"
                                name="arquivoPDF"
                                onChange={(e) => handleChangeDocumento(e)}
                                required
                            />
                        </span>}
                    </section>}

                    <section>
                        {(!documento || documento?.situacao === "Aguardando Assinatura") && <div className='contentAdicionarAssinante'>
                            <Button type='submit' onClick={(e) => documento ? adicionarAssinanteAoDocumento(e) : adicionarAssinantesLocais(e)} isLoading={processando} cor='link'>
                                {!processando && 'Adicionar Assinante'}
                                {processando && <Loader isLoading={processando} />}
                            </Button>
                        </div>}
                    </section>

                    {(!documento || documento?.situacao === "Aguardando Assinatura") && <section>
                        <span>
                            <label htmlFor="nomeCompleto"></label>
                            <input
                                type="text"
                                name="nomeCompleto"
                                id="inputNomeCompleto"
                                placeholder='Nome Completo'
                                onChange={(e) => setNomeNovoAssinante(e.target.value)} />
                        </span>
                        <span>
                            <input
                                type="text"
                                name="numeroTelefone"
                                id="inputNumeroTelefone"
                                placeholder='Telefone/Whatsapp'
                                onChange={(e) => changeTelefone(e)}
                                minLength={18}
                                maxLength={18}
                                value={telefoneNovoAssinante} />
                        </span>
                        <span>
                            <input
                                type="email"
                                name="email"
                                id="inputEmail"
                                placeholder='Email do assinante'
                                onChange={(e) => setEmailNovoAssinante(e.target.value)} />
                        </span>
                    </section>}

                    <section>

                        {documento && <span>
                            <label className='assinantesDocumentos'>Assinantes</label>
                            {documento?.documentosAssinantes.map((assinante, index) => (
                                <div key={index} className='containetAssinantes'>
                                    <div className='contentAssinantes'>
                                        <p>{assinante.nomeCompleto}</p>
                                        {assinante.situacao === 'Assinado' && <p>
                                            {assinante.alterado ? new Date(assinante.alterado).toLocaleString('pt-BR', {
                                                timeZone: 'America/Sao_Paulo',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            }).replace(',', ' as ') : ''}
                                        </p>}
                                        <DivSituacao texto={assinante.situacao ? assinante.situacao : ''} cor={assinante.situacao ? assinante.situacao?.toLowerCase().replace(/\s+/g, '') : ''} />
                                    </div>
                                    {assinante.situacao === 'Aguardando Assinatura' && <div className='contentAssinantesAcoes'>
                                        <img src={whatsAppImg} alt='' title='Enviar no WhstsApp' onClick={() => enviarSolicitacaoNoWhatsapp(assinante.id)} />
                                        <img src={copiarImg} alt="" title='Copiar Link' onClick={() => assinante.tokenZapSign && copiarUrlSolicitacao(assinante.tokenZapSign)} />
                                    </div>}
                                </div>
                            ))}
                        </span>}

                        {!documento && <span>
                            {assinantesLocais.length !== 0 && <label className='assinantesDocumentos'>Assinantes</label>}
                            {assinantesLocais.map((assinante, index) => (
                                <div className='listaAssinantes' key={index}>
                                    <p>{assinante.nomeCompleto}</p>
                                    <img className='iconeExcluirUsuario' src={apagarImg} alt="" title='Excluir Assinante' onClick={() => excluirAssinantes(index)}></img>
                                </div>
                            ))}

                        </span>}

                    </section>

                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar documento'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalDocumentosDigitais
