import { useState, FormEvent, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novaTarefaModelo, atualizarTarefaModelo } from '../../services/modelosTarefas'
import { ModalTarefaModeloDados } from '../../interfaces/modelosTarefas'
import { listarUsuariosOrganizacao } from '../../services/usuarios'
import { deletarSubTarefaModelo, listarSubtarefasModelos, novaSubTarefaModelo } from '../../services/subTarefasModelos'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import { Button, Loader } from '../Styles'
import { cadastrarSubTarefaModeloLocal, subTarefasModelosResposta } from '../../interfaces/subTarefasModelos'
import { toast } from 'react-toastify'
import apagarImg from '../../assets/img/excluir.png'
import processoImg from '../../assets/img/processos.png'
import '../../styles/modal.css'


function ModalTarefaModelo ({ tarefaModelo }: ModalTarefaModeloDados) {

    const { exibirModalTarefasProcesso, inserirTarefaModeloLocal, atualizarDadosProcesso } = useContext(ModelosProcessosContext)
    const { modalExibir, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const location = useLocation()

    const { search } = location

    const [tipo, setTipo] = useState(tarefaModelo?.tipo)
    const [titulo, setTitulo] = useState(tarefaModelo?.titulo)
    const [prioridade, setPrioridade] = useState(tarefaModelo?.prioridade)
    const [descricao, setDescricao] = useState(tarefaModelo?.descricao)
    const [responsavel, setResponsavel] = useState<string | null >(tarefaModelo?.responsavel?.id || null)
    const [automacao, setAutomacao] = useState<boolean | undefined>(tarefaModelo?.automacao ?? false)
    const [recorrencia, setRecorrencia] = useState<string | null | undefined>(tarefaModelo?.recorrencia)
    const [prazo, setPrazo] = useState(tarefaModelo?.prazo)
    const [subTarefasModelos, setSubtarefasModelos] = useState<subTarefasModelosResposta[]>([])
    const [subTarefasModelosLocal, setSubTarefasModelosLocal] = useState<cadastrarSubTarefaModeloLocal[]>([])
    const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])
    const [processando, setProcessando] = useState (false)

    function ocultarModal () {
        modalExibir('')
        return exibirModalTarefasProcesso ()
    }

    const adicionarSubTarefaModeloLocal = () => {

        const subTarefaLocal = window.prompt('Insira sua Subtarefa')

        if(subTarefaLocal ===  null) {
            return
        }

        const subTarefa = {
            descricao: subTarefaLocal
        }

        return setSubTarefasModelosLocal(itens => [...itens, subTarefa])
    }


    const removerSubTareModelofaLocal = (posicao: number) =>{

        setSubTarefasModelosLocal(itens => [...itens])

        return subTarefasModelosLocal.splice(posicao, 1)
    }
    

    const vincularTarefaModeloLocal = async (e: FormEvent) => {

        e.preventDefault()

        if(responsavel === undefined || titulo === undefined || descricao === undefined || prioridade === undefined || tipo === undefined || prazo === undefined || automacao === undefined) {
            return
        }

        const tarefaModelo = {
            idResponsavel: responsavel,
            titulo,
            prioridade,
            descricao,
            tipo,
            automacao,
            recorrencia,
            prazo,
            subTarefas: subTarefasModelosLocal
        }

        inserirTarefaModeloLocal(tarefaModelo)
        exibirModalTarefasProcesso()

        return tarefaModelo

    }

    const cadastrarTarefaModelo = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const parms = new URLSearchParams(search)
        const idModelo = parms.get('idModelo')
        const idTemplat = parms.get('idTemplat')

        if(!idModelo && !idTemplat) {
            setProcessando(false)
            return vincularTarefaModeloLocal(e)
        }

        if(prazo === null || prazo === undefined || automacao === undefined) {
            return setProcessando(false)
        }

        try {

            const payload = {
                idModeloProcesso: idModelo,
                idTemplat,
                idResponsavel: responsavel,
                titulo,
                descricao,
                prioridade,
                tipo,
                automacao,
                recorrencia,
                prazo,
                subTarefas: subTarefasModelosLocal
            }

            const resposta = await novaTarefaModelo(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                exibirModalTarefasProcesso()
                atualizarDadosProcesso()
                modalExibir('')

                return toast.success('Dados Salvos com Sucesso')
            }
            
        } catch (error) {
            
            setProcessando(false)
            
            return toast('Falta ao Processar os Dados')
        }

    }

    const updateTarefaModelo = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idModeloTarefa = location.hash.substring(1)


        if (!idModeloTarefa || responsavel === undefined || titulo === undefined || descricao === undefined || prioridade === undefined || tipo === undefined || prazo === undefined || automacao === undefined) {
            return setProcessando(false)
        }

        const payload = {
            idResponsavel: responsavel === '' ? null : responsavel,
            titulo,
            descricao,
            prioridade,
            tipo,
            automacao,
            recorrencia,
            prazo
        }

        try {

            const resposta = await atualizarTarefaModelo(idModeloTarefa, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                exibirModalTarefasProcesso()
                atualizarDadosProcesso()
                
                return toast.success('Dados Salvos com Sucesso')
            }
            
        } catch (error) {

            setProcessando(false)

            return toast('Falta ao Processar os Dados')
        }
    }

    const cadastrarNovaSubTarefaModelo = async () => {
    
        setProcessando(true)

        const idModeloTarefa = location.hash.substring(1)
    
        const descricaoSubTarefa = window.prompt('Insira sua Subtarefa')
    
        if(idModeloTarefa === null || descricaoSubTarefa === null) {
            return setProcessando(false)
        }
    
        try {
    
            const payload = {
                descricao: descricaoSubTarefa
            }
    
            const resposta = await novaSubTarefaModelo(Number(idModeloTarefa), payload)
    
            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDadosProcesso()
                atualizarDados()
                
                return toast.success('Subtarefa Salva com Sucesso')
            }

                
            } catch (error) {

                setProcessando(false)
                
                return toast.error('Falha ao Salvar os Dados')
                
        }
    }

    const deleteSubTarefaModelo = async (idSubTarefa: number) => {
    
        setProcessando(true)
    
        try {
    
            const resposta = await deletarSubTarefaModelo(idSubTarefa)
    
            if(resposta.status === 200) {
                setProcessando(false)
                atualizarDadosProcesso()
                atualizarDados()
                
                return toast.success('Subtarefa Apagada com Sucesso')
            }
                
        } catch (error) {

            setProcessando(false)
    
            return toast.error('Falha ao Apagar a Subtarefa')
                
        }
    }

        useEffect(() => {
    
            const buscarSubTarefasModelos = async () => {
    
                const idTarefa = location.hash.substring(1)
    
                if(!idTarefa) {
                    return
                }
    
                try {
    
                    const resposta = await listarSubtarefasModelos(idTarefa)
    
                    if(resposta.status === 200) {
                        setSubtarefasModelos(resposta.data)
                    }
                    
                } catch (error) {
                    
                }
            }
    
            buscarSubTarefasModelos()
    
        }, [atualizarDadosProcesso, atualizarInformacoes, location.hash])


    useEffect(() => {
        
        const buscarResponsaveis = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)

                if (resposta.status === 200) {
                    return setResponsaveis(resposta.data)
                }
                
            } catch (error) {

                return alert('Falha ao Listar os Responsáveis')
                
            }
        }

        buscarResponsaveis ()

    }, [])


    return (
        <div id = 'fundoModal'>
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>{!tarefaModelo ? 'Nova Tarefa Modelo': 'Vizualizar Tarefa Modelo'}</p>
                </span>
                <form id='formModalNovo' onSubmit={tarefaModelo ? updateTarefaModelo : cadastrarTarefaModelo}>
                    <section>
                        <span>
                            <label htmlFor="tituloTarefaModelo">Título</label>
                            <input
                                id='tituloTarefaModelo'
                                value={titulo}
                                onChange={((e) => setTitulo(e.target.value))} 
                                placeholder='Digite o título da tarefa'
                                type="text"
                                required />
                        </span>

                    </section>

                    <section>
                        <span>
                            <label htmlFor="tipoTarefaModelo">Tipo</label>
                            <select id='tipoTarefaModelo' value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value=""></option>
                                <option value="atendimento">Atendimento</option>
                                <option value="chamado">Chamado</option>
                                <option value="tarefa">Tarefa</option>
                            </select>
                        </span>

                        <span>
                            <label htmlFor="prioridadeNonaTarefa">Prioridade</label>
                            <select id='prioridadeNonaTarefa' value={prioridade} onChange={(e) => setPrioridade(e.target.value)} required>
                                <option value=""></option>
                                <option value="urgente">Urgente</option>
                                <option value="alta">Alta</option>
                                <option value="baixa">Baixa</option>
                            </select>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="descricaoTarefa">Descrição da Tarefa</label>
                            <textarea 
                                id='descricaoTarefa'
                                name="descricaoTarefa"
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                placeholder='Descreva aqui o que precisará ser realizado nesta tarefa'
                                rows={5}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="responsaveltarefa">Responsável</label>
                            <select id='responsaveltarefa' value={responsavel ? responsavel : ''} onChange={(e) => setResponsavel(e.target.value)}>
                                <option value="">Responsável pelo Processo</option>
                                {resposaveis.map((responsavel) => (
                                    <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                                ))}
                            </select>
                        </span>

                        <span>
                            <label htmlFor="prazoTarefaModelo">Prazo | Em Dias</label>
                            <input
                                id='prazoTarefaModelo'
                                value={prazo}
                                onChange={((e) => setPrazo(parseInt(e.target.value)))} 
                                placeholder='Do início do processo'
                                min={0}
                                type="number"
                                required/>
                        </span>
                    </section>

                    <div className='tarefasRecorrentes'>
                        <input
                            checked={automacao}
                            className='toggleAutomacaoTarefas'
                            id='toggleAutomacaoTarefas'
                            type="checkbox"
                            onChange={() => setAutomacao(!automacao)}/>
                        <label id='toggleAutomacaoTarefas' htmlFor='toggleAutomacaoTarefas'></label>
                        <p>Recorrência</p>
                    </div>

                    {automacao && <section className='automacaoTarefas'>
                        <span>
                            <label htmlFor="">Frequência</label>
                            <select value={recorrencia !== null ? recorrencia : ''} onChange={(e) => setRecorrencia(e.target.value)} required>
                                <option value=""></option>
                                <option value="diaria">Diária</option>
                                <option value="semanal">Semanal</option>
                                <option value="mensal">Mensal</option>
                                <option value="trimestral">Trimestral</option>
                                <option value="semestral">Semestral</option>
                                <option value="anual">Anual</option>
                            </select>
                        </span>
                        <span>
                            <label htmlFor="prazoAutomacao">Prazo</label>
                            <input 
                                id='prazoAutomacao'
                                type='number'
                                value={prazo}
                                placeholder='Prazo em Dias'
                                onChange={(e) => setPrazo(parseInt(e.target.value))} 
                                required/>
                        </span>
                    </section>}

                    {!tarefaModelo && <section className='subTarefasContainer'>
                        <p id='adicionarSubtarefasContent' onClick={() => adicionarSubTarefaModeloLocal()}>Adicionar Subtarefa</p>
                        <table id = 'tabelaListaContainer'>
                            <tbody>
                                {subTarefasModelosLocal.map((subtarefa, index) => (
                                    <tr key={Math.random()}>
                                        <td id='primeiraColuna'>{subtarefa.descricao}</td>
                                        <td id='ladoDireitoContent' align='right'>
                                            <img id='apagarIconeTarefa' title='Excluir SubTarefa' src={apagarImg} alt='' onClick={() => removerSubTareModelofaLocal(index)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>}

                    {tarefaModelo && <section className='subTarefasContainer'>
                        <p id='adicionarSubtarefasContent' onClick={() => cadastrarNovaSubTarefaModelo()}>Adicionar Subtarefa</p>
                        <table id = 'tabelaListaContainer'>
                            <tbody>
                                {subTarefasModelos.map((subtarefa) => (
                                    <tr key={Math.random()}>
                                        <td id='primeiraColuna'>{subtarefa.descricao}</td>
                                        <td id='ladoDireitoContent' align='right'>
                                            <img id='apagarIconeTarefa' title='Excluir SubTarefa' src={apagarImg} alt='' onClick={() => deleteSubTarefaModelo(subtarefa.id)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
    }

export default ModalTarefaModelo
