import BASEAPI from '../api/config'
import BASEAPIFORMDATA from '../api/formData'
import { atualizarProcessoTampletPayload, cadastrarTampletPayload, paramsListaModelosTamplet } from '../../interfaces/modelosProcessosTamplets'


export function novoModeloProcessoTamplat (payload: cadastrarTampletPayload) {
    return BASEAPIFORMDATA.post('/modelosprocessos/tamplet', payload)
} 

export function importarModeloTemplat (idTemplat: string) {
    return BASEAPI.post(`/modelosprocessos/tamplet/importar/${idTemplat}`)
}

export function listarModelosProcessosTamplet (params: paramsListaModelosTamplet) {
    return BASEAPI.get (`/modelosprocessos/tamplet?termo=${params.termo}&situacao=${params.situacao}`)
}

export function listarModelosProcessosTampletTarefas (idTemplat: string) {
    return BASEAPI.get (`/modelosprocessos/tamplet/tarefas/${idTemplat}`)
}

export function atualizarModeloProcessoTamplet (idTemplat: string, payload: atualizarProcessoTampletPayload) {
    return BASEAPI.patch(`/modelosprocessos/tamplet/${idTemplat}`, payload)
}

