import { FormEvent, useState, useEffect, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { clientesGesttor } from '../../services/organizacao'
import { organizacaoDados } from '../../interfaces/organizacao'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalGerenciarLicenca from '../ModalGerenciarLicenca'
import FiltrosDefault from '../FiltrosDefault'
import ModalAlerta from '../ModalAlerta'
import DivSituacao from '../DivSituacao'
import Perfil from '../../middleware/Perfil'
import LoagindBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/paginaListaDados.css'
import './gesttor.css'

function ClientesGesttor () {

    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const { modalEstado, exibirModal, atualizarInformacoes } = useContext(ModalEstadoContext)

    const [clientes, setClientes] = useState<organizacaoDados[]>([])
    const [clienteSelecionado, setClienteSelecionado] = useState<organizacaoDados>()
    const [cliente, setCliente] = useState(false)
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('true')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erroListarClientes, setErroListarClientes] = useState(false)
    

    const { tipo } = params
    const { pathname } = location

    const ocultarModal = () => {

        return setErroListarClientes(false)
    }

    const exibirModalNovoCadastro = () => {

        return exibirModal ()
    }

    const exibirModalDadosCliente = (cliente: organizacaoDados, idCliente: string) => {

        navigate(`${pathname}?idCliente=${idCliente}`)
        exibirModal()

        return setClienteSelecionado(cliente)
    }

    const pesquisarCliente = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        try {

            const params = {
                termo,
                situacao
            }

            const resposta = await clientesGesttor(params)

            if (resposta.data.length === 0) {
                setClientes(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setClientes(resposta.data)
                setProcessando(false)

                return
            }
            
        } catch (error) {

            setProcessando(false)
            return setErroListarClientes(true)
            
        }
    }

    useEffect(() => {

        if(!modalEstado) setClienteSelecionado(undefined)
        return setCliente(modalEstado)

    }, [modalEstado])

    useEffect(() => {

        const clientes = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            try {

                const params = {
                    termo: '',
                    situacao
                }

                const resposta = await clientesGesttor(params)

                if (resposta.data.length === 0) {
                    setClientes(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setClientes(resposta.data)
                    setProcessando (false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                return setErroListarClientes(true)
                
            }
        }

        clientes ()

    }, [atualizarInformacoes])


    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div id='paginaContainer'>
                {cliente && <ModalGerenciarLicenca organizacao={clienteSelecionado}/>}
                {processando && <LoagindBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {erroListarClientes && <ModalAlerta texto='Ocorreu um erro ao listar os clientes, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModal} />}
                <FiltrosDefault termo={termo} situacao={situacao} tipo={tipo} placeholder='Pesquise pelo nome fantasia ou razão social' onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={pesquisarCliente} acaoBtn={exibirModalNovoCadastro} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'true'}, {nome: 'Inativos', valor: 'false'}]} genero='masculino' isLoading={processando}/>

                <section id='fundoContainerPagina'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Nome Fantasia</th>
                                <th id='cadastroRazao' align='left'>Razão Social</th>
                                <th id='estadoCadastro' align='left'>Estado</th>
                                <th>Sistema Contratado</th>
                                <th align='center'>Licença</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientes.map((cliente) => (
                                <tr key={cliente.id} onClick={() => exibirModalDadosCliente(cliente, cliente.id)}>
                                    <td id='primeiraColuna'>{cliente.nomeFantasia}</td>
                                    <td id='cadastroNome'>
                                        <span>{cliente.razaoSocial}</span>
                                        <span><b>CNPJ/CPF: </b>{cliente.cnpjCpf}</span>
                                    </td>
                                    <td id='estadoCadastro' align='left'>{cliente.estado === null ? '' : cliente.estado.charAt(0).toUpperCase()}{cliente.estado === null ? '' : cliente.estado.substring(1)}</td>
                                    <td align='center'>
                                        <DivSituacao texto={cliente.sistemaContratado === false ? 'Não' : 'Sim'} cor={cliente.sistemaContratado === false ? 'nao' : 'sim'} />
                                    </td>
                                    <td id='ladoDireitoContent' align='center'>
                                        <DivSituacao texto={new Date() > new Date(cliente.validadeLicenca) ? 'Expirada' : 'Ativa'} cor={new Date() > new Date(cliente.validadeLicenca) ? 'expirada' : 'ativa'}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </Perfil>
    )
}

export default ClientesGesttor