import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { AuthContext } from '../../contexts/Auth'
import { notificador } from '../../helpers'
import Organizacao from '../../middleware/Organizacao'
import Perfil from '../../middleware/Perfil'
import io from 'socket.io-client'
import Notificacoes from '../Notificacoes'
import MenuDashboard from '../MenuOpcoesDashboard'
import MenuTarefas from '../MenuOpcoesTarefas'
import MenuAtendimento from '../MenuOpcoesAtendimento'
import MenuProcessos from '../MenuOpcoesProcessos'
import MenuCadastros from '../MenuOpcoesCadastros'
import MenuDocumentosDigitais from '../MenuOpcoesDocumentosDigitais'
import MenuRecrutamento from '../MenuOpcoesRecrutamento'
import MenuFinanceiro from '../MenuOpcoesFinanceiro'
import MenuRelatorio from '../MenuOpcoesRelatorios'
import MenuGesttor from '../MenuOpcoesGesttor'
import MenuSuporte from '../MenuOpcoesSuporte'
import MenuConfiguracao from '../MenuOpcoesConfiguracoes'
import ModalMeuPerfil from '../ModalMeuPerfil'
import ModalAssinatura from '../ModalAssinatura'
import ModalAtualizarAvatarUsuario from '../ModalAtualizarAvatarUsuario'
import dashboardIcone from '../../assets/icons/dashboard.svg'
import tarefasIcone from '../../assets/icons/tarefas.svg'
import processosIcone from '../../assets/icons/processos.svg'
import cadastrosIcnoe from '../../assets/icons/cadastros.svg'
import documentosDigitais from '../../assets/icons/documentosDigitais.svg'
import recrutamento from '../../assets/img/recrutamento.png'
import financeiroIcone from '../../assets/icons/financeiro.svg'
import relatorioIcone from '../../assets/icons/relatorios.svg'
import gesttorIcone from '../../assets/img/icone.png'
import suporteImg from '../../assets/icons/suporte.svg'
import configuracaoImg from '../../assets/icons/configuracoes.svg'
import avatarDefault from '../../assets/img/avatarDefault.png'
import avatarOrganizacaoDefault from '../../assets/img/organizacao.png'
import logoImg from '../../assets/img/logo.png'
import logoIcone from '../../assets/img/icone.png'
import ModulosContratados from '../../middleware/SistemasContratados'
import './menulateral.css'
import MenuOpcoesProjetos from '../MenuOpcoesProjetos'

function MenuLateral() {

    const navigate = useNavigate()

    const { modalExibir, exibirModalSecundario, modalEstadoSecundario, modal } = useContext(ModalEstadoContext)
    const { isAuth } = useContext(AuthContext)


    const [avatar, setAvatar] = useState('')
    const [avatarOrganizacao, setAvatarOrganizacao] = useState('')
    const [dadosUsuario, setDadosUsuario] = useState(false)
    const [modalPerfil, setModalPerfil] = useState(false)
    const [notificacoes, setNotificacoes] = useState(false)
    const [usuarioConect, setUsuarioConect] = useState({
        nome: '',
        icone: '',
        texto: '',
        cor: ''
    })

    const exibirDadosUsuario = () => setDadosUsuario(!dadosUsuario)

    const exibirModalPerfil = () => exibirModalSecundario()

    const atualizarAvatarUsuario = () => {

        return modalExibir('atualizarAvatarUsuario')
    }

    const home = () => navigate('/painel')

    const sair = () => {

        localStorage.removeItem('usuarioGesttorAvatar')
        localStorage.removeItem('perfilGesttor')
        localStorage.removeItem('usuarioGesttor')
        localStorage.removeItem('organizacaoGesttor')
        localStorage.removeItem('authGesttor')
        localStorage.removeItem('organizacaoGesttorAvatar')
        localStorage.removeItem('modulosOrganizacoes')
        document.cookie = 'authGesttor= ; expires=true, 01 Jan 2023 00:00:01 GMT'
        document.cookie = 'modulosGesttor= ; expires=true, 01 Jan 2023 00:00:01 GMT'

        isAuth()

        return navigate('/')
    }


    useEffect(() => {
        setModalPerfil(modalEstadoSecundario)
    }, [modalEstadoSecundario])


    useEffect(() => {

        const avatar = () => {

            const avatarImg = localStorage.getItem('usuarioGesttorAvatar')

            if (avatarImg === 'null' || avatarImg === null) {

                return setAvatar(avatarDefault)
            }

            return setAvatar(avatarImg)
        }

        avatar()
    }, [])

    useEffect(() => {

        const avatarOrganizacao = () => {

            const avatarOrganizacaoImg = localStorage.getItem('organizacaoGesttorAvatar')

            if (avatarOrganizacaoImg === 'null' || avatarOrganizacaoImg === null) {

                return setAvatarOrganizacao(avatarOrganizacaoDefault)
            }

            return setAvatarOrganizacao(avatarOrganizacaoImg)
        }

        avatarOrganizacao()

    }, [])

    useEffect(() => {


        const iniciarSocket = () => {

            const idOrganizacao = localStorage.getItem("organizacaoGesttor")

            const socket = io(`${process.env.REACT_APP_API_GESTTOR}?idOrganizacao=${idOrganizacao}`)

            socket.on('connect', () => {
                socket.emit('salaOrganizacao', idOrganizacao)
            })


            socket.on('notificacaoUsuario', async (data) => {

                const { nome, icone, texto, cor } = data

                setUsuarioConect({
                    nome,
                    icone,
                    texto,
                    cor
                })

                setNotificacoes(true)

                setTimeout(() => {
                    setNotificacoes(false)
                }, 5000)

                const dadosNotificacao = {
                    body: `${nome} está on-line`
                }

                await notificador.notificacao(dadosNotificacao)

            })

            return () => {
                socket.disconnect()
            }

        }

        iniciarSocket()

    }, [])

    return (
        <>
            {modalPerfil && <ModalMeuPerfil />}
            {modal.nome === 'atualizarAvatarUsuario' && <ModalAtualizarAvatarUsuario />}
            {notificacoes && <Notificacoes titulo={usuarioConect.nome} texto={` ${usuarioConect.texto}`} cor={usuarioConect.cor} icone={usuarioConect.icone} />}
            <aside id='menuLateral'>
                {modal.nome === 'minhaAssinatura' && <ModalAssinatura />}
                <section id='logoGesttor'>
                    <img id='logoSistemaGesttorMenuLateralExpandido' src={logoImg} alt='logo do sistema gesttor' onClick={() => home()} />
                    <img id='logoSistemaGesttorMenuLateralRecolhido' src={logoIcone} alt='logo do sistema gesttor' onClick={() => home()} />
                </section >
                <section id='menuLateralOpcoes'>
                    <MenuDashboard icone={dashboardIcone} titulo='Dashboard' alt='' />
                    <ModulosContratados modulo={["Atendimento"]}>
                        <Perfil perfil={['master', 'administrativo', 'comercial', 'atendimento']}>
                            <MenuAtendimento icone={""} titulo='Atendimento' alt='' />
                        </Perfil>
                    </ModulosContratados>
                    <MenuTarefas icone={tarefasIcone} titulo='Tarefas' alt='' />
                    <MenuProcessos icone={processosIcone} titulo='Processos' alt='' />
                    <ModulosContratados modulo={["Recrutamento"]}>
                        <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
                            <MenuRecrutamento icone={recrutamento} titulo='Recrutamento' alt='' />
                        </Perfil>
                    </ModulosContratados>
                    <ModulosContratados modulo={["Projetos"]}>
                        <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor', 'desenvolvedor']}>
                            <MenuOpcoesProjetos icone={""} titulo='Projetos' alt='' />
                        </Perfil>
                    </ModulosContratados>
                    <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                        <MenuCadastros icone={cadastrosIcnoe} titulo='Cadastros' alt='' />
                    </Perfil>
                    <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                        <MenuFinanceiro icone={financeiroIcone} titulo='Financeiro' alt='' />
                    </Perfil>
                    <ModulosContratados modulo={["Documentos"]}>
                        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                            <MenuDocumentosDigitais icone={documentosDigitais} titulo='Documentos' alt='' />
                        </Perfil>
                    </ModulosContratados>
                    <Perfil perfil={['master']}>
                        <MenuRelatorio icone={relatorioIcone} titulo='Relatórios' alt='' />
                    </Perfil>
                    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                            <MenuGesttor icone={gesttorIcone} titulo='Gesttor' alt='' />
                        </Perfil>
                    </Organizacao>
                    <MenuSuporte icone={suporteImg} titulo='Suporte' alt='' />
                    <Perfil perfil={['master', 'administrativo']}>
                        <MenuConfiguracao icone={configuracaoImg} titulo='Configurações' alt='' />
                    </Perfil>
                </section>
                <section id='contaUsuarioMenu'>
                    <img id='avatarUsuarioGesttor' src={avatar} alt='' onClick={exibirDadosUsuario} />
                    <img id='avatarOrganizacaoGesttor' src={avatarOrganizacao} alt='' title='Empresa que você está trabalhando atualmente' />
                    <p onClick={exibirDadosUsuario}>Minha Conta</p>
                    {dadosUsuario && <span id='dadosUsarioMenulateral'>
                        <p onClick={() => exibirModalPerfil()}>Meu Perfil</p>
                        <p onClick={() => atualizarAvatarUsuario()}>Atualizar Avatar</p>
                        <p onClick={sair}>Sair</p>
                    </span>}
                </section>
            </aside>
        </>
    )
}

export default MenuLateral