import { useState, FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { npsAtendimento } from '../../services/nps'
import { Button } from '../Styles'
import atendimentoIcone from '../../assets/img/atendimento.png'
import logoGesttor from '../../assets/img/logo.png'
import './nps.css'

function Nps () {

    const { idTarefa } = useParams ()
    const [solicitacao, setSolicitacao] = useState (false)
    const [nota, setNota] = useState<null | number> (null)
    const [notaSeleciona, setNoTaSelecionada] = useState('')
    const [comentario, setComentario] = useState <null | string> (null)

    function cadastrarAvaliacao (e: FormEvent) {

        e.preventDefault()

        const novaAvaliacao = async () => {

            setSolicitacao (true)

            if(!idTarefa) {
                setSolicitacao(false)
                return
            }

            const payload = {
                nota: (nota === null ? 0 : nota),
                comentario
            }

            if (nota === null) {
                setSolicitacao (false)
                return toast.warning('Nenhuma nota selecionada')
            }

            try {
    
                const resposta = await npsAtendimento (idTarefa, payload)

                if (resposta.status === 201) {
                    setSolicitacao (false)
                    toast.success('Dados Salvo com Sucesso')

                    return
                }
                
            } catch (error: any) {

                setSolicitacao (false)

                if (error.response.status === 403) {
                    toast.warning('Atendimento já Avaliado')

                    return
                }

                if (error.response.status === 404) {
                    toast.warn('Atendimento não Pode ser Avaliado')

                    return
                }

                toast.error('Falha ao Salvar os Dados')

                return
            }
        }

        novaAvaliacao ()
    }

    function notaEscolhida (valor: number) {
        setNota(valor)
        setNoTaSelecionada(`nota${valor.toString()}`)
    }

    return (
        <div id = 'paginaNps'>
            <a href='https://www.hgconsultoria.co/gesttor' target='_blanck'><img id='logoGesttorNps' src={logoGesttor} alt='Logo do Sistema Gesttor'/></a>
            <div id = 'conteudoContienerNps'>
                <span id='cabecalhoFormulario'>
                    <img id='atencimentoIcone' src={atendimentoIcone} alt = '' />
                    <p>Para gente é muito importante a sua nota. Avalie o atendimento recebido e, se possível, deixe um comentário.</p>
                </span>
                <section id = 'formularioContienerNps'>
                    <form id = 'npsForm' onSubmit={cadastrarAvaliacao}>
                        <div>
                            <p>Em nota de 0 à 10, como você avalia o seu último atendimento recebido?</p>
                        </div>
                        <div id = 'npsNota'>
                            <span id={notaSeleciona === 'nota0' ? notaSeleciona : ''} onClick={() => notaEscolhida(0)}>
                                <h1>0</h1>
                            </span>
                            <span id={notaSeleciona === 'nota1' ? notaSeleciona : ''} onClick={() => notaEscolhida(1)}>
                                <h1>01</h1>
                            </span>
                            <span id={notaSeleciona === 'nota2' ? notaSeleciona : ''} onClick={() => notaEscolhida(2)}>
                                <h1>02</h1>
                            </span>
                            <span id={notaSeleciona === 'nota3' ? notaSeleciona : ''} onClick={() => notaEscolhida(3)}>
                                <h1>03</h1>
                            </span>
                            <span id={notaSeleciona === 'nota4' ? notaSeleciona : ''} onClick={() => notaEscolhida(4)}>
                                <h1>04</h1>
                            </span>
                            <span id={notaSeleciona === 'nota5' ? notaSeleciona : ''} onClick={() => notaEscolhida(5)}>
                                <h1>05</h1>
                            </span>
                            <span id={notaSeleciona === 'nota6' ? notaSeleciona : ''} onClick={() => notaEscolhida(6)}>
                                <h1>06</h1>
                            </span>
                            <span id={notaSeleciona === 'nota7' ? notaSeleciona : ''} onClick={() => notaEscolhida(7)}>
                                <h1>07</h1>
                            </span>
                            <span id={notaSeleciona === 'nota8' ? notaSeleciona : ''} onClick={() => notaEscolhida(8)}>
                                <h1>08</h1>
                            </span>
                            <span id={notaSeleciona === 'nota9' ? notaSeleciona : ''} onClick={() => notaEscolhida(9)}>
                                <h1>09</h1>
                            </span>
                            <span id={notaSeleciona === 'nota10' ? notaSeleciona : ''} onClick={() => notaEscolhida(10)}>
                                <h1>10</h1>
                            </span>
                        </div>

                        <div id = 'npsComentario'>
                            <textarea  
                                placeholder = 'você pode adicionar a sua avaliação uma sugestão, crítica ou elogio.'
                                onChange={(e) => setComentario (e.target.value)}/>
                        </div>

                        <div id = 'npsEnviarDados'>
                            <Button type='submit' isLoading={solicitacao} cor='salvar'>
                                {!solicitacao && 'ENVIAR DADOS'}
                            </Button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default Nps