import { useState, FormEvent, useContext, ChangeEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { cadsatrarConta, atualizarConta } from '../../services/contas'
import { moedaMascara, converterNumero } from '../../helpers'
import { ModalContaDados } from '../../interfaces/contas'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import bancoImg from '../../assets/img/banco.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/modal.css'


function ModalConta ({ conta }: ModalContaDados) {

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const navigate = useNavigate ()
    const location = useLocation()
    const { pathname, search } = location

    const [descricao, setDescricao] = useState(conta?.descricao)
    const [saldoInicial, setSaldoInicial] = useState(conta?.saldoInicial)
    const [dataSaldoInicial, setDataSaldoInicial] = useState(conta?.dataSaldoInicial)
    const [situacao, setSituacao] = useState(conta?.situacao)
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {

        navigate(pathname)
        exibirModal()
    }

    function ocultarModalAlertaErro () {

        setErro (false)
        setForm(true)

        return
    }

    const changeSaldoInicial = (e: ChangeEvent<HTMLInputElement>) => {

        return setSaldoInicial(moedaMascara(e.target.value))
    }

    const novaConta = async (e: FormEvent) => {

        e.preventDefault()
        setForm(false)
        setProcessando(true)

        if(descricao === undefined || dataSaldoInicial === undefined || saldoInicial === undefined) {
            return setProcessando(false)
        }

        try {

            const payload = {
                descricao,
                saldoInicial: converterNumero(saldoInicial)/100,
                dataSaldoInicial
            }

            const resposta = await cadsatrarConta(payload)

            if (resposta.status === 201) {

                setProcessando(false)
                atualizarDados()

                return setConfirmacao (true)
            }
            
        } catch (error:any) {

            setProcessando(false)
            setErro (true)

            return
        }
    }

    const atualizarDadosConta = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
        setForm(false)
    
        const parms = new URLSearchParams(search)
        const idConta = parms.get('id')
        const idTransacao = location.hash.substring(1)

        if(idConta == null || descricao === undefined || dataSaldoInicial === undefined || saldoInicial === undefined || situacao === undefined) {
            return setProcessando(false)
        }

        const payload = {
            idTransacao,
            descricao,
            dataSaldoInicial,
            saldoInicial: converterNumero(saldoInicial)/100,
            situacao
        }

        try {

            const resposta = await atualizarConta(idConta, payload)

            if(resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return
            }
            
        } catch (error: any) {

            setProcessando (false)
            setForm(true)

            return setErro (true)
            
        }
    }


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= {conta ? 'Dados atualizados com sucesso' : 'Conta Cadastrada com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao tentar salvar os dados!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaErro} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={bancoImg} alt='' />
                    <p>{!conta ? 'Cadastrar Conta': 'Atualizar Dados da Conta'}</p>
                </span>
                <form id='formModalNovo' onSubmit={!conta ? novaConta : atualizarDadosConta}>
                    <section>
                        <span>
                            <label htmlFor="descricaoConta">Descrição</label>
                            <input 
                                id='descricaoConta' 
                                type="text" 
                                value={descricao}
                                placeholder='Ex.: Banco do Brasil'
                                onChange={(e) => setDescricao(e.target.value)}
                                required/>
                        </span>

                        {conta && <span>
                            <label htmlFor="situacaoConta">Situacao</label>
                            <select id='situacaoConta' value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value='ativa'>Ativa</option>
                                <option value="inativa">Inativa</option>
                            </select>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="saldoInicialConta">Saldo Inicial</label>
                            <input 
                                id="saldoInicialConta"
                                type="text"
                                value={moedaMascara(saldoInicial)}
                                placeholder='R$ 0,00' 
                                required
                                onChange={(e) => changeSaldoInicial(e)}/>
                        </span>
                        <span>
                            <label htmlFor="dataSaldoInicial">Data do Saldo Inicial</label>
                            <input 
                                id="dataSaldoInicial"
                                type="date"
                                value={dataSaldoInicial}
                                onChange={(e) => setDataSaldoInicial(e.target.value)}
                                required/>
                        </span>
                    </section>
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalConta
