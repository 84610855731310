import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, Pie, PieChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip, XAxis, YAxis } from "recharts";
import { curriculoCadastradoMensal, curriculoAnaliseDisc, curriculosPorEstados, curriculosPorCidades, curriculosCompletosIncompletos, curriculoEstatisticas, curriculosSituacaoMensal } from "../../services/dashboardCandidatos";
import { analiseDisc, curriculoCadastradoMes, curriculosCidade, curriculosCompletoIncompleto, curriculosEstado, curriculoEstatistica, curriculosCompletoIncompletoPorMes } from "../../interfaces/dashboardCandidatos";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";
import Organizacao from "../../middleware/Organizacao";
import Perfil from "../../middleware/Perfil";
import GraficoComponent from "../GraficoComponent";
import LoadingCirculo from "../LoadingCirculo";
import ModulosContratados from '../../middleware/SistemasContratados'

function DashboardCandidatos() {
  const [novosCurriculosMensal, setNovosCurriculosMensal] = useState<curriculoCadastradoMes[]>([])
  const [analiseDisc, setAnaliseDisc] = useState<analiseDisc[]>([])
  const [curriculosEstado, setCurriculosEstado] = useState<curriculosEstado[]>([])
  const [curriculosCidade, setCurriculosCidade] = useState<curriculosCidade[]>([])
  const [curriculosCompletosEIncompletos, setCurriculosCompletosEIncompletos] = useState<curriculosCompletoIncompleto[]>([
    { name: "Completos", value: 0 },
    { name: "Incompletos", value: 0 }
  ])
  const [curriculoEstatistica, setCurriculoEstatistica] = useState<curriculoEstatistica>({
    cidadesComCurriculos: 0,
    estadosComCurriculos: 0,
    totalCurriculos: 0,
    totalCurriculosMes: 0
  })
  const [curriculosCompletosEIncompletosPorMes, setCurriculosCompletosEIncompletosPorMes] = useState<curriculosCompletoIncompletoPorMes[]>()
  const [carregando, setCarregando] = useState(false)

  useEffect(() => {
    const curriculoCadastradoMes = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculoCadastradoMensal(),
          {
            error: 'Falha ao Processar Solicitação de Curriculos Cadastrados no Mês'
          }
        )

        const mesesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const dadosFormatados = resposta.data.map((processo: any) => ({
          name: `${mesesNomes[processo.month - 1]}/${processo.year}`,
          curriculos: processo.total,
        }));

        setNovosCurriculosMensal(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar o Grafico de Curriculos Cadastrados No Mês")
      }
    }
    curriculoCadastradoMes()
  }, [])

  useEffect(() => {
    const analiseDiscCurriculos = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculoAnaliseDisc(),
          {
            error: 'Falha ao Processar Solicitação de Analise Disc dos Curriculos'
          }
        )

        const dadosFormatados = resposta.data.map((processo: any) => ([{
          subject: 'Dominante',
          A: parseInt(processo.dominante),
        },
        {
          subject: 'Influente',
          A: parseInt(processo.influente),
        },
        {
          subject: 'Estável',
          A: parseInt(processo.estavel),
        },
        {
          subject: 'Condescendente',
          A: parseInt(processo.condescendente),
        }]));
        setCarregando(false)
        setAnaliseDisc(dadosFormatados[0])
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar o Grafico de Análise Disc")
      }
    }
    analiseDiscCurriculos()
  }, [])

  useEffect(() => {
    const curriculosPorEstado = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculosPorEstados(),
          {
            error: 'Falha ao Processar Solicitação de Curriculos por Estado'
          }
        )

        const dadosFormatados = resposta.data.map((processo: any) => ({ name: processo.estado, value: processo.quantidade }));

        setCurriculosEstado(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Curriculos por Estado")
      }
    }
    curriculosPorEstado()
  }, [])

  useEffect(() => {
    const curriculosCidade = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculosPorCidades(),
          {
            error: 'Falha ao Processar Solicitação de Curriculos Por Cidade'
          }
        )

        const dadosFormatados = resposta.data.map((processo: any) => ({ name: processo.cidade, value: processo.quantidade }));

        setCurriculosCidade(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Curriculos por Cidade")
      }
    }
    curriculosCidade()
  }, [])

  useEffect(() => {
    const curriculosCompletoIncompleto = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculosCompletosIncompletos(),
          {
            error: 'Falha ao Processar Solicitação de Situação do Curriculo'
          }
        )

        setCurriculosCompletosEIncompletos([
          { name: "Completos", value: parseInt(resposta.data[0].completos) },
          { name: "Incompletos", value: parseInt(resposta.data[0].incompletos) }
        ]);
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Curriculos  por Situação")
      }
    }
    curriculosCompletoIncompleto()
  }, [])

  useEffect(() => {
    const curriculoEstatistica = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculoEstatisticas(),
          {
            error: 'Falha ao Processar Solicitação de Situação do Curriculo'
          }
        )

        setCurriculoEstatistica(resposta.data);
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Curriculos Cadastrados no Ano")
      }
    }
    curriculoEstatistica()
  }, [])

  useEffect(() => {
    const curriculosCompletosEIncompletosMensal = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          curriculosSituacaoMensal(),
          {
            error: 'Falha ao Processar Solicitação de Situação do Curriculo'
          }
        )

        const mesesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const dadosFormatados = resposta.data.map((processo: any) => ({
          data: `${mesesNomes[processo.month - 1]}/${processo.year}`,
          completos: processo.completos,
          incompletos: processo.incompletos,
        }));

        setCurriculosCompletosEIncompletosPorMes(dadosFormatados);
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Curriculos  por Situação Mensal")
      }
    }
    curriculosCompletosEIncompletosMensal()
  }, [])

  return (
    <ModulosContratados modulo={["Recrutamento"]}>
        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
            <Perfil perfil={['master']}>
                <div className='graficoDashboardContainer'>
                <div className='dadosDashboardDefault'>
                    <div className='dashboardDefaultContent'>
                    {carregando && <LoadingCirculo />}
                    {!carregando && <h1>{curriculoEstatistica.totalCurriculos}</h1>}
                    <p>Currículos Cadastrados</p>
                    </div>
                    <div>
                    <IoDocumentTextOutline fontSize={"70px"} color="#FF6B6C" />
                    </div>
                </div>
                <div className='dadosDashboardDefault'>
                    <div className='dashboardDefaultContent'>
                    {carregando && <LoadingCirculo />}
                    {!carregando && <h1>{curriculoEstatistica.totalCurriculosMes}</h1>}
                    <p>Currículos no Mês</p>
                    </div>
                    <div>
                    <IoDocumentTextOutline fontSize={"70px"} color="#FF6B6C" />
                    </div>
                </div>
                <div className='dadosDashboardDefault'>
                    <div className='dashboardDefaultContent'>
                    {carregando && <LoadingCirculo />}
                    {!carregando && <h1>{curriculoEstatistica.estadosComCurriculos}</h1>}
                    <p>Estados com Currículos</p>
                    </div>
                    <div>
                    <FaMapLocationDot fontSize={"70px"} color="#FF6B6C" />
                    </div>
                </div>
                <div className='dadosDashboardDefault'>
                    <div className='dashboardDefaultContent'>
                    {carregando && <LoadingCirculo />}
                    {!carregando && <h1>{curriculoEstatistica.cidadesComCurriculos}</h1>}
                    <p>Cidades com Currículos</p>
                    </div>
                    <div>
                    <FaMapLocationDot fontSize={"70px"} color="#FF6B6C" />
                    </div>
                </div>
                <div className='graficoDashboardDefault'>
                    <GraficoComponent title={"Novos Curriculos Cadastrados Por Mês"} col={"grafico-col-1"}>
                    {carregando ? <LoadingCirculo /> : <BarChart data={novosCurriculosMensal}>
                        <Bar dataKey="curriculos" fill="#25AE88" />
                        <Tooltip />
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Legend />
                        <Line type="monotone" dataKey="Curriculos Cadastrados" stroke="#82ca9d" />
                    </BarChart>}
                    </GraficoComponent>
                    <GraficoComponent title={"PerFil DISC dos Candidatos"} col={"grafico-col-6"}>
                    {carregando ? <LoadingCirculo /> :
                        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={analiseDisc}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis />
                        <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        </RadarChart>
                    }
                    </GraficoComponent>
                    <GraficoComponent title={"Número de Curriculos por Estado"} col={"grafico-col-3"}>
                    {carregando ? <LoadingCirculo /> :
                        <PieChart margin={{
                        top: 0,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }} >
                        <Pie
                            data={curriculosEstado}
                            cx={120}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label
                        >
                            <Cell fill={"#FFCD56"} />
                            <Cell fill={"#01287A"} />
                            <Cell fill={"#FF6384"} />
                            <Cell fill={"#8884D8"} />
                            <Cell fill={"#72C3C9"} />
                        </Pie>
                        <Tooltip />
                        </PieChart>
                    }
                    </GraficoComponent>
                    <GraficoComponent title={"Número de Curriculos por Cidades"} col={"grafico-col-4"}>
                    {carregando ? <LoadingCirculo /> :
                        <PieChart margin={{
                        top: 0,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        }} >
                        <Pie
                            data={curriculosCidade}
                            cx={120}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label
                        >
                            <Cell fill={"#FFCD56"} />
                            <Cell fill={"#01287A"} />
                            <Cell fill={"#FF6384"} />
                            <Cell fill={"#8884D8"} />
                            <Cell fill={"#72C3C9"} />
                            <Cell fill={"#0788D9"} />
                            <Cell fill={"#11BF8E"} />
                            <Cell fill={"#F2D43D"} />
                            <Cell fill={"#F2784B"} />
                            <Cell fill={"#734A3C"} />
                        </Pie>
                        <Tooltip />
                        </PieChart>
                    }
                    </GraficoComponent>
                    <GraficoComponent title={"Curriculos por Situação"} col={"grafico-col-5"}>
                    {carregando ? <LoadingCirculo /> :
                        <PieChart width={400} height={400}>
                        <Pie
                            data={curriculosCompletosEIncompletos}
                            cx="50%"
                            cy="50%"
                            label
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            <Cell fill={"#25AE88"} />
                            <Cell fill={'#E21B1B'} />
                        </Pie>
                        <Tooltip />
                        </PieChart>
                    }
                    </GraficoComponent>
                    <GraficoComponent title={"Curriculos por Situação Mensal"} col={"grafico-col-2"}>
                    {carregando ? <LoadingCirculo /> : <BarChart
                        width={300}
                        height={300}
                        data={curriculosCompletosEIncompletosPorMes}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="data" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="completos" stackId="a" fill="#25AE88" />
                        <Bar dataKey="incompletos" stackId="b" fill="#E21B1B" />
                    </BarChart>
                    }
                    </GraficoComponent>
                </div>
                </div>
            </Perfil>
        </Organizacao>
    </ModulosContratados>
  );
}

export default DashboardCandidatos;