import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import Contatos from "../../components/Contatos";

function Contato() {
  useEffect(() => {
    document.title = 'Contatos | Gesttor'
  }, [])

  return (
    <DadosContainerDefault>
      <Contatos />
    </DadosContainerDefault>
  )
}

export default Contato;