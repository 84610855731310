import { useState } from 'react'
import Perfil from '../../middleware/Perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import { RiCustomerService2Fill } from "react-icons/ri";
import '../../styles/menuopcoes.css'

function MenuAtendimento(props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu() {
        setSubMenu(subMenu => !subMenu)
    }

    return (
        <Perfil perfil={['master', 'administrativo', 'comercial', 'atendimento']}>
            <div>
                <div id='menuOpcoes' onClick={exibirSubmenu}>
                    <RiCustomerService2Fill id='iconeMenu' color='#F1F1F3' />
                    <p id='tituloMenu'>{props.titulo}</p>
                    <h3>Novo</h3>
                </div>
                <div id='submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect='/leads' texto='Leads' />}
                </div>
                <div id='submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect='/contatos' texto='Contatos' />}
                </div>
                <div id='submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect='/comunicados' texto='Comunicados' />}
                </div>
            </div>
        </Perfil>
    )
}

export default MenuAtendimento